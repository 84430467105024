import { handleActions } from "redux-actions";
import { UPDATE_DECK } from "../actions/deck";

export const INITIAL_STATE = {
  leader: {},
  characters: [],
  doms: [],
  events: [],
  stages: [],
};

export default handleActions(
  {
    [UPDATE_DECK]: (state: any, payload: any) => {
      return { ...state, ...payload.payload };
    },
  },
  INITIAL_STATE
);
