const words = {
  header: {
    menu: {
      home: "Hogar",
      decks: "Decks",
      events: "Eventos",
      cards: "Cartas",
      languages: "Idiomas",
    },
    search: {
      placeholder: "Buscar cartas",
    },
  },
  languages: {
    english: "Inglés",
    spanish: "Español",
    portuguese: "Portugués",
    "en-en": "Inglés",
    "es-es": "Español",
    "pt-br": "Portugués",
  },
  search: {
    "press-enter": "Presione enter para cerrar",
  },
  filter: {
    color: "Color",
    type: "Tipo",
    set: "Conjunto",
    clear: "Limpiar",
    search: "Buscar",
    noResultsText: "No se encontraron resultados",
    placeholderColors: "Seleccione los colores",
    placeholderTypes: "Seleccione los tipos",
    placeholderSets: "Seleccione los conjuntos",
  }
};

export default words;
