import { createAction } from 'redux-actions';

export const UPDATE_DECK: any = createAction('UPDATE_DECK');
export const SAVE_STATE: any = createAction('SAVE_STATUS');

export const update_deck = (data:any) => (dispatch: any) => {
    dispatch(UPDATE_DECK(data));
    dispatch(SAVE_STATE());
}

