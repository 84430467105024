import loader from "./loader";
import card_list from "./card_list";
import translation from "./translation";
import deck from "./deck";

const reducers = {
  loader,
  card_list,
  translation,
  deck
};

export default reducers;
