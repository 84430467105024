import { auto } from "@popperjs/core"
import { useEffect } from "react"
import { useSelector } from "react-redux"

const CardResults = () => {
    const { card_list } = useSelector((state: any) => state)
    const { list: cards } = card_list

    useEffect(() => {
        const html = document.querySelector('html')
        if (cards?.length > 0) {
            if (html) {
                html.style.overflowY = 'hidden'
            }
            return
        }

        if (html) {
            html.style.overflowY = 'auto'
        }
    }, [cards])

    if (cards.length === 0) return null

    return (
        <div className="row px-4 position-absolute w-100" style={{ zIndex: 4, background: "#00000070", height: "calc(100vh - 65px)", overflow: auto, padding: "30px" }}>
            {cards.map((item: any) => (
                <div className="col-md-3 mb-3">
                    <div className="card text-white border border-1 border-secondary" style={{ background: "#1B1F22" }}>
                        <img src={`${process.env.REACT_APP_URL_IMG}/${item.image}`} className="card-img-top" alt="..." />
                        <div className="card-body">
                            <h5 className="card-title">{item.name}</h5>
                            <p className="card-text">{item.category}</p>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default CardResults