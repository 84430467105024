import { Provider } from "react-redux";
import store from "./redux/store";
import Loader from "./components/Loader";
import MainRouter from "./routes/MainRouter";

import 'jquery/dist/jquery.min.js'
import 'bootstrap/dist/js/bootstrap.min.js'

const App = () => {
  return (
    <Provider store={store}>
      <Loader />
      <MainRouter />
    </Provider>
  );
}

export default App;
