import { useEffect } from "react";
import useTranslation from "../../hooks/useTranslation";

const Search = ({ open, handleSearch, toggleOpen }: any) => {
    const { t } = useTranslation()
    useEffect(() => {
        if (open) {
            const searchInput: HTMLInputElement | null = document.querySelector('#btn-main-search')
            if (searchInput) searchInput.value = ''
            searchInput?.focus()
        }
    }, [open]);

    const handleClick = (e: any) => {
        const className = e.target.className;
        if (className.trim() === 'container-search') {
            toggleOpen();
        }
    }

    const _handleSubmit = (e: any) => {
        e.preventDefault();
        toggleOpen();
    }

    return (
        <div className={`container-search ${open ? '' : 'd-none'}`} onClick={handleClick}>
            <div className="container-box-search">
                <div className="container-box-search__wrap">
                    <form onSubmit={_handleSubmit}>
                        <input type="text" className="form-control w-100" placeholder="Search" aria-label="Search" aria-describedby="button-addon2" onChange={handleSearch} id="btn-main-search" />
                    </form>
                </div>

                <div className="container-box-search__footer p-2">

                    <div className="text-white">
                        <kbd className="me-2">
                            <svg width="15" height="15" aria-label="Enter key" role="img"><g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2"><path d="M12 3.53088v3c0 1-1 2-2 2H4M7 11.53088l-3-3 3-3"></path></g></svg>
                        </kbd>
                        {t('search.press-enter')}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Search;