import useTranslation from "../../hooks/useTranslation";
import Search from "./Search";
import './styles.scss';

const SearchButton = ({ handleSearch, toggleOpen, open }: any) => {
    const { t } = useTranslation();

    return (
        <>
            <Search open={open} handleSearch={handleSearch} toggleOpen={toggleOpen} />
            <div className="bd-search" id="docsearch" data-bd-docs-version="5.3">
                <button type="button" className="btn border px-3 d-flex align-items-center" aria-label="Search" onClick={toggleOpen}>
                    <span className="me-3 d-flex align-items-center">
                        <svg width="20" height="20" className="DocSearch-Search-Icon" viewBox="0 0 20 20">
                            <path d="M14.386 14.386l4.0877 4.0877-4.0877-4.0877c-2.9418 2.9419-7.7115 2.9419-10.6533 0-2.9419-2.9418-2.9419-7.7115 0-10.6533 2.9418-2.9419 7.7115-2.9419 10.6533 0 2.9419 2.9418 2.9419 7.7115 0 10.6533z" stroke="currentColor" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round"></path>
                        </svg>
                        <span className="me-5 ms-2">{t('header.search.placeholder')}</span>
                    </span>
                    <span className="ms-3">
                        <kbd className="me-1">
                            <svg width="15" height="15" className="">
                                <path d="M4.505 4.496h2M5.505 5.496v5M8.216 4.496l.055 5.993M10 7.5c.333.333.5.667.5 1v2M12.326 4.5v5.996M8.384 4.496c1.674 0 2.116 0 2.116 1.5s-.442 1.5-2.116 1.5M3.205 9.303c-.09.448-.277 1.21-1.241 1.203C1 10.5.5 9.513.5 8V7c0-1.57.5-2.5 1.464-2.494.964.006 1.134.598 1.24 1.342M12.553 10.5h1.953" strokeWidth="1.2" stroke="currentColor" fill="none" strokeLinecap="square"></path></svg>
                        </kbd>
                        <kbd className="">K</kbd>
                    </span>
                </button>
            </div>
        </>
    )
}

export default SearchButton;