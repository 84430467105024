interface IconProps {
    component: any
    effect?: string
    executionTime?: number
    when?: "hover" | "click"
    style?: object
    className?: string
    onClick?: any
}
const Icon = ({ component: Component, executionTime = 1000, effect = "animate__bounce", when, style, className, onClick }: IconProps) => {
    let interval: any = null

    const _handleEffect = (e: any, action: string = "enter") => {
        const icon = document.querySelector('.icon-component')
        if (when === "click" && action === "click") {
            if (icon) {
                icon.classList.add('animate__animated')
                icon.classList.add(effect)
                clearInterval(interval)

                interval = setTimeout(() => {
                    icon.classList.remove('animate__animated')
                    icon.classList.remove(effect)
                }, executionTime)
            }
        }

        if (action === "click" && onClick) onClick(e)

        if (when === "hover" && action === "enter") {
            if (icon) {
                icon.classList.add('animate__animated')
                icon.classList.add(effect)
            }
        }

        if (when === "hover" && action === "leave") {
            if (icon) {
                clearInterval(interval)

                interval = setTimeout(() => {
                    icon.classList.remove('animate__animated')
                    icon.classList.remove(effect)
                }, executionTime)
            }
        }
    }


    return (
        <button
            type="button"
            className={`btn icon-component ${className}`}
            style={style}
            onMouseEnter={(e: any) => _handleEffect(e, "enter")}
            onMouseLeave={(e: any) => _handleEffect(e, "leave")}
            onClick={(e: any) => _handleEffect(e, "click")}
        >
            <Component className=" text-white" />
        </button>
    )

}

export default Icon;