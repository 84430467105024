import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from '../pages/Home';
import MainTemplate from '../template/MainTemplate';
import { CustomProvider } from 'rsuite';
import Decks from '../pages/Decks';


const MainRouter = () => {
  return (
    <CustomProvider theme='dark'>
      <BrowserRouter>
        <Routes>

          <Route
            path="/"
            element={<MainTemplate><Home /></MainTemplate>}
          />

          <Route
            path="/decks"
            element={<MainTemplate><Decks /></MainTemplate>}
          />

          <Route path="*" element={<>Nothing Here</>} />
        </Routes>
      </BrowserRouter>
    </CustomProvider>
  );
};
export default MainRouter;
