export const data = [
  {
    number: "OP01-001",
    name: "Roronoa Zoro",
    cost: "",
    attribute: "Slash",
    power: "5000",
    counter: "-",
    color: "Red",
    type: "Supernovas/Straw Hat Crew",
    category: "LEADER",
    effect: "[DON!! x1] [Your Turn] All of your Characters gain +1000 power.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "L",
    image: "OP01-001.png",
    life: "5",
    trigger: "",
  },
  {
    number: "OP01-001_p1",
    name: "Roronoa Zoro",
    cost: "",
    attribute: "Slash",
    power: "5000",
    counter: "-",
    color: "Red",
    type: "Supernovas/Straw Hat Crew",
    category: "LEADER",
    effect: "[DON!! x1] [Your Turn] All of your Characters gain +1000 power.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "L",
    image: "OP01-001_p1.png",
    life: "5",
    trigger: "",
  },
  {
    number: "OP01-002",
    name: "Trafalgar Law",
    cost: "",
    attribute: "Slash",
    power: "5000",
    counter: "-",
    color: "Red/Green",
    type: "Supernovas/Heart Pirates",
    category: "LEADER",
    effect:
      "[Activate: Main] [Once Per Turn] ➁ (You may rest the specified number of DON!! cards in your cost area.): If you have 5 Characters, return 1 of your Characters to the owner's hand. Then, play up to 1 Character with a cost of 5 or less from your hand that is a different color than the returned Character.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "L",
    image: "OP01-002.png",
    life: "4",
    trigger: "",
  },
  {
    number: "OP01-002_p1",
    name: "Trafalgar Law",
    cost: "",
    attribute: "Slash",
    power: "5000",
    counter: "-",
    color: "Red/Green",
    type: "Supernovas/Heart Pirates",
    category: "LEADER",
    effect:
      "[Activate: Main] [Once Per Turn] ➁ (You may rest the specified number of DON!! cards in your cost area.): If you have 5 Characters, return 1 of your Characters to the owner's hand. Then, play up to 1 Character with a cost of 5 or less from your hand that is a different color than the returned Character.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "L",
    image: "OP01-002_p1.png",
    life: "4",
    trigger: "",
  },
  {
    number: "OP01-003",
    name: "Monkey.D.Luffy",
    cost: "",
    attribute: "Strike",
    power: "5000",
    counter: "-",
    color: "Red/Green",
    type: "Supernovas/Straw Hat Crew",
    category: "LEADER",
    effect:
      "[Activate: Main] [Once Per Turn] ➃ (You may rest the specified number of DON!! cards in your cost area.): Set up to 1 of your {Supernovas} or {Straw Hat Crew} type Character cards with a cost of 5 or less as active. It gains +1000 power during this turn.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "L",
    image: "OP01-003.png",
    life: "4",
    trigger: "",
  },
  {
    number: "OP01-003_p1",
    name: "Monkey.D.Luffy",
    cost: "",
    attribute: "Strike",
    power: "5000",
    counter: "-",
    color: "Red/Green",
    type: "Supernovas/Straw Hat Crew",
    category: "LEADER",
    effect:
      "[Activate: Main] [Once Per Turn] ➃ (You may rest the specified number of DON!! cards in your cost area.): Set up to 1 of your {Supernovas} or {Straw Hat Crew} type Character cards with a cost of 5 or less as active. It gains +1000 power during this turn.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "L",
    image: "OP01-003_p1.png",
    life: "4",
    trigger: "",
  },
  {
    number: "OP01-004",
    name: "Usopp",
    cost: "2",
    attribute: "Ranged",
    power: "3000",
    counter: "2000",
    color: "Red",
    type: "Straw Hat Crew",
    category: "CHARACTER",
    effect:
      "[DON!! x1] [Your Turn] [Once Per Turn] Draw 1 card when your opponent activates an Event.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "R",
    image: "OP01-004.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-005",
    name: "Uta",
    cost: "4",
    attribute: "Special",
    power: "4000",
    counter: "-",
    color: "Red",
    type: "FILM",
    category: "CHARACTER",
    effect:
      "[On Play] Add up to 1 red Character card other than [Uta] with a cost of 3 or less from your trash to your hand.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "R",
    image: "OP01-005.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-005_p1",
    name: "Uta",
    cost: "4",
    attribute: "Special",
    power: "4000",
    counter: "-",
    color: "Red",
    type: "FILM",
    category: "CHARACTER",
    effect:
      "[On Play] Add up to 1 red Character card other than [Uta] with a cost of 3 or less from your trash to your hand.",
    cardSet: "Premium Card Collection -FILM RED Edition-",
    rarity: "R",
    image: "OP01-005_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-006",
    name: "Otama",
    cost: "1",
    attribute: "Special",
    power: "-",
    counter: "2000",
    color: "Red",
    type: "Land of Wano",
    category: "CHARACTER",
    effect:
      "[On Play] Give up to 1 of your opponent's Characters −2000 power during this turn.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "UC",
    image: "OP01-006.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-007",
    name: "Caribou",
    cost: "3",
    attribute: "Special",
    power: "4000",
    counter: "1000",
    color: "Red",
    type: "Supernovas/Caribou Pirates",
    category: "CHARACTER",
    effect:
      "[On K.O.] K.O. up to 1 of your opponent's Characters with 4000 power or less.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "C",
    image: "OP01-007.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-008",
    name: "Cavendish",
    cost: "4",
    attribute: "Slash",
    power: "5000",
    counter: "-",
    color: "Red",
    type: "Supernovas/Beautiful Pirates",
    category: "CHARACTER",
    effect:
      "[On Play] You may add 1 card from your Life area to your hand: This Character gains [Rush] during this turn. (This card can attack on the turn in which it is played.)",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "C",
    image: "OP01-008.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-008_p1",
    name: "Cavendish",
    cost: "4",
    attribute: "Slash",
    power: "5000",
    counter: "-",
    color: "Red",
    type: "Supernovas/Beautiful Pirates",
    category: "CHARACTER",
    effect:
      "[On Play] You may add 1 card from your Life area to your hand: This Character gains [Rush] during this turn. (This card can attack on the turn in which it is played.)",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "C",
    image: "OP01-008_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-009",
    name: "Carrot",
    cost: "2",
    attribute: "Strike",
    power: "3000",
    counter: "1000",
    color: "Red",
    type: "Minks",
    category: "CHARACTER",
    effect: "[Trigger] Play this card.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "C",
    image: "OP01-009.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-010",
    name: "Komachiyo",
    cost: "1",
    attribute: "Strike",
    power: "3000",
    counter: "1000",
    color: "Red",
    type: "Animal/Land of Wano",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "C",
    image: "OP01-010.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-011",
    name: "Gordon",
    cost: "2",
    attribute: "Wisdom",
    power: "3000",
    counter: "2000",
    color: "Red",
    type: "FILM",
    category: "CHARACTER",
    effect:
      "[On Play] You may place 1 card from your hand at the bottom of your deck: Draw 1 card.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "UC",
    image: "OP01-011.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-012",
    name: "Sai",
    cost: "2",
    attribute: "Slash",
    power: "4000",
    counter: "1000",
    color: "Red",
    type: "Happosui Army",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "C",
    image: "OP01-012.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-013",
    name: "Sanji",
    cost: "2",
    attribute: "Strike",
    power: "3000",
    counter: "2000",
    color: "Red",
    type: "Straw Hat Crew",
    category: "CHARACTER",
    effect:
      "[Activate: Main] [Once Per Turn] You may add 1 card from your Life area to your hand: This Character gains +2000 power during this turn. Then, give this Character up to 2 rested DON!! cards.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "R",
    image: "OP01-013.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-013_p1",
    name: "Sanji",
    cost: "2",
    attribute: "Strike",
    power: "3000",
    counter: "2000",
    color: "Red",
    type: "Straw Hat Crew",
    category: "CHARACTER",
    effect:
      "[Activate: Main] [Once Per Turn] You may add 1 card from your Life area to your hand: This Character gains +2000 power during this turn. Then, give this Character up to 2 rested DON!! cards.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "R",
    image: "OP01-013_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-014",
    name: "Jinbe",
    cost: "4",
    attribute: "Strike",
    power: "5000",
    counter: "-",
    color: "Red",
    type: "Fish-Man/Straw Hat Crew",
    category: "CHARACTER",
    effect:
      "[Blocker] (After your opponent declares an attack, you may rest this card to make it the new target of the attack.)",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "UC",
    image: "OP01-014.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-014_p1",
    name: "Jinbe",
    cost: "4",
    attribute: "Strike",
    power: "5000",
    counter: "-",
    color: "Red",
    type: "Fish-Man/Straw Hat Crew",
    category: "CHARACTER",
    effect:
      "[Blocker] (After your opponent declares an attack, you may rest this card to make it the new target of the attack.)",
    cardSet: "Premium Card Collection -FILM RED Edition-",
    rarity: "UC",
    image: "OP01-014_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-015",
    name: "Tony Tony.Chopper",
    cost: "3",
    attribute: "Wisdom",
    power: "4000",
    counter: "1000",
    color: "Red",
    type: "Animal/Straw Hat Crew",
    category: "CHARACTER",
    effect:
      "[DON!! x1] [When Attacking] You may trash 1 card from your hand: Add up to 1 {Straw Hat Crew} type Character card other than [Tony Tony.Chopper] with a cost of 4 or less from your trash to your hand.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "UC",
    image: "OP01-015.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-016",
    name: "Nami",
    cost: "1",
    attribute: "Special",
    power: "2000",
    counter: "1000",
    color: "Red",
    type: "Straw Hat Crew",
    category: "CHARACTER",
    effect:
      "[On Play] Look at 5 cards from the top of your deck; reveal up to 1 {Straw Hat Crew} type card other than [Nami] and add it to your hand. Then, place the rest at the bottom of your deck in any order.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "R",
    image: "OP01-016.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-016_p1",
    name: "Nami",
    cost: "1",
    attribute: "Special",
    power: "2000",
    counter: "1000",
    color: "Red",
    type: "Straw Hat Crew",
    category: "CHARACTER",
    effect:
      "[On Play] Look at 5 cards from the top of your deck; reveal up to 1 {Straw Hat Crew} type card other than [Nami] and add it to your hand. Then, place the rest at the bottom of your deck in any order.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "R",
    image: "OP01-016_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-017",
    name: "Nico Robin",
    cost: "3",
    attribute: "Strike",
    power: "4000",
    counter: "1000",
    color: "Red",
    type: "Straw Hat Crew",
    category: "CHARACTER",
    effect:
      "[DON!! x1] [When Attacking] K.O. up to 1 of your opponent's Characters with 3000 power or less.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "R",
    image: "OP01-017.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-017_p1",
    name: "Nico Robin",
    cost: "3",
    attribute: "Strike",
    power: "4000",
    counter: "1000",
    color: "Red",
    type: "Straw Hat Crew",
    category: "CHARACTER",
    effect:
      "[DON!! x1] [When Attacking] K.O. up to 1 of your opponent's Characters with 3000 power or less.",
    cardSet: "Premium Card Collection -FILM RED Edition-",
    rarity: "R",
    image: "OP01-017_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-018",
    name: "Hajrudin",
    cost: "4",
    attribute: "Strike",
    power: "6000",
    counter: "1000",
    color: "Red",
    type: "Giant/New Giant Pirates",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "C",
    image: "OP01-018.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-019",
    name: "Bartolomeo",
    cost: "2",
    attribute: "Special",
    power: "2000",
    counter: "1000",
    color: "Red",
    type: "Supernovas/Barto Club",
    category: "CHARACTER",
    effect:
      "[Blocker] (After your opponent declares an attack, you may rest this card to make it the new target of the attack.)",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "C",
    image: "OP01-019.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-020",
    name: "Hyogoro",
    cost: "2",
    attribute: "Strike",
    power: "3000",
    counter: "1000",
    color: "Red",
    type: "Land of Wano",
    category: "CHARACTER",
    effect:
      "[Activate: Main] You may rest this Character: Up to 1 of your Leader or Character cards gains +2000 power during this turn.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "C",
    image: "OP01-020.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-021",
    name: "Franky",
    cost: "3",
    attribute: "Ranged",
    power: "4000",
    counter: "-",
    color: "Red",
    type: "Straw Hat Crew",
    category: "CHARACTER",
    effect:
      "[DON!! x1] This Character can also attack your opponent's active Characters.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "UC",
    image: "OP01-021.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-021_p1",
    name: "Franky",
    cost: "3",
    attribute: "Ranged",
    power: "4000",
    counter: "-",
    color: "Red",
    type: "Straw Hat Crew",
    category: "CHARACTER",
    effect:
      "[DON!! x1] This Character can also attack your opponent's active Characters.",
    cardSet: "Premium Card Collection -FILM RED Edition-",
    rarity: "UC",
    image: "OP01-021_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-022",
    name: "Brook",
    cost: "4",
    attribute: "Slash",
    power: "5000",
    counter: "1000",
    color: "Red",
    type: "Straw Hat Crew",
    category: "CHARACTER",
    effect:
      "[DON!! x1] [When Attacking] Give up to 2 of your opponent's Characters −2000 power during this turn.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "UC",
    image: "OP01-022.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-023",
    name: "Marco",
    cost: "3",
    attribute: "Special",
    power: "5000",
    counter: "1000",
    color: "Red",
    type: "Former Whitebeard Pirates",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "C",
    image: "OP01-023.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-024",
    name: "Monkey.D.Luffy",
    cost: "2",
    attribute: "Strike",
    power: "3000",
    counter: "1000",
    color: "Red",
    type: "Supernovas/Straw Hat Crew",
    category: "CHARACTER",
    effect:
      "[DON!! x2] This Character cannot be K.O.'d in battle by ＜Strike＞ attribute Characters.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "SR",
    image: "OP01-024.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-024_p1",
    name: "Monkey.D.Luffy",
    cost: "2",
    attribute: "Strike",
    power: "3000",
    counter: "1000",
    color: "Red",
    type: "Supernovas/Straw Hat Crew",
    category: "CHARACTER",
    effect:
      "[DON!! x2] This Character cannot be K.O.'d in battle by ＜Strike＞  attribute Characters.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "SR",
    image: "OP01-024_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-025",
    name: "Roronoa Zoro",
    cost: "3",
    attribute: "Slash",
    power: "5000",
    counter: "-",
    color: "Red",
    type: "Supernovas/Straw Hat Crew",
    category: "CHARACTER",
    effect: "[Rush] (This card can attack on the turn in which it is played.)",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "SR",
    image: "OP01-025.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-025_p1",
    name: "Roronoa Zoro",
    cost: "3",
    attribute: "Slash",
    power: "5000",
    counter: "-",
    color: "Red",
    type: "Supernovas/Straw Hat Crew",
    category: "CHARACTER",
    effect: "[Rush] (This card can attack on the turn in which it is played.)",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "SR",
    image: "OP01-025_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-026",
    name: "Gum-Gum Fire-Fist Pistol Red Hawk",
    cost: "2",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Red",
    type: "Supernovas/Straw Hat Crew",
    category: "EVENT",
    effect:
      "[Counter] Up to 1 of your Leader or Character cards gains +4000 power during this battle. Then, K.O. up to 1 of your opponent's Characters with 4000 power or less.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "R",
    image: "OP01-026.png",
    life: "",
    trigger:
      "[Trigger] Give up to 1 of your opponent's Leader or Character cards −10000 power during this turn.",
  },
  {
    number: "OP01-027",
    name: "Round Table",
    cost: "4",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Red",
    type: "Supernovas/Beautiful Pirates",
    category: "EVENT",
    effect:
      "[Main] Give up to 1 of your opponent's Characters −10000 power during this turn.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "C",
    image: "OP01-027.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-028",
    name: "Green Star Rafflesia",
    cost: "1",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Red",
    type: "Straw Hat Crew",
    category: "EVENT",
    effect:
      "[Counter] Give up to 1 of your opponent's Leader or Character cards −2000 power during this turn.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "C",
    image: "OP01-028.png",
    life: "",
    trigger: "[Trigger] Activate this card's [Counter] effect.",
  },
  {
    number: "OP01-029",
    name: "Radical Beam!!",
    cost: "1",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Red",
    type: "Straw Hat Crew",
    category: "EVENT",
    effect:
      "[Counter] Up to 1 of your Leader or Character cards gains +2000 power during this battle. Then, if you have 2 or less Life cards, that card gains an additional +2000 power.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "UC",
    image: "OP01-029.png",
    life: "",
    trigger:
      "[Trigger] Up to 1 of your Leader or Character cards gains +1000 power during this turn.",
  },
  {
    number: "OP01-030",
    name: "In Two Years!! At the Sabaody Archipelago!!",
    cost: "1",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Red",
    type: "Straw Hat Crew",
    category: "EVENT",
    effect:
      "[Main] Look at 5 cards from the top of your deck; reveal up to 1 {Straw Hat Crew} type Character card and add it to your hand. Then, place the rest at the bottom of your deck in any order.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "UC",
    image: "OP01-030.png",
    life: "",
    trigger: "[Trigger] Activate this card's [Main] effect.",
  },
  {
    number: "OP01-031",
    name: "Kouzuki Oden",
    cost: "",
    attribute: "Slash",
    power: "5000",
    counter: "-",
    color: "Green",
    type: "Land of Wano/Kouzuki Clan",
    category: "LEADER",
    effect:
      "[Activate: Main] [Once Per Turn] You can trash 1 {Land of Wano} type card from your hand: Set up to 2 of your DON!! cards as active.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "L",
    image: "OP01-031.png",
    life: "5",
    trigger: "",
  },
  {
    number: "OP01-031_p1",
    name: "Kouzuki Oden",
    cost: "",
    attribute: "Slash",
    power: "5000",
    counter: "-",
    color: "Green",
    type: "Land of Wano/Kouzuki Clan",
    category: "LEADER",
    effect:
      "[Activate: Main] [Once Per Turn] You can trash 1 {Land of Wano} type card from your hand: Set up to 2 of your DON!! cards as active.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "L",
    image: "OP01-031_p1.png",
    life: "5",
    trigger: "",
  },
  {
    number: "OP01-032",
    name: "Ashura Doji",
    cost: "3",
    attribute: "Slash",
    power: "4000",
    counter: "1000",
    color: "Green",
    type: "Land of Wano/The Akazaya Nine",
    category: "CHARACTER",
    effect:
      "[DON!! x1] If your opponent has 2 or more rested Characters, this Character gains +2000 power.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "UC",
    image: "OP01-032.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-033",
    name: "Izo",
    cost: "3",
    attribute: "Ranged",
    power: "3000",
    counter: "2000",
    color: "Green",
    type: "Land of Wano/Former Whitebeard Pirates",
    category: "CHARACTER",
    effect:
      "[On Play] Rest up to 1 of your opponent's Characters with a cost of 4 or less.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "UC",
    image: "OP01-033.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-034",
    name: "Inuarashi",
    cost: "3",
    attribute: "Slash",
    power: "4000",
    counter: "1000",
    color: "Green",
    type: "Minks/Land of Wano/The Akazaya Nine",
    category: "CHARACTER",
    effect:
      "[DON!! x2] [When Attacking] Set up to 1 of your DON!! cards as active.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "C",
    image: "OP01-034.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-034_p1",
    name: "Inuarashi",
    cost: "3",
    attribute: "Slash",
    power: "4000",
    counter: "1000",
    color: "Green",
    type: "Minks/Land of Wano/The Akazaya Nine",
    category: "CHARACTER",
    effect:
      "[DON!! x2] [When Attacking] Set up to 1 of your DON!! cards as active.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "C",
    image: "OP01-034_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-035",
    name: "Okiku",
    cost: "3",
    attribute: "Slash",
    power: "5000",
    counter: "-",
    color: "Green",
    type: "Land of Wano/The Akazaya Nine",
    category: "CHARACTER",
    effect:
      "[DON!! x1] [When Attacking] [Once Per Turn] Rest up to 1 of your opponent's Characters with a cost of 5 or less.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "R",
    image: "OP01-035.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-036",
    name: "Otsuru",
    cost: "1",
    attribute: "Wisdom",
    power: "3000",
    counter: "1000",
    color: "Green",
    type: "Land of Wano",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "C",
    image: "OP01-036.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-037",
    name: "Kawamatsu",
    cost: "2",
    attribute: "Slash",
    power: "3000",
    counter: "1000",
    color: "Green",
    type: "Fish-Man/Land of Wano/The Akazaya Nine",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "C",
    image: "OP01-037.png",
    life: "",
    trigger: "[Trigger] Play this card.",
  },
  {
    number: "OP01-038",
    name: "Kanjuro",
    cost: "2",
    attribute: "Slash",
    power: "3000",
    counter: "1000",
    color: "Green",
    type: "Land of Wano/The Akazaya Nine",
    category: "CHARACTER",
    effect:
      "[DON!! x1] [When Attacking] K.O. up to 1 of your opponent's rested Characters with a cost of 2 or less.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "C",
    image: "OP01-038.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-039",
    name: "Killer",
    cost: "2",
    attribute: "Slash",
    power: "2000",
    counter: "1000",
    color: "Green",
    type: "Supernovas/Kid Pirates",
    category: "CHARACTER",
    effect:
      "[Blocker] (After your opponent declares an attack, you may rest this card to make it the new target of the attack.)",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "UC",
    image: "OP01-039.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-040",
    name: "Kin'emon",
    cost: "6",
    attribute: "Slash",
    power: "6000",
    counter: "-",
    color: "Green",
    type: "Land of Wano/The Akazaya Nine",
    category: "CHARACTER",
    effect:
      "[On Play] If your Leader is [Kouzuki Oden], play up to 1 {The Akazaya Nine} type Character card with a cost of 3 or less from your hand.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "SR",
    image: "OP01-040.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-040_p1",
    name: "Kin'emon",
    cost: "6",
    attribute: "Slash",
    power: "6000",
    counter: "-",
    color: "Green",
    type: "Land of Wano/The Akazaya Nine",
    category: "CHARACTER",
    effect:
      "[On Play] If your Leader is [Kouzuki Oden], play up to 1 {The Akazaya Nine} type Character card with a cost of 3 or less from your hand.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "SR",
    image: "OP01-040_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-041",
    name: "Kouzuki Momonosuke",
    cost: "1",
    attribute: "Slash",
    power: "-",
    counter: "1000",
    color: "Green",
    type: "Land of Wano/Kouzuki Clan",
    category: "CHARACTER",
    effect:
      "[Activate: Main] ➀ (You may rest the specified number of DON!! cards in your cost area) You may rest this Character: Look at 5 cards from the top of your deck; reveal up to 1 {Land of Wano} type card and add it to your hand. Then, place the rest at the bottom of your deck in any order.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "R",
    image: "OP01-041.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-042",
    name: "Komurasaki",
    cost: "1",
    attribute: "Wisdom",
    power: "-",
    counter: "1000",
    color: "Green",
    type: "Land of Wano/Kouzuki Clan",
    category: "CHARACTER",
    effect:
      "[On Play] ③ (You may rest the specified number of DON!! cards in your cost area.): If your Leader is [Kouzuki Oden], set up to 1 of your {Land of Wano} type Character cards with a cost of 3 or less as active.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "UC",
    image: "OP01-042.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-043",
    name: "Shinobu",
    cost: "3",
    attribute: "Special",
    power: "5000",
    counter: "1000",
    color: "Green",
    type: "Land of Wano",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "C",
    image: "OP01-043.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-044",
    name: "Shachi",
    cost: "3",
    attribute: "Strike",
    power: "4000",
    counter: "1000",
    color: "Green",
    type: "Heart Pirates",
    category: "CHARACTER",
    effect:
      "[Blocker] (After your opponent declares an attack, you may rest this card to make it the new target of the attack.)",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "C",
    image: "OP01-044.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-045",
    name: "Jean Bart",
    cost: "4",
    attribute: "Strike",
    power: "6000",
    counter: "1000",
    color: "Green",
    type: "Heart Pirates",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "C",
    image: "OP01-045.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-046",
    name: "Denjiro",
    cost: "5",
    attribute: "Slash",
    power: "7000",
    counter: "-",
    color: "Green",
    type: "Land of Wano/The Akazaya Nine",
    category: "CHARACTER",
    effect:
      "[DON!! x1] [When Attacking] If your Leader is [Kouzuki Oden], set up to 2 of your DON!! cards as active.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "R",
    image: "OP01-046.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-047",
    name: "Trafalgar Law",
    cost: "5",
    attribute: "Slash",
    power: "6000",
    counter: "-",
    color: "Green",
    type: "Supernovas/Heart Pirates",
    category: "CHARACTER",
    effect:
      "[Blocker] (After your opponent declares an attack, you may rest this card to make it the new target of the attack.)",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "SR",
    image: "OP01-047.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-047_p1",
    name: "Trafalgar Law",
    cost: "5",
    attribute: "Slash",
    power: "6000",
    counter: "-",
    color: "Green",
    type: "Supernovas/Heart Pirates",
    category: "CHARACTER",
    effect:
      "[Blocker] (After your opponent declares an attack, you may rest this card to make it the new target of the attack.)",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "SR",
    image: "OP01-047_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-048",
    name: "Nekomamushi",
    cost: "2",
    attribute: "Slash",
    power: "3000",
    counter: "1000",
    color: "Green",
    type: "Minks/Land of Wano/The Akazaya Nine",
    category: "CHARACTER",
    effect:
      "[On Play] Rest up to 1 of your opponent's Characters with a cost of 3 or less.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "C",
    image: "OP01-048.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-048_p1",
    name: "Nekomamushi",
    cost: "2",
    attribute: "Slash",
    power: "3000",
    counter: "1000",
    color: "Green",
    type: "Minks/Land of Wano/The Akazaya Nine",
    category: "CHARACTER",
    effect:
      "[On Play] Rest up to 1 of your opponent's Characters with a cost of 3 or less.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "C",
    image: "OP01-048_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-049",
    name: "Bepo",
    cost: "4",
    attribute: "Strike",
    power: "4000",
    counter: "2000",
    color: "Green",
    type: "Minks/Heart Pirates",
    category: "CHARACTER",
    effect:
      "[DON!! x1] [When Attacking] Play up to 1 {Heart Pirates} type Character card other than [Bepo] with a cost of 4 or less from your hand.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "R",
    image: "OP01-049.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-050",
    name: "Penguin",
    cost: "3",
    attribute: "Strike",
    power: "2000",
    counter: "1000",
    color: "Green",
    type: "Heart Pirates",
    category: "CHARACTER",
    effect:
      "[Blocker] (After your opponent declares an attack, you may rest this card to make it the new target of the attack.)",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "C",
    image: "OP01-050.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-051",
    name: 'Eustass"Captain"Kid',
    cost: "8",
    attribute: "Special",
    power: "8000",
    counter: "-",
    color: "Green",
    type: "Supernovas/Kid Pirates",
    category: "CHARACTER",
    effect:
      '[DON!! x1] [Opponent\'s Turn] If this Character is rested, your opponent cannot attack any card other than the Character [Eustass"Captain"Kid].',
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "SR",
    image: "OP01-051.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-051_p1",
    name: 'Eustass"Captain"Kid',
    cost: "8",
    attribute: "Special",
    power: "8000",
    counter: "-",
    color: "Green",
    type: "Supernovas/Kid Pirates",
    category: "CHARACTER",
    effect:
      '[DON!! x1] [Opponent\'s Turn] If this Character is rested, your opponent cannot attack any card other than the Character [Eustass"Captain"Kid].',
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "SR",
    image: "OP01-051_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-051_p2",
    name: 'Eustass"Captain"Kid',
    cost: "8",
    attribute: "Special",
    power: "8000",
    counter: "-",
    color: "Green",
    type: "Supernovas/Kid Pirates",
    category: "CHARACTER",
    effect:
      '[DON!! x1] [Opponent\'s Turn] If this Character is rested, your opponent cannot attack any card other than the Character [Eustass"Captain"Kid].',
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "SP CARD",
    image: "OP01-051_p2.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-052",
    name: "Raizo",
    cost: "3",
    attribute: "Slash",
    power: "4000",
    counter: "1000",
    color: "Green",
    type: "Land of Wano/The Akazaya Nine",
    category: "CHARACTER",
    effect:
      "[When Attacking] [Once Per Turn] If you have 2 or more rested Characters, draw 1 card.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "UC",
    image: "OP01-052.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-053",
    name: "Wire",
    cost: "2",
    attribute: "Slash",
    power: "4000",
    counter: "1000",
    color: "Green",
    type: "Kid Pirates",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "C",
    image: "OP01-053.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-054",
    name: "X.Drake",
    cost: "5",
    attribute: "Slash",
    power: "6000",
    counter: "1000",
    color: "Green",
    type: "Supernovas/Navy/Drake Pirates",
    category: "CHARACTER",
    effect:
      "[On Play] K.O. up to 1 of your opponent's rested Characters with a cost of 4 or less.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "R",
    image: "OP01-054.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-055",
    name: "You Can Be My Samurai!!",
    cost: "1",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Green",
    type: "Land of Wano/Kouzuki Clan",
    category: "EVENT",
    effect: "[Main] You may rest 2 of your Characters: Draw 2 cards.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "C",
    image: "OP01-055.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-056",
    name: "Demon Face",
    cost: "6",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Green",
    type: "Supernovas/Hawkins Pirates",
    category: "EVENT",
    effect:
      "[Main] K.O. up to 2 of your opponent's rested Characters with a cost of 5 or less.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "UC",
    image: "OP01-056.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-057",
    name: "Paradise Waterfall",
    cost: "1",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Green",
    type: "Land of Wano/Kouzuki Clan",
    category: "EVENT",
    effect:
      "[Counter] Up to 1 of your Leader or Character cards gains +2000 power during this battle. Then, set up to 1 of your Characters as active.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "UC",
    image: "OP01-057.png",
    life: "",
    trigger:
      "[Trigger] K.O. up to 1 of your opponent's rested Characters with a cost of 4 or less.",
  },
  {
    number: "OP01-058",
    name: "Punk Gibson",
    cost: "2",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Green",
    type: "Supernovas/Kid Pirates",
    category: "EVENT",
    effect:
      "[Counter] Up to 1 of your Leader or Character cards gains +4000 power during this battle. Then, rest up to 1 of your opponent's Characters with a cost of 4 or less.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "R",
    image: "OP01-058.png",
    life: "",
    trigger: "[Trigger] Rest up to 1 of your opponent's Characters.",
  },
  {
    number: "OP01-059",
    name: "BE-BENG!!",
    cost: "3",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Green",
    type: "Land of Wano",
    category: "EVENT",
    effect:
      "[Main] You may trash 1 {Land of Wano} type card from your hand: Set up to 1 of your {Land of Wano} type Character cards with a cost of 3 or less as active.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "C",
    image: "OP01-059.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-060",
    name: "Donquixote Doflamingo",
    cost: "",
    attribute: "Special",
    power: "5000",
    counter: "-",
    color: "Blue",
    type: "The Seven Warlords of the Sea/Donquixote Pirates",
    category: "LEADER",
    effect:
      "[DON!! x2] [When Attacking] ➀ (You may rest the specified number of DON!! cards in your cost area.): Reveal 1 card from the top of your deck. If that card is a {The Seven Warlords of the Sea} type Character card with a cost of 4 or less, you may play that card rested.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "L",
    image: "OP01-060.png",
    life: "5",
    trigger: "",
  },
  {
    number: "OP01-060_p1",
    name: "Donquixote Doflamingo",
    cost: "",
    attribute: "Special",
    power: "5000",
    counter: "-",
    color: "Blue",
    type: "The Seven Warlords of the Sea/Donquixote Pirates",
    category: "LEADER",
    effect:
      "[DON!! x2] [When Attacking] ➀ (You may rest the specified number of DON!! cards in your cost area.): Reveal 1 card from the top of your deck. If that card is a {The Seven Warlords of the Sea} type Character card with a cost of 4 or less, you may play that card rested.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "L",
    image: "OP01-060_p1.png",
    life: "5",
    trigger: "",
  },
  {
    number: "OP01-061",
    name: "Kaido",
    cost: "",
    attribute: "Strike",
    power: "5000",
    counter: "-",
    color: "Blue/Purple",
    type: "The Four Emperors/Animal Kingdom Pirates",
    category: "LEADER",
    effect:
      "[DON!! x1] [Your Turn] [Once Per Turn] When your opponent's Character is K.O.'d, add up to 1 DON!! card from your DON!! deck and set it as active.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "L",
    image: "OP01-061.png",
    life: "4",
    trigger: "",
  },
  {
    number: "OP01-061_p1",
    name: "Kaido",
    cost: "",
    attribute: "Strike",
    power: "5000",
    counter: "-",
    color: "Blue/Purple",
    type: "The Four Emperors/Animal Kingdom Pirates",
    category: "LEADER",
    effect:
      "[DON!! x1] [Your Turn] [Once Per Turn] When your opponent's Character is K.O.'d, add up to 1 DON!! card from your DON!! deck and set it as active.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "L",
    image: "OP01-061_p1.png",
    life: "4",
    trigger: "",
  },
  {
    number: "OP01-062",
    name: "Crocodile",
    cost: "",
    attribute: "Special",
    power: "5000",
    counter: "-",
    color: "Blue/Purple",
    type: "The Seven Warlords of the Sea/Baroque Works",
    category: "LEADER",
    effect:
      "[DON!! x1] When you activate an Event, you may draw 1 card if you have 4 or less cards in your hand and haven't drawn a card using this Leader's effect during this turn.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "L",
    image: "OP01-062.png",
    life: "4",
    trigger: "",
  },
  {
    number: "OP01-062_p1",
    name: "Crocodile",
    cost: "",
    attribute: "Special",
    power: "5000",
    counter: "-",
    color: "Blue/Purple",
    type: "The Seven Warlords of the Sea/Baroque Works",
    category: "LEADER",
    effect:
      "[DON!! x1] When you activate an Event, you may draw 1 card if you have 4 or less cards in your hand and haven't drawn a card using this Leader's effect during this turn.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "L",
    image: "OP01-062_p1.png",
    life: "4",
    trigger: "",
  },
  {
    number: "OP01-063",
    name: "Arlong",
    cost: "4",
    attribute: "Slash",
    power: "5000",
    counter: "1000",
    color: "Blue",
    type: "Fish-Man/Arlong Pirates",
    category: "CHARACTER",
    effect:
      "[DON!! x1] [Activate: Main] You may rest this Character: Choose 1 card from your opponent's hand; your opponent reveals that card. If the revealed card is an Event, place up to 1 card from your opponent's Life area at the bottom of the owner's deck.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "UC",
    image: "OP01-063.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-064",
    name: "Alvida",
    cost: "2",
    attribute: "Strike",
    power: "3000",
    counter: "2000",
    color: "Blue",
    type: "Buggy Pirates",
    category: "CHARACTER",
    effect:
      "[DON!! x1] [When Attacking] You may trash 1 card from your hand: Return up to 1 of your opponent's Characters with a cost of 3 or less to the owner's hand.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "C",
    image: "OP01-064.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-064_p1",
    name: "Alvida",
    cost: "2",
    attribute: "Strike",
    power: "3000",
    counter: "2000",
    color: "Blue",
    type: "Buggy Pirates",
    category: "CHARACTER",
    effect:
      "[DON!! x1] [When Attacking] You may trash 1 card from your hand: Return up to 1 of your opponent's Characters with a cost of 3 or less to the owner's hand.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "C",
    image: "OP01-064_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-065",
    name: "Vergo",
    cost: "5",
    attribute: "Strike",
    power: "7000",
    counter: "1000",
    color: "Blue",
    type: "Navy/Donquixote Pirates/Punk Hazard",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "C",
    image: "OP01-065.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-066",
    name: "Krieg",
    cost: "4",
    attribute: "Slash",
    power: "6000",
    counter: "1000",
    color: "Blue",
    type: "Krieg Pirates",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "C",
    image: "OP01-066.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-067",
    name: "Crocodile",
    cost: "7",
    attribute: "Special",
    power: "7000",
    counter: "1000",
    color: "Blue",
    type: "The Seven Warlords of the Sea/Baroque Works",
    category: "CHARACTER",
    effect:
      "[Banish] (When this card deals damage, the target card is trashed without activating its Trigger.)",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "SR",
    image: "OP01-067.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-067_p1",
    name: "Crocodile",
    cost: "7",
    attribute: "Special",
    power: "7000",
    counter: "1000",
    color: "Blue",
    type: "The Seven Warlords of the Sea/Baroque Works",
    category: "CHARACTER",
    effect:
      "[Banish] (When this card deals damage, the target card is trashed without activating its Trigger.)",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "SR",
    image: "OP01-067_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-068",
    name: "Gecko Moria",
    cost: "4",
    attribute: "Special",
    power: "5000",
    counter: "1000",
    color: "Blue",
    type: "The Seven Warlords of the Sea/Thriller Bark Pirates",
    category: "CHARACTER",
    effect:
      "[Your Turn] This Character gains [Double Attack] if you have 5 or more cards in your hand.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "R",
    image: "OP01-068.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-069",
    name: "Caesar Clown",
    cost: "4",
    attribute: "Special",
    power: "5000",
    counter: "1000",
    color: "Blue",
    type: "Scientist/Punk Hazard",
    category: "CHARACTER",
    effect:
      "[On K.O.] Play up to 1 [Smiley] from your deck, then shuffle your deck.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "R",
    image: "OP01-069.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-070",
    name: "Dracule Mihawk",
    cost: "9",
    attribute: "Slash",
    power: "9000",
    counter: "-",
    color: "Blue",
    type: "The Seven Warlords of the Sea",
    category: "CHARACTER",
    effect:
      "[On Play] Place up to 1 Character with a cost of 7 or less at the bottom of the owner's deck.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "SR",
    image: "OP01-070.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-070_p1",
    name: "Dracule Mihawk",
    cost: "9",
    attribute: "Slash",
    power: "9000",
    counter: "-",
    color: "Blue",
    type: "The Seven Warlords of the Sea",
    category: "CHARACTER",
    effect:
      "[On Play] Place up to 1 Character with a cost of 7 or less at the bottom of the owner's deck.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "SR",
    image: "OP01-070_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-071",
    name: "Jinbe",
    cost: "4",
    attribute: "Strike",
    power: "2000",
    counter: "-",
    color: "Blue",
    type: "Fish-Man/Straw Hat Crew",
    category: "CHARACTER",
    effect:
      "[On Play] Place up to 1 Character with a cost of 3 or less at the bottom of the owner's deck.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "R",
    image: "OP01-071.png",
    life: "",
    trigger: "[Trigger] Play this card.",
  },
  {
    number: "OP01-072",
    name: "Smiley",
    cost: "3",
    attribute: "Special",
    power: "1000",
    counter: "1000",
    color: "Blue",
    type: "Biological Weapon/Punk Hazard",
    category: "CHARACTER",
    effect:
      "[DON!! x1] [Your Turn] This Character gains +1000 power for every card in your hand.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "C",
    image: "OP01-072.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-073",
    name: "Donquixote Doflamingo",
    cost: "3",
    attribute: "Special",
    power: "4000",
    counter: "1000",
    color: "Blue",
    type: "The Seven Warlords of the Sea/Donquixote Pirates",
    category: "CHARACTER",
    effect:
      "[Blocker] (After your opponent declares an attack, you may rest this card to make it the new target of the attack.)",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "R",
    image: "OP01-073.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-073_p1",
    name: "Donquixote Doflamingo",
    cost: "3",
    attribute: "Special",
    power: "4000",
    counter: "1000",
    color: "Blue",
    type: "The Seven Warlords of the Sea/Donquixote Pirates",
    category: "CHARACTER",
    effect:
      "[Blocker] (After your opponent declares an attack, you may rest this card to make it the new target of the attack.)",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "R",
    image: "OP01-073_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-074",
    name: "Bartholomew Kuma",
    cost: "4",
    attribute: "Strike",
    power: "5000",
    counter: "1000",
    color: "Blue",
    type: "The Seven Warlords of the Sea/Revolutionary Army",
    category: "CHARACTER",
    effect:
      "[Blocker] (After your opponent declares an attack, you may rest this card to make it the new target of the attack.)",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "R",
    image: "OP01-074.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-075",
    name: "Pacifista",
    cost: "4",
    attribute: "Special",
    power: "5000",
    counter: "-",
    color: "Blue",
    type: "Biological Weapon/Navy",
    category: "CHARACTER",
    effect:
      "Under the rules of this game, you may have any number of this card in your deck.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "C",
    image: "OP01-075.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-076",
    name: "Bellamy",
    cost: "2",
    attribute: "Strike",
    power: "4000",
    counter: "1000",
    color: "Blue",
    type: "Dressrosa",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "C",
    image: "OP01-076.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-077",
    name: "Perona",
    cost: "1",
    attribute: "Special",
    power: "2000",
    counter: "1000",
    color: "Blue",
    type: "Thriller Bark Pirates",
    category: "CHARACTER",
    effect:
      "[On Play] Look at 5 cards from the top of your deck and place them at the top or bottom of the deck in any order.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "UC",
    image: "OP01-077.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-077_p1",
    name: "Perona",
    cost: "1",
    attribute: "Special",
    power: "2000",
    counter: "1000",
    color: "Blue",
    type: "Thriller Bark Pirates",
    category: "CHARACTER",
    effect:
      "[On Play] Look at 5 cards from the top of your deck and place them at the top or bottom of the deck in any order.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "UC",
    image: "OP01-077_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-078",
    name: "Boa Hancock",
    cost: "4",
    attribute: "Special",
    power: "5000",
    counter: "1000",
    color: "Blue",
    type: "The Seven Warlords of the Sea/Kuja Pirates",
    category: "CHARACTER",
    effect:
      "[Blocker] (After your opponent declares an attack, you may rest this card to make it the new target of the attack.)",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "SR",
    image: "OP01-078.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-078_p1",
    name: "Boa Hancock",
    cost: "4",
    attribute: "Special",
    power: "5000",
    counter: "1000",
    color: "Blue",
    type: "The Seven Warlords of the Sea/Kuja Pirates",
    category: "CHARACTER",
    effect:
      "[Blocker] (After your opponent declares an attack, you may rest this card to make it the new target of the attack.)",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "SR",
    image: "OP01-078_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-079",
    name: "Ms. All Sunday",
    cost: "3",
    attribute: "Strike",
    power: "1000",
    counter: "1000",
    color: "Blue",
    type: "Baroque Works",
    category: "CHARACTER",
    effect:
      "[Blocker] (After your opponent declares an attack, you may rest this card to make it the new target of the attack.)",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "R",
    image: "OP01-079.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-080",
    name: "Miss Doublefinger(Zala)",
    cost: "3",
    attribute: "Slash",
    power: "4000",
    counter: "1000",
    color: "Blue",
    type: "Baroque Works",
    category: "CHARACTER",
    effect: "[On K.O.] Draw 1 card.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "C",
    image: "OP01-080.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-081",
    name: "Mocha",
    cost: "3",
    attribute: "Strike",
    power: "5000",
    counter: "1000",
    color: "Blue",
    type: "Punk Hazard",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "C",
    image: "OP01-081.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-082",
    name: "Monet",
    cost: "2",
    attribute: "Special",
    power: "3000",
    counter: "1000",
    color: "Blue",
    type: "Donquixote Pirates/Punk Hazard",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "C",
    image: "OP01-082.png",
    life: "",
    trigger: "[Trigger] Play this card.",
  },
  {
    number: "OP01-083",
    name: "Mr.1(Daz.Bonez)",
    cost: "2",
    attribute: "Slash",
    power: "3000",
    counter: "1000",
    color: "Blue",
    type: "Baroque Works",
    category: "CHARACTER",
    effect:
      "[DON!! x1] [Your Turn] If your Leader has the {Baroque Works} type, this Character gains +1000 power for every 2 Events in your trash.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "UC",
    image: "OP01-083.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-084",
    name: "Mr.2.Bon.Kurei(Bentham)",
    cost: "3",
    attribute: "Strike",
    power: "4000",
    counter: "2000",
    color: "Blue",
    type: "Baroque Works",
    category: "CHARACTER",
    effect:
      "[DON!! x1] [When Attacking] Look at 5 cards from the top of your deck; reveal up to 1 {Baroque Works} type Event card and add it to your hand. Then, place the rest at the bottom of your deck in any order.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "UC",
    image: "OP01-084.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-085",
    name: "Mr.3(Galdino)",
    cost: "2",
    attribute: "Special",
    power: "3000",
    counter: "1000",
    color: "Blue",
    type: "Baroque Works",
    category: "CHARACTER",
    effect:
      "[On Play] If your Leader has the {Baroque Works} type, select up to 1 of your opponent's Characters with a cost of 4 or less. The selected Character cannot attack until the end of your opponent's next turn.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "UC",
    image: "OP01-085.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-086",
    name: "Overheat",
    cost: "2",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Blue",
    type: "The Seven Warlords of the Sea/Donquixote Pirates",
    category: "EVENT",
    effect:
      "[Counter] Up to 1 of your Leader or Character cards gains +4000 power during this battle. Then, return up to 1 active Character with a cost of 3 or less to the owner's hand.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "R",
    image: "OP01-086.png",
    life: "",
    trigger:
      "[Trigger] Return up to 1 Character with a cost of 4 or less to the owner's hand.",
  },
  {
    number: "OP01-087",
    name: "Officer Agents",
    cost: "2",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Blue",
    type: "Baroque Works",
    category: "EVENT",
    effect:
      "[Counter] Play up to 1 {Baroque Works} type Character card with a cost of 3 or less from your hand.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "C",
    image: "OP01-087.png",
    life: "",
    trigger: "[Trigger] Activate this card's [Counter] effect.",
  },
  {
    number: "OP01-088",
    name: "Desert Spada",
    cost: "1",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Blue",
    type: "The Seven Warlords of the Sea/Baroque Works",
    category: "EVENT",
    effect:
      "[Counter] Up to 1 of your Leader or Character cards gains +2000 power during this battle. Then, look at 3 cards from the top of your deck and place them at the top or bottom of the deck in any order.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "UC",
    image: "OP01-088.png",
    life: "",
    trigger: "[Trigger] Draw 2 cards and trash 1 card from your hand.",
  },
  {
    number: "OP01-089",
    name: "Crescent Cutlass",
    cost: "3",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Blue",
    type: "The Seven Warlords of the Sea/Baroque Works",
    category: "EVENT",
    effect:
      "[Counter] If your Leader has the {The Seven Warlords of the Sea} type, return up to 1 Character with a cost of 5 or less to the owner's hand.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "C",
    image: "OP01-089.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-090",
    name: "Baroque Works",
    cost: "1",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Blue",
    type: "Baroque Works",
    category: "EVENT",
    effect:
      "[Main] Look at 5 cards from the top of your deck; reveal up to 1 {Baroque Works} type card other than [Baroque Works] and add it to your hand. Then, place the rest at the bottom of your deck in any order.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "UC",
    image: "OP01-090.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-091",
    name: "King",
    cost: "",
    attribute: "Special",
    power: "5000",
    counter: "-",
    color: "Purple",
    type: "Animal Kingdom Pirates",
    category: "LEADER",
    effect:
      "[Your Turn] If you have 10 DON!! cards on your field, give all of your opponent's Characters −1000 power.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "L",
    image: "OP01-091.png",
    life: "5",
    trigger: "",
  },
  {
    number: "OP01-091_p1",
    name: "King",
    cost: "",
    attribute: "Special",
    power: "5000",
    counter: "-",
    color: "Purple",
    type: "Animal Kingdom Pirates",
    category: "LEADER",
    effect:
      "[Your Turn] If you have 10 DON!! cards on your field, give all of your opponent's Characters −1000 power.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "L",
    image: "OP01-091_p1.png",
    life: "5",
    trigger: "",
  },
  {
    number: "OP01-092",
    name: "Urashima",
    cost: "7",
    attribute: "Strike",
    power: "9000",
    counter: "1000",
    color: "Purple",
    type: "Land of Wano",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "C",
    image: "OP01-092.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-093",
    name: "Ulti",
    cost: "2",
    attribute: "Strike",
    power: "3000",
    counter: "-",
    color: "Purple",
    type: "Animal Kingdom Pirates",
    category: "CHARACTER",
    effect:
      "[On Play] ① (You may rest the specified number of DON!! cards in your cost area.): Add up to 1 DON!! card from your DON!! deck and rest it.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "R",
    image: "OP01-093.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-093_p1",
    name: "Ulti",
    cost: "2",
    attribute: "Strike",
    power: "3000",
    counter: "-",
    color: "Purple",
    type: "Animal Kingdom Pirates",
    category: "CHARACTER",
    effect:
      "[On Play] ① (You may rest the specified number of DON!! cards in your cost area.): Add up to 1 DON!! card from your DON!! deck and rest it.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "R",
    image: "OP01-093_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-094",
    name: "Kaido",
    cost: "10",
    attribute: "Strike",
    power: "12000",
    counter: "-",
    color: "Purple",
    type: "The Four Emperors/Animal Kingdom Pirates",
    category: "CHARACTER",
    effect:
      "[On Play] DON!! −6 (You may return the specified number of DON!! cards from your field to your DON!! deck.): If your Leader has the {Animal Kingdom Pirates} type, K.O. all Characters other than this Character.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "SR",
    image: "OP01-094.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-094_p1",
    name: "Kaido",
    cost: "10",
    attribute: "Strike",
    power: "12000",
    counter: "-",
    color: "Purple",
    type: "The Four Emperors/Animal Kingdom Pirates",
    category: "CHARACTER",
    effect:
      "[On Play] DON!! −6 (You may return the specified number of DON!! cards from your field to your DON!! deck.): If your Leader has the {Animal Kingdom Pirates} type, K.O. all Characters other than this Character.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "SR",
    image: "OP01-094_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-095",
    name: "Kyoshirou",
    cost: "5",
    attribute: "Slash",
    power: "6000",
    counter: "1000",
    color: "Purple",
    type: "Land of Wano",
    category: "CHARACTER",
    effect:
      "[On Play] Draw 1 card if you have 8 or more DON!! cards on your field.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "UC",
    image: "OP01-095.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-096",
    name: "King",
    cost: "7",
    attribute: "Special",
    power: "7000",
    counter: "-",
    color: "Purple",
    type: "Animal Kingdom Pirates",
    category: "CHARACTER",
    effect:
      "[On Play] DON!! −2 (You may return the specified number of DON!! cards from your field to your DON!! deck.): K.O. up to 1 of your opponent's Characters with a cost of 3 or less and up to 1 of your opponent's Characters with a cost of 2 or less.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "SR",
    image: "OP01-096.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-096_p1",
    name: "King",
    cost: "7",
    attribute: "Special",
    power: "7000",
    counter: "-",
    color: "Purple",
    type: "Animal Kingdom Pirates",
    category: "CHARACTER",
    effect:
      "[On Play] DON!! −2 (You may return the specified number of DON!! cards from your field to your DON!! deck.): K.O. up to 1 of your opponent's Characters with a cost of 3 or less and up to 1 of your opponent's Characters with a cost of 2 or less.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "SR",
    image: "OP01-096_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-097",
    name: "Queen",
    cost: "6",
    attribute: "Strike",
    power: "5000",
    counter: "1000",
    color: "Purple",
    type: "Animal Kingdom Pirates",
    category: "CHARACTER",
    effect:
      "[On Play] DON!! −1 (You may return the specified number of DON!! cards from your field to your DON!! deck.): This Character gains [Rush] during this turn. Then, give up to 1 of your opponent's Characters −2000 power during this turn.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "R",
    image: "OP01-097.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-097_p1",
    name: "Queen",
    cost: "6",
    attribute: "Strike",
    power: "5000",
    counter: "1000",
    color: "Purple",
    type: "Animal Kingdom Pirates",
    category: "CHARACTER",
    effect:
      "[On Play] DON!! −1 (You may return the specified number of DON!! cards from your field to your DON!! deck.): This Character gains [Rush] during this turn. Then, give up to 1 of your opponent's Characters −2000 power during this turn.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "R",
    image: "OP01-097_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-098",
    name: "Kurozumi Orochi",
    cost: "1",
    attribute: "Wisdom",
    power: "2000",
    counter: "2000",
    color: "Purple",
    type: "Land of Wano/Kurozumi Clan",
    category: "CHARACTER",
    effect:
      "[On Play] Reveal up to 1 [Artificial Devil Fruit SMILE] from your deck and add it to your hand. Then, shuffle your deck.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "UC",
    image: "OP01-098.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-099",
    name: "Kurozumi Semimaru",
    cost: "2",
    attribute: "Special",
    power: "3000",
    counter: "1000",
    color: "Purple",
    type: "Land of Wano/Kurozumi Clan",
    category: "CHARACTER",
    effect:
      "{Kurozumi Clan} type Characters other than your [Kurozumi Semimaru] cannot be K.O.'d in battle.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "C",
    image: "OP01-099.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-100",
    name: "Kurozumi Higurashi",
    cost: "2",
    attribute: "Special",
    power: "3000",
    counter: "1000",
    color: "Purple",
    type: "Land of Wano/Kurozumi Clan",
    category: "CHARACTER",
    effect:
      "[Blocker] (After your opponent declares an attack, you may rest this card to make it the new target of the attack.)",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "C",
    image: "OP01-100.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-101",
    name: "Sasaki",
    cost: "3",
    attribute: "Slash",
    power: "4000",
    counter: "2000",
    color: "Purple",
    type: "Animal Kingdom Pirates",
    category: "CHARACTER",
    effect:
      "[DON!! x1] [When Attacking] You may trash 1 card from your hand: Add up to 1 DON!! card from your DON!! deck and rest it.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "UC",
    image: "OP01-101.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-102",
    name: "Jack",
    cost: "3",
    attribute: "Strike",
    power: "4000",
    counter: "1000",
    color: "Purple",
    type: "Animal Kingdom Pirates",
    category: "CHARACTER",
    effect:
      "[When Attacking] DON!! −1 (You may return the specified number of DON!! cards from your field to your DON!! deck.): Your opponent trashes 1 card from their hand.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "R",
    image: "OP01-102.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-102_p1",
    name: "Jack",
    cost: "3",
    attribute: "Strike",
    power: "4000",
    counter: "1000",
    color: "Purple",
    type: "Animal Kingdom Pirates",
    category: "CHARACTER",
    effect:
      "[When Attacking] DON!! −1 (You may return the specified number of DON!! cards from your field to your DON!! deck.): Your opponent trashes 1 card from their hand.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "R",
    image: "OP01-102_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-103",
    name: "Scratchmen Apoo",
    cost: "4",
    attribute: "Ranged",
    power: "6000",
    counter: "1000",
    color: "Purple",
    type: "Animal Kingdom Pirates/On-Air Pirates",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "C",
    image: "OP01-103.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-104",
    name: "Speed",
    cost: "2",
    attribute: "Strike",
    power: "3000",
    counter: "1000",
    color: "Purple",
    type: "Animal Kingdom Pirates/SMILE",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "C",
    image: "OP01-104.png",
    life: "",
    trigger: "[Trigger] Play this card.",
  },
  {
    number: "OP01-105",
    name: "Bao Huang",
    cost: "2",
    attribute: "Wisdom",
    power: "3000",
    counter: "1000",
    color: "Purple",
    type: "Animal Kingdom Pirates/SMILE",
    category: "CHARACTER",
    effect:
      "[On Play] Choose 2 cards from your opponent's hand; your opponent reveals those cards.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "C",
    image: "OP01-105.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-106",
    name: "Basil Hawkins",
    cost: "4",
    attribute: "Slash",
    power: "2000",
    counter: "1000",
    color: "Purple",
    type: "Animal Kingdom Pirates/Hawkins Pirates",
    category: "CHARACTER",
    effect:
      "[On Play] Add up to 1 DON!! card from your DON!! deck and rest it.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "UC",
    image: "OP01-106.png",
    life: "",
    trigger: "[Trigger] Play this card.",
  },
  {
    number: "OP01-107",
    name: "Babanuki",
    cost: "5",
    attribute: "Ranged",
    power: "7000",
    counter: "1000",
    color: "Purple",
    type: "Animal Kingdom Pirates/SMILE",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "C",
    image: "OP01-107.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-108",
    name: "Hitokiri Kamazo",
    cost: "4",
    attribute: "Slash",
    power: "5000",
    counter: "1000",
    color: "Purple",
    type: "Supernovas/Kid Pirates/SMILE",
    category: "CHARACTER",
    effect:
      "[On K.O.] DON!! −1 (You may return the specified number of DON!! cards from your field to your DON!! deck.): K.O. up to 1 of your opponent's Characters with a cost of 5 or less.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "UC",
    image: "OP01-108.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-109",
    name: "Who's.Who",
    cost: "2",
    attribute: "Slash",
    power: "3000",
    counter: "1000",
    color: "Purple",
    type: "Animal Kingdom Pirates",
    category: "CHARACTER",
    effect:
      "[DON!! x1] [Your Turn] If you have 8 or more DON!! cards on your field, this Character gains +1000 power.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "UC",
    image: "OP01-109.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-109_p1",
    name: "Who's.Who",
    cost: "2",
    attribute: "Slash",
    power: "3000",
    counter: "1000",
    color: "Purple",
    type: "Animal Kingdom Pirates",
    category: "CHARACTER",
    effect:
      "[DON!! x1] [Your Turn] If you have 8 or more DON!! cards on your field, this Character gains +1000 power.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "UC",
    image: "OP01-109_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-110",
    name: "Fukurokuju",
    cost: "6",
    attribute: "Special",
    power: "8000",
    counter: "1000",
    color: "Purple",
    type: "Land of Wano/Animal Kingdom Pirates",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "C",
    image: "OP01-110.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-111",
    name: "Black Maria",
    cost: "4",
    attribute: "Special",
    power: "5000",
    counter: "1000",
    color: "Purple",
    type: "Animal Kingdom Pirates",
    category: "CHARACTER",
    effect:
      "[Blocker] (After your opponent declares an attack, you may rest this card to make it the new target of the attack.)",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "R",
    image: "OP01-111.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-112",
    name: "Page One",
    cost: "4",
    attribute: "Strike",
    power: "5000",
    counter: "1000",
    color: "Purple",
    type: "Animal Kingdom Pirates",
    category: "CHARACTER",
    effect:
      "[Activate: Main] [Once Per Turn] DON!! −1 (You may return the specified number of DON!! cards from your field to your DON!! deck.): This Character can also attack your opponent's active Characters during this turn.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "R",
    image: "OP01-112.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-113",
    name: "Holedem",
    cost: "3",
    attribute: "Special",
    power: "4000",
    counter: "1000",
    color: "Purple",
    type: "Animal Kingdom Pirates/SMILE",
    category: "CHARACTER",
    effect:
      "[On K.O.] Add up to 1 DON!! card from your DON!! deck and rest it.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "C",
    image: "OP01-113.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-114",
    name: "X.Drake",
    cost: "5",
    attribute: "Slash",
    power: "5000",
    counter: "2000",
    color: "Purple",
    type: "Navy/Drake Pirates/Animal Kingdom Pirates",
    category: "CHARACTER",
    effect:
      "[On Play] DON!! −1 (You may return the specified number of DON!! cards from your field to your DON!! deck.): Your opponent trashes 1 card from their hand.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "R",
    image: "OP01-114.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-115",
    name: "Elephant's Marchoo",
    cost: "4",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Purple",
    type: "Animal Kingdom Pirates/SMILE",
    category: "EVENT",
    effect:
      "[Main] K.O. up to 1 of your opponent's Characters with a cost of 2 or less, then add up to 1 DON!! card from your DON!! deck and set it as active.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "C",
    image: "OP01-115.png",
    life: "",
    trigger: "[Trigger] Activate this card's [Main] effect.",
  },
  {
    number: "OP01-116",
    name: "Artificial Devil Fruit SMILE",
    cost: "2",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Purple",
    type: "Animal Kingdom Pirates/SMILE",
    category: "EVENT",
    effect:
      "[Main] Look at 5 cards from the top of your deck; play up to 1 {SMILE} type Character card with a cost of 3 or less. Then, place the rest at the bottom of your deck in any order.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "UC",
    image: "OP01-116.png",
    life: "",
    trigger: "[Trigger] Activate this card's [Main] effect.",
  },
  {
    number: "OP01-117",
    name: "Sheep's Horn",
    cost: "2",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Purple",
    type: "Animal Kingdom Pirates/SMILE",
    category: "EVENT",
    effect:
      "[Main] DON!! −1 (You may return the specified number of DON!! cards from your field to your DON!! deck.): Rest up to 1 of your opponent's Characters with a cost of 6 or less.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "C",
    image: "OP01-117.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-118",
    name: "Ulti-Mortar",
    cost: "1",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Purple",
    type: "Animal Kingdom Pirates",
    category: "EVENT",
    effect:
      "[Counter] DON!! −2 (You may return the specified number of DON!! cards from your field to your DON!! deck.): Up to 1 of your Leader or Character cards gains +2000 power during this battle. Then, draw 1 card.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "UC",
    image: "OP01-118.png",
    life: "",
    trigger:
      "[Trigger] Add up to 1 DON!! card from your DON!! deck and set it as active.",
  },
  {
    number: "OP01-119",
    name: "Thunder Bagua",
    cost: "2",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Purple",
    type: "The Four Emperors/Animal Kingdom Pirates",
    category: "EVENT",
    effect:
      "[Counter] Up to 1 of your Leader or Character cards gains +4000 power during this battle. Then, if you have 2 or less Life cards, add up to 1 DON!! card from your DON!! deck and rest it.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "R",
    image: "OP01-119.png",
    life: "",
    trigger:
      "[Trigger] Add up to 1 DON!! card from your DON!! deck and set it as active.",
  },
  {
    number: "OP01-120",
    name: "Shanks",
    cost: "9",
    attribute: "Slash",
    power: "10000",
    counter: "-",
    color: "Red",
    type: "The Four Emperors/Red-Haired Pirates",
    category: "CHARACTER",
    effect: "[Rush] (This card can attack on the turn in which it is played.)",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "SEC",
    image: "OP01-120.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-120_p1",
    name: "Shanks",
    cost: "9",
    attribute: "Slash",
    power: "10000",
    counter: "-",
    color: "Red",
    type: "The Four Emperors/Red-Haired Pirates",
    category: "CHARACTER",
    effect: "[Rush] (This card can attack on the turn in which it is played.)",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "SEC",
    image: "OP01-120_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-120_p2",
    name: "Shanks",
    cost: "9",
    attribute: "Slash",
    power: "10000",
    counter: "-",
    color: "Red",
    type: "The Four Emperors/Red-Haired Pirates",
    category: "CHARACTER",
    effect: "[Rush] (This card can attack on the turn in which it is played.)",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "SEC",
    image: "OP01-120_p2.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-121",
    name: "Yamato",
    cost: "5",
    attribute: "Strike",
    power: "5000",
    counter: "1000",
    color: "Green",
    type: "Land of Wano",
    category: "CHARACTER",
    effect:
      "Also treat this card's name as [Kouzuki Oden] according to the rules.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "SEC",
    image: "OP01-121.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP01-121_p1",
    name: "Yamato",
    cost: "5",
    attribute: "Strike",
    power: "5000",
    counter: "1000",
    color: "Green",
    type: "Land of Wano",
    category: "CHARACTER",
    effect:
      "Also treat this card's name as [Kouzuki Oden] according to the rules.",
    cardSet: "-ROMANCE DAWN- [OP01]",
    rarity: "SEC",
    image: "OP01-121_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-001",
    name: "Edward.Newgate",
    cost: "",
    attribute: "Special",
    power: "6000",
    counter: "-",
    color: "Red",
    type: "The Four Emperors/Whitebeard Pirates",
    category: "LEADER",
    effect:
      "[End of Your Turn] Add 1 card from the top of your Life cards to your hand.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "L",
    image: "OP02-001.png",
    life: "6",
    trigger: "",
  },
  {
    number: "OP02-001_p1",
    name: "Edward.Newgate",
    cost: "",
    attribute: "Special",
    power: "6000",
    counter: "-",
    color: "Red",
    type: "The Four Emperors/Whitebeard Pirates",
    category: "LEADER",
    effect:
      "[End of Your Turn] Add 1 card from the top of your Life cards to your hand.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "L",
    image: "OP02-001_p1.png",
    life: "6",
    trigger: "",
  },
  {
    number: "OP02-002",
    name: "Monkey.D.Garp",
    cost: "",
    attribute: "Strike",
    power: "5000",
    counter: "-",
    color: "Red/Black",
    type: "Navy",
    category: "LEADER",
    effect:
      "[Your Turn] When this Leader or any of your Characters is given a DON!! card, give up to 1 of your opponent's Characters with a cost of 7 or less −1 cost during this turn.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "L",
    image: "OP02-002.png",
    life: "4",
    trigger: "",
  },
  {
    number: "OP02-002_p1",
    name: "Monkey.D.Garp",
    cost: "",
    attribute: "Strike",
    power: "5000",
    counter: "-",
    color: "Red/Black",
    type: "Navy",
    category: "LEADER",
    effect:
      "[Your Turn] When this Leader or any of your Characters is given a DON!! card, give up to 1 of your opponent's Characters with a cost of 7 or less −1 cost during this turn.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "L",
    image: "OP02-002_p1.png",
    life: "4",
    trigger: "",
  },
  {
    number: "OP02-003",
    name: "Atmos",
    cost: "4",
    attribute: "Slash",
    power: "6000",
    counter: "1000",
    color: "Red",
    type: "Whitebeard Pirates",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "C",
    image: "OP02-003.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-004",
    name: "Edward.Newgate",
    cost: "9",
    attribute: "Special",
    power: "10000",
    counter: "-",
    color: "Red",
    type: "The Four Emperors/Whitebeard Pirates",
    category: "CHARACTER",
    effect:
      "[On Play] Up to 1 of your Leader gains +2000 power until the start of your next turn. Then, you cannot add Life cards to your hand using your own effects during this turn.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "SR",
    image: "OP02-004.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-004_p1",
    name: "Edward.Newgate",
    cost: "9",
    attribute: "Special",
    power: "10000",
    counter: "-",
    color: "Red",
    type: "The Four Emperors/Whitebeard Pirates",
    category: "CHARACTER",
    effect:
      "[On Play] Up to 1 of your Leader gains +2000 power until the start of your next turn. Then, you cannot add Life cards to your hand using your own effects during this turn.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "SR",
    image: "OP02-004_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-005",
    name: "Curly.Dadan",
    cost: "2",
    attribute: "Slash",
    power: "3000",
    counter: "1000",
    color: "Red",
    type: "Mountain Bandits",
    category: "CHARACTER",
    effect:
      "[On Play] Look at up to 5 cards from the top of your deck; reveal up to 1 red Character with a cost of 1 and add it to your hand. Then, place the rest at the bottom of your deck in any order.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "UC",
    image: "OP02-005.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-006",
    name: "Kingdew",
    cost: "5",
    attribute: "Strike",
    power: "7000",
    counter: "1000",
    color: "Red",
    type: "Whitebeard Pirates",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "C",
    image: "OP02-006.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-007",
    name: "Thatch",
    cost: "6",
    attribute: "Wisdom",
    power: "8000",
    counter: "1000",
    color: "Red",
    type: "Whitebeard Pirates",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "C",
    image: "OP02-007.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-008",
    name: "Jozu",
    cost: "4",
    attribute: "Strike",
    power: "4000",
    counter: "2000",
    color: "Red",
    type: "Whitebeard Pirates",
    category: "CHARACTER",
    effect:
      '[DON!! x1] If you have 2 or less Life cards and your Leader\'s type includes "Whitebeard Pirates", this Character gains [Rush].',
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "R",
    image: "OP02-008.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-009",
    name: "Squard",
    cost: "3",
    attribute: "Slash",
    power: "5000",
    counter: "-",
    color: "Red",
    type: "Whitebeard Pirates Allies",
    category: "CHARACTER",
    effect:
      "[On Play] If your Leader's type includes \"Whitebeard Pirates\", give up to 1 of your opponent's Characters −4000 power during this turn and add 1 card from the top of your Life cards to your hand.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "UC",
    image: "OP02-009.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-009_p1",
    name: "Squard",
    cost: "3",
    attribute: "Slash",
    power: "5000",
    counter: "-",
    color: "Red",
    type: "Whitebeard Pirates Allies",
    category: "CHARACTER",
    effect:
      "[On Play] If your Leader's type includes \"Whitebeard Pirates\", give up to 1 of your opponent's Characters −4000 power during this turn and add 1 card from the top of your Life cards to your hand.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "UC",
    image: "OP02-009_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-010",
    name: "Dogura",
    cost: "1",
    attribute: "Slash",
    power: "2000",
    counter: "1000",
    color: "Red",
    type: "Mountain Bandits",
    category: "CHARACTER",
    effect:
      "[Activate: Main] You may rest this Character: Play up to 1 red Character other than [Dogura] with a cost of 1 from your hand.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "C",
    image: "OP02-010.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-011",
    name: "Vista",
    cost: "3",
    attribute: "Slash",
    power: "3000",
    counter: "1000",
    color: "Red",
    type: "Whitebeard Pirates",
    category: "CHARACTER",
    effect:
      "[On Play] K.O. up to 1 of your opponent's Characters with 3000 power or less.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "R",
    image: "OP02-011.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-012",
    name: "Blenheim",
    cost: "2",
    attribute: "Slash",
    power: "3000",
    counter: "-",
    color: "Red",
    type: "Whitebeard Pirates",
    category: "CHARACTER",
    effect:
      "[Blocker] (After your opponent declares an attack, you may rest this card to make it the new target of the attack.)",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "C",
    image: "OP02-012.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-013",
    name: "Portgas.D.Ace",
    cost: "7",
    attribute: "Special",
    power: "7000",
    counter: "-",
    color: "Red",
    type: "Whitebeard Pirates",
    category: "CHARACTER",
    effect:
      "[On Play] Give up to 2 of your opponent's Characters −3000 power during this turn. Then, if your Leader's type includes \"Whitebeard Pirates\", this Character gains [Rush] during this turn.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "SR",
    image: "OP02-013.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-013_p1",
    name: "Portgas.D.Ace",
    cost: "7",
    attribute: "Special",
    power: "7000",
    counter: "-",
    color: "Red",
    type: "Whitebeard Pirates",
    category: "CHARACTER",
    effect:
      "[On Play] Give up to 2 of your opponent's Characters −3000 power during this turn. Then, if your Leader's type includes \"Whitebeard Pirates\", this Character gains [Rush] during this turn.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "SR",
    image: "OP02-013_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-013_p2",
    name: "Portgas.D.Ace",
    cost: "7",
    attribute: "Special",
    power: "7000",
    counter: "-",
    color: "Red",
    type: "Whitebeard Pirates",
    category: "CHARACTER",
    effect:
      "[On Play] Give up to 2 of your opponent's Characters −3000 power during this turn. Then, if your Leader's type includes \"Whitebeard Pirates\", this Character gains [Rush] during this turn.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "SR",
    image: "OP02-013_p2.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-014",
    name: "Whitey Bay",
    cost: "1",
    attribute: "Slash",
    power: "2000",
    counter: "1000",
    color: "Red",
    type: "Whitebeard Pirates Allies",
    category: "CHARACTER",
    effect:
      "[DON!! x1] This Character can also attack your opponent's active Characters.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "UC",
    image: "OP02-014.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-015",
    name: "Makino",
    cost: "1",
    attribute: "Wisdom",
    power: "-",
    counter: "2000",
    color: "Red",
    type: "Windmill Village",
    category: "CHARACTER",
    effect:
      "[Activate: Main] You may rest this Character: Up to 1 of your red Characters with a cost of 1 gains +3000 power during this turn.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "UC",
    image: "OP02-015.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-016",
    name: "Magura",
    cost: "1",
    attribute: "Slash",
    power: "2000",
    counter: "1000",
    color: "Red",
    type: "Mountain Bandits",
    category: "CHARACTER",
    effect:
      "[On Play] Up to 1 of your red Characters with a cost of 1 gains +3000 power during this turn.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "C",
    image: "OP02-016.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-017",
    name: "Masked Deuce",
    cost: "2",
    attribute: "Wisdom",
    power: "3000",
    counter: "2000",
    color: "Red",
    type: "Spade Pirates",
    category: "CHARACTER",
    effect:
      "[DON!! x2] [When Attacking] K.O. up to 1 of your opponent's Characters with 2000 power or less.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "R",
    image: "OP02-017.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-017_p1",
    name: "Masked Deuce",
    cost: "2",
    attribute: "Wisdom",
    power: "3000",
    counter: "2000",
    color: "Red",
    type: "Spade Pirates",
    category: "CHARACTER",
    effect:
      "[DON!! x2] [When Attacking] K.O. up to 1 of your opponent's Characters with 2000 power or less.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "R",
    image: "OP02-017_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-018",
    name: "Marco",
    cost: "4",
    attribute: "Special",
    power: "5000",
    counter: "1000",
    color: "Red",
    type: "Whitebeard Pirates",
    category: "CHARACTER",
    effect:
      "[Blocker] (After your opponent declares an attack, you may rest this card to make it the new target of the attack.)",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "R",
    image: "OP02-018.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-018_p1",
    name: "Marco",
    cost: "4",
    attribute: "Special",
    power: "5000",
    counter: "1000",
    color: "Red",
    type: "Whitebeard Pirates",
    category: "CHARACTER",
    effect:
      "[Blocker] (After your opponent declares an attack, you may rest this card to make it the new target of the attack.)",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "R",
    image: "OP02-018_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-019",
    name: "Rakuyo",
    cost: "3",
    attribute: "Strike",
    power: "4000",
    counter: "1000",
    color: "Red",
    type: "Whitebeard Pirates",
    category: "CHARACTER",
    effect:
      '[DON!! x1] [Your Turn] All of your Characters with a type including "Whitebeard Pirates" gain +1000 power.',
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "UC",
    image: "OP02-019.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-020",
    name: "LittleOars Jr.",
    cost: "7",
    attribute: "Strike",
    power: "9000",
    counter: "1000",
    color: "Red",
    type: "Giant/Whitebeard Pirates Allies",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "C",
    image: "OP02-020.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-021",
    name: "Seaquake",
    cost: "1",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Red",
    type: "The Four Emperors/Whitebeard Pirates",
    category: "EVENT",
    effect:
      "[Main] If your Leader's type includes \"Whitebeard Pirates\", K.O. up to 1 of your opponent's Characters with 3000 power or less.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "R",
    image: "OP02-021.png",
    life: "",
    trigger:
      "[Trigger] Give up to 1 of your opponent's Leader or Character cards −3000 power during this turn.",
  },
  {
    number: "OP02-022",
    name: "Whitebeard Pirates",
    cost: "1",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Red",
    type: "Whitebeard Pirates",
    category: "EVENT",
    effect:
      '[Main] Look at 5 cards from the top of your deck; reveal up to 1 Character card with a type including "Whitebeard Pirates" and add it to your hand. Then, place the rest at the bottom of your deck in any order.',
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "UC",
    image: "OP02-022.png",
    life: "",
    trigger: "[Trigger] Activate this card's [Main] effect.",
  },
  {
    number: "OP02-023",
    name: "You May Be a Fool...but I Still Love You",
    cost: "1",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Red",
    type: "The Four Emperors/Whitebeard Pirates",
    category: "EVENT",
    effect:
      "[Main] If you have 3 or less Life cards, you cannot add Life cards to your hand using your own effects during this turn.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "C",
    image: "OP02-023.png",
    life: "",
    trigger:
      "[Trigger] Up to 1 of your Leader gains +1000 power during this turn.",
  },
  {
    number: "OP02-024",
    name: "Moby Dick",
    cost: "2",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Red",
    type: "Whitebeard Pirates",
    category: "STAGE",
    effect:
      '[Your Turn] If you have 1 or less Life cards, your [Edward.Newgate] and all your Characters with a type including "Whitebeard Pirates" gain +2000 power.',
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "C",
    image: "OP02-024.png",
    life: "",
    trigger: "[Trigger] Play this card.",
  },
  {
    number: "OP02-025",
    name: "Kin'emon",
    cost: "",
    attribute: "Slash",
    power: "5000",
    counter: "-",
    color: "Green",
    type: "Land of Wano/The Akazaya Nine",
    category: "LEADER",
    effect:
      "[Activate: Main] [Once Per Turn] If you have 1 or less Characters, the next time you play a {Land of Wano} type Character card with a cost of 3 or more from your hand during this turn, the cost will be reduced by 1.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "L",
    image: "OP02-025.png",
    life: "5",
    trigger: "",
  },
  {
    number: "OP02-025_p1",
    name: "Kin'emon",
    cost: "",
    attribute: "Slash",
    power: "5000",
    counter: "-",
    color: "Green",
    type: "Land of Wano/The Akazaya Nine",
    category: "LEADER",
    effect:
      "[Activate: Main] [Once Per Turn] If you have 1 or less Characters, the next time you play a {Land of Wano} type Character card with a cost of 3 or more from your hand during this turn, the cost will be reduced by 1.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "L",
    image: "OP02-025_p1.png",
    life: "5",
    trigger: "",
  },
  {
    number: "OP02-026",
    name: "Sanji",
    cost: "",
    attribute: "Strike",
    power: "5000",
    counter: "-",
    color: "Green/Blue",
    type: "Straw Hat Crew",
    category: "LEADER",
    effect:
      "[Once Per Turn] When you play a Character with no base effect from your hand, if you have 3 or less Characters, set up to 2 of your DON!! cards as active.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "L",
    image: "OP02-026.png",
    life: "4",
    trigger: "",
  },
  {
    number: "OP02-026_p1",
    name: "Sanji",
    cost: "",
    attribute: "Strike",
    power: "5000",
    counter: "-",
    color: "Green/Blue",
    type: "Straw Hat Crew",
    category: "LEADER",
    effect:
      "[Once Per Turn] When you play a Character with no base effect from your hand, if you have 3 or less Characters, set up to 2 of your DON!! cards as active.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "L",
    image: "OP02-026_p1.png",
    life: "4",
    trigger: "",
  },
  {
    number: "OP02-027",
    name: "Inuarashi",
    cost: "3",
    attribute: "Slash",
    power: "4000",
    counter: "1000",
    color: "Green",
    type: "Minks/Land of Wano/The Akazaya Nine",
    category: "CHARACTER",
    effect:
      "If all of your DON!! cards are rested, this Character cannot be removed from the field by your opponent's effects.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "UC",
    image: "OP02-027.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-028",
    name: "Usopp",
    cost: "3",
    attribute: "Ranged",
    power: "5000",
    counter: "1000",
    color: "Green",
    type: "FILM/Straw Hat Crew",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "C",
    image: "OP02-028.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-029",
    name: "Carrot",
    cost: "5",
    attribute: "Special",
    power: "5000",
    counter: "2000",
    color: "Green",
    type: "Minks",
    category: "CHARACTER",
    effect: "[End of Your Turn] Set up to 1 of your DON!! cards as active.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "R",
    image: "OP02-029.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-030",
    name: "Kouzuki Oden",
    cost: "8",
    attribute: "Slash",
    power: "8000",
    counter: "-",
    color: "Green",
    type: "Land of Wano/Kouzuki Clan",
    category: "CHARACTER",
    effect:
      "[Activate: Main] [Once Per Turn] ③ (You may rest the specified number of DON!! cards in your cost area.): Set this Character as active.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "SR",
    image: "OP02-030.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-030_p1",
    name: "Kouzuki Oden",
    cost: "8",
    attribute: "Slash",
    power: "8000",
    counter: "-",
    color: "Green",
    type: "Land of Wano/Kouzuki Clan",
    category: "CHARACTER",
    effect:
      "[Activate: Main] [Once Per Turn] ③ (You may rest the specified number of DON!! cards in your cost area.): Set this Character as active.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "SR",
    image: "OP02-030_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-031",
    name: "Kouzuki Toki",
    cost: "1",
    attribute: "Wisdom",
    power: "-",
    counter: "1000",
    color: "Green",
    type: "Land of Wano/Kouzuki Clan",
    category: "CHARACTER",
    effect:
      "If you have a [Kouzuki Oden] Character, this Character gains [Blocker].",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "UC",
    image: "OP02-031.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-031_p1",
    name: "Kouzuki Toki",
    cost: "1",
    attribute: "Wisdom",
    power: "-",
    counter: "1000",
    color: "Green",
    type: "Land of Wano/Kouzuki Clan",
    category: "CHARACTER",
    effect:
      "If you have a [Kouzuki Oden] Character, this Character gains [Blocker].",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "UC",
    image: "OP02-031_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-032",
    name: "Shishilian",
    cost: "2",
    attribute: "Slash",
    power: "3000",
    counter: "1000",
    color: "Green",
    type: "Minks",
    category: "CHARACTER",
    effect:
      "[On Play] ② (You may rest the specified number of DON!! cards in your cost area.): Set up to 1 of your {Minks} type Characters with a cost of 5 or less as active.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "UC",
    image: "OP02-032.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-033",
    name: "Jinbe",
    cost: "2",
    attribute: "Strike",
    power: "4000",
    counter: "1000",
    color: "Green",
    type: "FILM/Fish-Man/Straw Hat Crew",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "C",
    image: "OP02-033.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-034",
    name: "Tony Tony.Chopper",
    cost: "2",
    attribute: "Wisdom",
    power: "2000",
    counter: "2000",
    color: "Green",
    type: "FILM/Animal/Straw Hat Crew",
    category: "CHARACTER",
    effect:
      "[DON!! x1] [When Attacking] Rest up to 1 of your opponent's Characters with a cost of 2 or less.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "UC",
    image: "OP02-034.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-035",
    name: "Trafalgar Law",
    cost: "2",
    attribute: "Slash",
    power: "3000",
    counter: "1000",
    color: "Green",
    type: "FILM/Supernovas/Heart Pirates",
    category: "CHARACTER",
    effect:
      "[Activate: Main] ➀ (You may rest the specified number of DON!! cards in your cost area.) You may return this Character to the owner's hand: Play up to 1 Character with a cost of 3 from your hand.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "C",
    image: "OP02-035.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-035_p1",
    name: "Trafalgar Law",
    cost: "2",
    attribute: "Slash",
    power: "3000",
    counter: "1000",
    color: "Green",
    type: "FILM/Supernovas/Heart Pirates",
    category: "CHARACTER",
    effect:
      "[Activate: Main] ➀ (You may rest the specified number of DON!! cards in your cost area.) You may return this Character to the owner's hand: Play up to 1 Character with a cost of 3 from your hand.",
    cardSet: "Included in Online Regional Participation Pack Vol.1",
    rarity: "C",
    image: "OP02-035_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-036",
    name: "Nami",
    cost: "3",
    attribute: "Special",
    power: "5000",
    counter: "-",
    color: "Green",
    type: "FILM/Straw Hat Crew",
    category: "CHARACTER",
    effect:
      "[On Play]/[When Attacking] ➀ (You may rest the specified number of DON!! cards in your cost area.): Look at 3 cards from the top of your deck; reveal up to 1 {FILM} type card other than [Nami] and add it to your hand. Then, place the rest at the bottom of your deck in any order.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "SR",
    image: "OP02-036.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-036_p1",
    name: "Nami",
    cost: "3",
    attribute: "Special",
    power: "5000",
    counter: "-",
    color: "Green",
    type: "FILM/Straw Hat Crew",
    category: "CHARACTER",
    effect:
      "[On Play]/[When Attacking] ➀ (You may rest the specified number of DON!! cards in your cost area.): Look at 3 cards from the top of your deck; reveal up to 1 {FILM} type card other than [Nami] and add it to your hand. Then, place the rest at the bottom of your deck in any order.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "SR",
    image: "OP02-036_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-037",
    name: "Nico Robin",
    cost: "3",
    attribute: "Strike",
    power: "4000",
    counter: "1000",
    color: "Green",
    type: "FILM/Straw Hat Crew",
    category: "CHARACTER",
    effect:
      "[On Play] Play up to 1 {FILM} or {Straw Hat Crew} type Character card with a cost of 2 or less from your hand.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "UC",
    image: "OP02-037.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-038",
    name: "Nekomamushi",
    cost: "3",
    attribute: "Slash",
    power: "4000",
    counter: "-",
    color: "Green",
    type: "Minks/Land of Wano/The Akazaya Nine",
    category: "CHARACTER",
    effect:
      "[Blocker] (After your opponent declares an attack, you may rest this card to make it the new target of the attack.)",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "C",
    image: "OP02-038.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-039",
    name: "Franky",
    cost: "5",
    attribute: "Special",
    power: "7000",
    counter: "1000",
    color: "Green",
    type: "FILM/Straw Hat Crew",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "C",
    image: "OP02-039.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-040",
    name: "Brook",
    cost: "4",
    attribute: "Slash",
    power: "5000",
    counter: "1000",
    color: "Green",
    type: "FILM/Straw Hat Crew",
    category: "CHARACTER",
    effect:
      "[On Play] Play up to 1 {FILM} or {Straw Hat Crew} type Character card with a cost of 3 or less from your hand.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "R",
    image: "OP02-040.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-041",
    name: "Monkey.D.Luffy",
    cost: "7",
    attribute: "Strike",
    power: "7000",
    counter: "-",
    color: "Green",
    type: "FILM/Supernovas/Straw Hat Crew",
    category: "CHARACTER",
    effect:
      "[Blocker] (After your opponent declares an attack, you may rest this card to make it the new target of the attack.)",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "R",
    image: "OP02-041.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-041_p1",
    name: "Monkey.D.Luffy",
    cost: "7",
    attribute: "Strike",
    power: "7000",
    counter: "-",
    color: "Green",
    type: "FILM/Supernovas/Straw Hat Crew",
    category: "CHARACTER",
    effect:
      "[Blocker] (After your opponent declares an attack, you may rest this card to make it the new target of the attack.)",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "R",
    image: "OP02-041_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-042",
    name: "Yamato",
    cost: "4",
    attribute: "Strike",
    power: "6000",
    counter: "-",
    color: "Green",
    type: "Land of Wano",
    category: "CHARACTER",
    effect:
      "Also treat this card's name as [Kouzuki Oden] according to the rules.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "R",
    image: "OP02-042.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-043",
    name: "Roronoa Zoro",
    cost: "4",
    attribute: "Slash",
    power: "6000",
    counter: "1000",
    color: "Green",
    type: "FILM/Supernovas/Straw Hat Crew",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "C",
    image: "OP02-043.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-044",
    name: "Wanda",
    cost: "2",
    attribute: "Slash",
    power: "3000",
    counter: "1000",
    color: "Green",
    type: "Minks",
    category: "CHARACTER",
    effect:
      "[On Play] Play up to 1 {Minks} type Character card other than [Wanda] with a cost of 3 or less from your hand.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "C",
    image: "OP02-044.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-045",
    name: "Three Sword Style Oni Giri",
    cost: "3",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Green",
    type: "FILM/Supernovas/Straw Hat Crew",
    category: "EVENT",
    effect:
      "[Counter] Up to 1 of your Leader or Character cards gains +6000 power during this battle. Then, play up to 1 Character card with a cost of 3 or less and no base effect from your hand.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "C",
    image: "OP02-045.png",
    life: "",
    trigger:
      "[Trigger] Rest up to 1 of your opponent's Leader or Character cards with a cost of 5 or less.",
  },
  {
    number: "OP02-046",
    name: "Diable Jambe Venaison Shoot",
    cost: "2",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Green",
    type: "FILM/Straw Hat Crew",
    category: "EVENT",
    effect:
      "[Main] K.O. up to 1 of your opponent's rested Characters with a cost of 4 or less.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "UC",
    image: "OP02-046.png",
    life: "",
    trigger:
      "[Trigger] Play up to 1 Character card with a cost of 4 or less and no base effect from your hand.",
  },
  {
    number: "OP02-047",
    name: "Paradise Totsuka",
    cost: "1",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Green",
    type: "Land of Wano/Kouzuki Clan",
    category: "EVENT",
    effect:
      "[Main] Rest up to 1 of your opponent's Characters with a cost of 4 or less.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "R",
    image: "OP02-047.png",
    life: "",
    trigger:
      "[Trigger] K.O. up to 1 of your opponent's rested Characters with a cost of 3 or less.",
  },
  {
    number: "OP02-048",
    name: "Land of Wano",
    cost: "1",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Green",
    type: "Land of Wano",
    category: "STAGE",
    effect:
      "[Activate: Main] You may trash 1 {Land of Wano} type card from your hand and rest this Stage: Set up to 1 of your DON!! cards as active.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "C",
    image: "OP02-048.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-049",
    name: "Emporio.Ivankov",
    cost: "",
    attribute: "Special",
    power: "5000",
    counter: "-",
    color: "Blue",
    type: "Impel Down/Revolutionary Army",
    category: "LEADER",
    effect:
      "[End of Your Turn] If you have 0 cards in your hand, draw 2 cards.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "L",
    image: "OP02-049.png",
    life: "5",
    trigger: "",
  },
  {
    number: "OP02-049_p1",
    name: "Emporio.Ivankov",
    cost: "",
    attribute: "Special",
    power: "5000",
    counter: "-",
    color: "Blue",
    type: "Impel Down/Revolutionary Army",
    category: "LEADER",
    effect:
      "[End of Your Turn] If you have 0 cards in your hand, draw 2 cards.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "L",
    image: "OP02-049_p1.png",
    life: "5",
    trigger: "",
  },
  {
    number: "OP02-050",
    name: "Inazuma",
    cost: "4",
    attribute: "Slash",
    power: "5000",
    counter: "1000",
    color: "Blue",
    type: "Impel Down/Revolutionary Army",
    category: "CHARACTER",
    effect:
      "If you have 1 or less cards in your hand, this Character gains +2000 power.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "R",
    image: "OP02-050.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-051",
    name: "Emporio.Ivankov",
    cost: "7",
    attribute: "Special",
    power: "7000",
    counter: "-",
    color: "Blue",
    type: "Impel Down/Revolutionary Army",
    category: "CHARACTER",
    effect:
      "[On Play] Draw card(s) so that you have 3 cards in your hand and then play up to 1 blue {Impel Down} type Character card with a cost of 6 or less from your hand.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "SR",
    image: "OP02-051.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-051_p1",
    name: "Emporio.Ivankov",
    cost: "7",
    attribute: "Special",
    power: "7000",
    counter: "-",
    color: "Blue",
    type: "Impel Down/Revolutionary Army",
    category: "CHARACTER",
    effect:
      "[On Play] Draw card(s) so that you have 3 cards in your hand and then play up to 1 blue {Impel Down} type Character card with a cost of 6 or less from your hand.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "SR",
    image: "OP02-051_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-052",
    name: "Cabaji",
    cost: "2",
    attribute: "Slash",
    power: "3000",
    counter: "1000",
    color: "Blue",
    type: "Buggy Pirates",
    category: "CHARACTER",
    effect:
      "[On Play] If you have [Mohji], draw 2 cards and trash 1 card from your hand.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "C",
    image: "OP02-052.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-053",
    name: "Crocodile",
    cost: "5",
    attribute: "Special",
    power: "7000",
    counter: "1000",
    color: "Blue",
    type: "Impel Down/Former Baroque Works",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "C",
    image: "OP02-053.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-054",
    name: "Gecko Moria",
    cost: "4",
    attribute: "Special",
    power: "6000",
    counter: "1000",
    color: "Blue",
    type: "The Seven Warlords of the Sea/Thriller Bark Pirates",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "C",
    image: "OP02-054.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-055",
    name: "Dracule Mihawk",
    cost: "6",
    attribute: "Slash",
    power: "8000",
    counter: "1000",
    color: "Blue",
    type: "The Seven Warlords of the Sea",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "C",
    image: "OP02-055.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-056",
    name: "Donquixote Doflamingo",
    cost: "1",
    attribute: "Special",
    power: "2000",
    counter: "-",
    color: "Blue",
    type: "The Seven Warlords of the Sea/Donquixote Pirates",
    category: "CHARACTER",
    effect:
      "[On Play] Look at 3 cards from the top of your deck and place them at the top or bottom of the deck in any order.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "UC",
    image: "OP02-056.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-057",
    name: "Bartholomew Kuma",
    cost: "3",
    attribute: "Strike",
    power: "3000",
    counter: "1000",
    color: "Blue",
    type: "The Seven Warlords of the Sea/Revolutionary Army",
    category: "CHARACTER",
    effect:
      "[On Play] Look at 2 cards from the top of your deck; reveal up to 1 {The Seven Warlords of the Sea} type card and add it to your hand. Then, place the rest at the top or bottom of the deck in any order.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "UC",
    image: "OP02-057.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-058",
    name: "Buggy",
    cost: "1",
    attribute: "Slash",
    power: "2000",
    counter: "1000",
    color: "Blue",
    type: "Impel Down/Buggy Pirates",
    category: "CHARACTER",
    effect:
      "[On Play] Look at 5 cards from the top of your deck; reveal up to 1 blue {Impel Down} type card other than [Buggy] and add it to your hand. Then, place the rest at the bottom of your deck in any order.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "R",
    image: "OP02-058.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-058_p1",
    name: "Buggy",
    cost: "1",
    attribute: "Slash",
    power: "2000",
    counter: "1000",
    color: "Blue",
    type: "Impel Down/Buggy Pirates",
    category: "CHARACTER",
    effect:
      "[On Play] Look at 5 cards from the top of your deck; reveal up to 1 blue {Impel Down} type card other than [Buggy] and add it to your hand. Then, place the rest at the bottom of your deck in any order.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "R",
    image: "OP02-058_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-059",
    name: "Boa Hancock",
    cost: "4",
    attribute: "Special",
    power: "5000",
    counter: "1000",
    color: "Blue",
    type: "Impel Down/The Seven Warlords of the Sea/Kuja Pirates",
    category: "CHARACTER",
    effect:
      "[When Attacking] Draw 1 card and trash 1 card from your hand. Then, trash up to 3 cards from your hand.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "UC",
    image: "OP02-059.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-059_p1",
    name: "Boa Hancock",
    cost: "4",
    attribute: "Special",
    power: "5000",
    counter: "1000",
    color: "Blue",
    type: "Impel Down/The Seven Warlords of the Sea/Kuja Pirates",
    category: "CHARACTER",
    effect:
      "[When Attacking] Draw 1 card and trash 1 card from your hand. Then, trash up to 3 cards from your hand.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "UC",
    image: "OP02-059_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-060",
    name: "Mohji",
    cost: "1",
    attribute: "Wisdom",
    power: "3000",
    counter: "1000",
    color: "Blue",
    type: "Buggy Pirates",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "C",
    image: "OP02-060.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-061",
    name: "Morley",
    cost: "4",
    attribute: "Special",
    power: "5000",
    counter: "1000",
    color: "Blue",
    type: "Giant/Revolutionary Army",
    category: "CHARACTER",
    effect:
      "[When Attacking] If you have 1 or less cards in your hand, your opponent cannot activate the [Blocker] of any Character with a cost of 5 or less during this battle.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "UC",
    image: "OP02-061.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-062",
    name: "Monkey.D.Luffy",
    cost: "6",
    attribute: "Strike",
    power: "7000",
    counter: "-",
    color: "Blue",
    type: "Impel Down/Straw Hat Crew",
    category: "CHARACTER",
    effect:
      "[On Play]/[When Attacking] You may trash 2 cards from your hand: Return up to 1 Character with a cost of 4 or less to the owner's hand. Then, this Character gains [Double Attack] during this turn.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "SR",
    image: "OP02-062.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-062_p1",
    name: "Monkey.D.Luffy",
    cost: "6",
    attribute: "Strike",
    power: "7000",
    counter: "-",
    color: "Blue",
    type: "Impel Down/Straw Hat Crew",
    category: "CHARACTER",
    effect:
      "[On Play]/[When Attacking] You may trash 2 cards from your hand: Return up to 1 Character with a cost of 4 or less to the owner's hand. Then, this Character gains [Double Attack] during this turn.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "SR",
    image: "OP02-062_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-063",
    name: "Mr.1(Daz.Bonez)",
    cost: "1",
    attribute: "Slash",
    power: "1000",
    counter: "1000",
    color: "Blue",
    type: "Impel Down/Former Baroque Works",
    category: "CHARACTER",
    effect:
      "[On Play] Add up to 1 blue Event card with a cost of 1 from your trash to your hand.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "UC",
    image: "OP02-063.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-064",
    name: "Mr.2.Bon.Kurei(Bentham)",
    cost: "5",
    attribute: "Strike",
    power: "5000",
    counter: "2000",
    color: "Blue",
    type: "Impel Down/Former Baroque Works",
    category: "CHARACTER",
    effect:
      "[DON!! x1] [When Attacking] You may trash 1 card from your hand: Place up to 1 Character with a cost of 2 or less at the bottom of the owner's deck. Then, at the end of this battle, place this Character at the bottom of the owner's deck.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "R",
    image: "OP02-064.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-065",
    name: "Mr.3(Galdino)",
    cost: "4",
    attribute: "Special",
    power: "5000",
    counter: "1000",
    color: "Blue",
    type: "Impel Down/Former Baroque Works",
    category: "CHARACTER",
    effect:
      "[Blocker] (After your opponent declares an attack, you may rest this card to make it the new target of the attack.)",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "R",
    image: "OP02-065.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-066",
    name: "Impel Down All Stars",
    cost: "1",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Blue",
    type: "Impel Down",
    category: "EVENT",
    effect:
      "[Main] You may trash 2 cards from your hand: If your Leader has the {Impel Down} type, draw up to 2 cards.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "C",
    image: "OP02-066.png",
    life: "",
    trigger: "[Trigger] Draw 2 cards.",
  },
  {
    number: "OP02-067",
    name: "Arabesque Brick Fist",
    cost: "2",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Blue",
    type: "Fish-Man/Impel Down",
    category: "EVENT",
    effect:
      "[Main] Return up to 1 Character with a cost of 4 or less to the owner's hand.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "UC",
    image: "OP02-067.png",
    life: "",
    trigger: "[Trigger] Activate this card's [Main] effect.",
  },
  {
    number: "OP02-068",
    name: "Gum-Gum Rain",
    cost: "-",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Blue",
    type: "Impel Down/Straw Hat Crew",
    category: "EVENT",
    effect:
      "[Counter] You may trash 1 card from your hand: Up to 1 of your Leader or Character cards gains +3000 power during this battle.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "R",
    image: "OP02-068.png",
    life: "",
    trigger:
      "[Trigger] Return up to 1 Character with a cost of 2 or less to the owner's hand.",
  },
  {
    number: "OP02-069",
    name: "DEATH WINK",
    cost: "3",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Blue",
    type: "Impel Down/Revolutionary Army",
    category: "EVENT",
    effect:
      "[Counter] Up to 1 of your Leader or Character cards gains +6000 power during this battle. Then, draw cards so that you have 2 cards in your hand.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "C",
    image: "OP02-069.png",
    life: "",
    trigger:
      "[Trigger] Return up to 1 Character with a cost of 7 or less to the owner's hand.",
  },
  {
    number: "OP02-070",
    name: "New Kama Land",
    cost: "1",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Blue",
    type: "Impel Down",
    category: "STAGE",
    effect:
      "[Activate: Main] You may rest this Stage: If your Leader is [Emporio.Ivankov], draw 1 card and trash 1 card from your hand. Then, trash up to 3 cards from your hand.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "C",
    image: "OP02-070.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-071",
    name: "Magellan",
    cost: "",
    attribute: "Special",
    power: "5000",
    counter: "-",
    color: "Purple",
    type: "Impel Down",
    category: "LEADER",
    effect:
      "[Your Turn] [Once Per Turn] When a DON!! card on the field is returned to your DON!! deck, this Leader gains +1000 power during this turn.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "L",
    image: "OP02-071.png",
    life: "5",
    trigger: "",
  },
  {
    number: "OP02-071_p1",
    name: "Magellan",
    cost: "",
    attribute: "Special",
    power: "5000",
    counter: "-",
    color: "Purple",
    type: "Impel Down",
    category: "LEADER",
    effect:
      "[Your Turn] [Once Per Turn] When a DON!! card on the field is returned to your DON!! deck, this Leader gains +1000 power during this turn.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "L",
    image: "OP02-071_p1.png",
    life: "5",
    trigger: "",
  },
  {
    number: "OP02-072",
    name: "Zephyr",
    cost: "",
    attribute: "Strike",
    power: "5000",
    counter: "-",
    color: "Purple/Black",
    type: "FILM/Neo Navy",
    category: "LEADER",
    effect:
      "[When Attacking] DON!! −4 (You may return the specified number of DON!! cards from your field to your DON!! deck.): K.O. up to 1 of your opponent's Characters with a cost of 3 or less. Then, this Leader gains +1000 power during this turn.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "L",
    image: "OP02-072.png",
    life: "4",
    trigger: "",
  },
  {
    number: "OP02-072_p1",
    name: "Zephyr",
    cost: "",
    attribute: "Strike",
    power: "5000",
    counter: "-",
    color: "Purple/Black",
    type: "FILM/Neo Navy",
    category: "LEADER",
    effect:
      "[When Attacking] DON!! −4 (You may return the specified number of DON!! cards from your field to your DON!! deck.): K.O. up to 1 of your opponent's Characters with a cost of 3 or less. Then, this Leader gains +1000 power during this turn.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "L",
    image: "OP02-072_p1.png",
    life: "4",
    trigger: "",
  },
  {
    number: "OP02-073",
    name: "Little Sadi",
    cost: "3",
    attribute: "Wisdom",
    power: "3000",
    counter: "2000",
    color: "Purple",
    type: "Impel Down",
    category: "CHARACTER",
    effect:
      "[On Play] Play up to 1 {Jailer Beast} type Character card from your hand.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "R",
    image: "OP02-073.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-073_p1",
    name: "Little Sadi",
    cost: "3",
    attribute: "Wisdom",
    power: "3000",
    counter: "2000",
    color: "Purple",
    type: "Impel Down",
    category: "CHARACTER",
    effect:
      "[On Play] Play up to 1 {Jailer Beast} type Character card from your hand.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "R",
    image: "OP02-073_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-074",
    name: "Saldeath",
    cost: "1",
    attribute: "Wisdom",
    power: "2000",
    counter: "1000",
    color: "Purple",
    type: "Impel Down",
    category: "CHARACTER",
    effect: "Your [Blugori] gains [Blocker].",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "C",
    image: "OP02-074.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-075",
    name: "Shiki",
    cost: "2",
    attribute: "Slash",
    power: "3000",
    counter: "2000",
    color: "Purple",
    type: "FILM/Golden Lion Pirates",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "R",
    image: "OP02-075.png",
    life: "",
    trigger:
      "[Trigger] DON!! −1 (You may return the specified number of DON!! cards from your field to your DON!! deck.): Play this card.",
  },
  {
    number: "OP02-076",
    name: "Shiryu",
    cost: "4",
    attribute: "Slash",
    power: "5000",
    counter: "1000",
    color: "Purple",
    type: "Impel Down",
    category: "CHARACTER",
    effect:
      "[On Play] DON!! −1 (You may return the specified number of DON!! cards from your field to your DON!! deck.): K.O. up to 1 of your opponent's Characters with a cost of 1 or less.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "R",
    image: "OP02-076.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-077",
    name: "Solitaire",
    cost: "3",
    attribute: "Slash",
    power: "5000",
    counter: "1000",
    color: "Purple",
    type: "Animal Kingdom Pirates/SMILE",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "C",
    image: "OP02-077.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-078",
    name: "Daifugo",
    cost: "3",
    attribute: "Ranged",
    power: "5000",
    counter: "-",
    color: "Purple",
    type: "Animal Kingdom Pirates/SMILE",
    category: "CHARACTER",
    effect:
      "[On Play] DON!! −2 (You may return the specified number of DON!! cards from your field to your DON!! deck.): Play up to 1 {SMILE} type Character card other than [Daifugo] with a cost of 3 or less from your hand.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "UC",
    image: "OP02-078.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-079",
    name: "Douglas Bullet",
    cost: "5",
    attribute: "Special",
    power: "6000",
    counter: "1000",
    color: "Purple",
    type: "FILM/The Pirates Fest",
    category: "CHARACTER",
    effect:
      "[On Play] DON!! −1 (You may return the specified number of DON!! cards from your field to your DON!! deck.): Rest up to 1 of your opponent's Characters with a cost of 4 or less.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "UC",
    image: "OP02-079.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-080",
    name: "Dobon",
    cost: "2",
    attribute: "Strike",
    power: "4000",
    counter: "1000",
    color: "Purple",
    type: "Animal Kingdom Pirates/SMILE",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "C",
    image: "OP02-080.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-081",
    name: "Domino",
    cost: "2",
    attribute: "Wisdom",
    power: "2000",
    counter: "1000",
    color: "Purple",
    type: "Impel Down",
    category: "CHARACTER",
    effect:
      "[Blocker] (After your opponent declares an attack, you may rest this card to make it the new target of the attack.)",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "C",
    image: "OP02-081.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-082",
    name: "Byrnndi World",
    cost: "8",
    attribute: "Strike",
    power: "8000",
    counter: "-",
    color: "Purple",
    type: "World Pirates",
    category: "CHARACTER",
    effect:
      "[Activate: Main] DON!! −8 (You may return the specified number of DON!! cards from your field to your DON!! deck.): This Character gains +792000 power during this turn.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "UC",
    image: "OP02-082.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-083",
    name: "Hannyabal",
    cost: "1",
    attribute: "Slash",
    power: "2000",
    counter: "1000",
    color: "Purple",
    type: "Impel Down",
    category: "CHARACTER",
    effect:
      "[On Play] Look at 5 cards from the top of your deck; reveal up to 1 purple {Impel Down} type card other than [Hannyabal] and add it to your hand. Then, place the rest at the bottom of your deck in any order.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "R",
    image: "OP02-083.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-084",
    name: "Blugori",
    cost: "1",
    attribute: "Slash",
    power: "3000",
    counter: "1000",
    color: "Purple",
    type: "Animal/Impel Down",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "C",
    image: "OP02-084.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-085",
    name: "Magellan",
    cost: "5",
    attribute: "Special",
    power: "6000",
    counter: "-",
    color: "Purple",
    type: "Impel Down",
    category: "CHARACTER",
    effect:
      "[On Play] DON!! −1 (You may return the specified number of DON!! cards from your field to your DON!! deck.): Your opponent returns 1 DON!! card from their field to their DON!! deck.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "SR",
    image: "OP02-085.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-085_p1",
    name: "Magellan",
    cost: "5",
    attribute: "Special",
    power: "6000",
    counter: "-",
    color: "Purple",
    type: "Impel Down",
    category: "CHARACTER",
    effect:
      "[On Play] DON!! −1 (You may return the specified number of DON!! cards from your field to your DON!! deck.): Your opponent returns 1 DON!! card from their field to their DON!! deck.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "SR",
    image: "OP02-085_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-086",
    name: "Minokoala",
    cost: "4",
    attribute: "Strike",
    power: "5000",
    counter: "1000",
    color: "Purple",
    type: "Impel Down/Jailer Beast",
    category: "CHARACTER",
    effect:
      "[Blocker] (After your opponent declares an attack, you may rest this card to make it the new target of the attack.)",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "UC",
    image: "OP02-086.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-086_p1",
    name: "Minokoala",
    cost: "4",
    attribute: "Strike",
    power: "5000",
    counter: "1000",
    color: "Purple",
    type: "Impel Down/Jailer Beast",
    category: "CHARACTER",
    effect:
      "[Blocker] (After your opponent declares an attack, you may rest this card to make it the new target of the attack.)",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "UC",
    image: "OP02-086_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-087",
    name: "Minotaur",
    cost: "4",
    attribute: "Strike",
    power: "5000",
    counter: "1000",
    color: "Purple",
    type: "Impel Down/Jailer Beast",
    category: "CHARACTER",
    effect: "[Double Attack] (This card deals 2 damage.)",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "UC",
    image: "OP02-087.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-088",
    name: "Sphinx",
    cost: "4",
    attribute: "Strike",
    power: "6000",
    counter: "1000",
    color: "Purple",
    type: "Animal/Impel Down",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "C",
    image: "OP02-088.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-089",
    name: "Judgment of Hell",
    cost: "2",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Purple",
    type: "Impel Down",
    category: "EVENT",
    effect:
      "[Counter] DON!! −1 (You may return the specified number of DON!! cards from your field to your DON!! deck.): Give up to a total of 2 of your opponent's Leader or Character cards −3000 power during this turn.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "R",
    image: "OP02-089.png",
    life: "",
    trigger:
      "[Trigger] If your opponent has 6 or more DON!! cards on their field, your opponent returns 1 DON!! card from their field to their DON!! deck.",
  },
  {
    number: "OP02-090",
    name: "Hydra",
    cost: "1",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Purple",
    type: "Impel Down",
    category: "EVENT",
    effect:
      "[Main] DON!! −1 (You may return the specified number of DON!! cards from your field to your DON!! deck.): Give up to 1 of your opponent's Characters −3000 power during this turn.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "UC",
    image: "OP02-090.png",
    life: "",
    trigger:
      "[Trigger] If your opponent has 6 or more DON!! cards on their field, your opponent returns 1 DON!! card from their field to their DON!! deck.",
  },
  {
    number: "OP02-091",
    name: "Venom Road",
    cost: "3",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Purple",
    type: "Impel Down",
    category: "EVENT",
    effect:
      "[Main] Add up to 1 DON!! card from your DON!! deck and set it as active.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "C",
    image: "OP02-091.png",
    life: "",
    trigger:
      "[Trigger] If your opponent has 6 or more DON!! cards on their field, your opponent returns 1 DON!! card from their field to their DON!! deck.",
  },
  {
    number: "OP02-092",
    name: "Impel Down",
    cost: "1",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Purple",
    type: "Impel Down",
    category: "STAGE",
    effect:
      "[Activate: Main] You may trash 1 card from your hand and rest this Stage: Look at 3 cards from the top of your deck; reveal up to 1 {Impel Down} type card and add it to your hand. Then, place the rest at the bottom of your deck in any order.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "C",
    image: "OP02-092.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-093",
    name: "Smoker",
    cost: "",
    attribute: "Special",
    power: "5000",
    counter: "-",
    color: "Black",
    type: "Navy",
    category: "LEADER",
    effect:
      "[DON!! x1] [Activate: Main] [Once Per Turn] Give up to 1 of your opponent's Characters −1 cost during this turn. Then, if there is a Character with a cost of 0, this Leader gains +1000 power during this turn.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "L",
    image: "OP02-093.png",
    life: "5",
    trigger: "",
  },
  {
    number: "OP02-093_p1",
    name: "Smoker",
    cost: "",
    attribute: "Special",
    power: "5000",
    counter: "-",
    color: "Black",
    type: "Navy",
    category: "LEADER",
    effect:
      "[DON!! x1] [Activate: Main] [Once Per Turn] Give up to 1 of your opponent's Characters −1 cost during this turn. Then, if there is a Character with a cost of 0, this Leader gains +1000 power during this turn.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "L",
    image: "OP02-093_p1.png",
    life: "5",
    trigger: "",
  },
  {
    number: "OP02-094",
    name: "Isuka",
    cost: "3",
    attribute: "Slash",
    power: "4000",
    counter: "1000",
    color: "Black",
    type: "Navy",
    category: "CHARACTER",
    effect:
      "[DON!! x1] [Once Per Turn] When this Character battles and K.O.'s your opponent's Character, set this Character as active.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "UC",
    image: "OP02-094.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-095",
    name: "Onigumo",
    cost: "5",
    attribute: "Slash",
    power: "6000",
    counter: "1000",
    color: "Black",
    type: "Navy",
    category: "CHARACTER",
    effect:
      "If there is a Character with a cost of 0, this Character gains [Banish].",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "UC",
    image: "OP02-095.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-096",
    name: "Kuzan",
    cost: "4",
    attribute: "Special",
    power: "5000",
    counter: "-",
    color: "Black",
    type: "Navy",
    category: "CHARACTER",
    effect: "[On Play] Draw 1 card.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "SR",
    image: "OP02-096.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-096_p1",
    name: "Kuzan",
    cost: "4",
    attribute: "Special",
    power: "5000",
    counter: "-",
    color: "Black",
    type: "Navy",
    category: "CHARACTER",
    effect: "[On Play] Draw 1 card.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "SR",
    image: "OP02-096_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-097",
    name: "Komille",
    cost: "1",
    attribute: "Slash",
    power: "3000",
    counter: "1000",
    color: "Black",
    type: "Navy",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "C",
    image: "OP02-097.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-098",
    name: "Koby",
    cost: "3",
    attribute: "Strike",
    power: "4000",
    counter: "1000",
    color: "Black",
    type: "Navy",
    category: "CHARACTER",
    effect:
      "[On Play] You may trash 1 card from your hand: K.O. up to 1 of your opponent's Characters with a cost of 3 or less.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "R",
    image: "OP02-098.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-098_p1",
    name: "Koby",
    cost: "3",
    attribute: "Strike",
    power: "4000",
    counter: "1000",
    color: "Black",
    type: "Navy",
    category: "CHARACTER",
    effect:
      "[On Play] You may trash 1 card from your hand: K.O. up to 1 of your opponent's Characters with a cost of 3 or less.",
    cardSet: "Included in Online Regional Participation Pack Vol.1",
    rarity: "R",
    image: "OP02-098_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-099",
    name: "Sakazuki",
    cost: "6",
    attribute: "Special",
    power: "7000",
    counter: "-",
    color: "Black",
    type: "Navy",
    category: "CHARACTER",
    effect:
      "[On Play] You may trash 1 card from your hand: K.O. up to 1 of your opponent's Characters with a cost of 5 or less.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "SR",
    image: "OP02-099.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-099_p1",
    name: "Sakazuki",
    cost: "6",
    attribute: "Special",
    power: "7000",
    counter: "-",
    color: "Black",
    type: "Navy",
    category: "CHARACTER",
    effect:
      "[On Play] You may trash 1 card from your hand: K.O. up to 1 of your opponent's Characters with a cost of 5 or less.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "SR",
    image: "OP02-099_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-100",
    name: "Jango",
    cost: "2",
    attribute: "Slash",
    power: "3000",
    counter: "1000",
    color: "Black",
    type: "Navy",
    category: "CHARACTER",
    effect:
      "If you have [Fullbody], this Character cannot be K.O.'d in battle.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "C",
    image: "OP02-100.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-101",
    name: "Strawberry",
    cost: "4",
    attribute: "Slash",
    power: "5000",
    counter: "-",
    color: "Black",
    type: "Navy",
    category: "CHARACTER",
    effect:
      "[When Attacking] If there is a Character with a cost of 0, your opponent cannot activate the [Blocker] of any Character with a cost of 5 or less during this battle.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "C",
    image: "OP02-101.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-102",
    name: "Smoker",
    cost: "3",
    attribute: "Special",
    power: "4000",
    counter: "1000",
    color: "Black",
    type: "Navy",
    category: "CHARACTER",
    effect: "This Character cannot be K.O.'d by effects.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "R",
    image: "OP02-102.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-103",
    name: "Sengoku",
    cost: "2",
    attribute: "Wisdom",
    power: "3000",
    counter: "2000",
    color: "Black",
    type: "Navy",
    category: "CHARACTER",
    effect:
      "[DON!! x1] [When Attacking] Give up to 1 of your opponent's Characters −2 cost during this turn.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "R",
    image: "OP02-103.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-104",
    name: "Sentomaru",
    cost: "2",
    attribute: "Slash",
    power: "3000",
    counter: "1000",
    color: "Black",
    type: "Navy",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "C",
    image: "OP02-104.png",
    life: "",
    trigger: "[Trigger] Play this card.",
  },
  {
    number: "OP02-105",
    name: "Tashigi",
    cost: "3",
    attribute: "Slash",
    power: "5000",
    counter: "-",
    color: "Black",
    type: "Navy",
    category: "CHARACTER",
    effect:
      "[DON!! x1] [When Attacking] Give up to 1 of your opponent's Characters −3 cost during this turn.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "C",
    image: "OP02-105.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-105_p1",
    name: "Tashigi",
    cost: "3",
    attribute: "Slash",
    power: "5000",
    counter: "-",
    color: "Black",
    type: "Navy",
    category: "CHARACTER",
    effect:
      "[DON!! x1] [When Attacking] Give up to 1 of your opponent's Characters −3 cost during this turn.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "C",
    image: "OP02-105_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-106",
    name: "Tsuru",
    cost: "1",
    attribute: "Wisdom",
    power: "-",
    counter: "2000",
    color: "Black",
    type: "Navy",
    category: "CHARACTER",
    effect:
      "[On Play] Give up to 1 of your opponent's Characters −2 cost during this turn.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "UC",
    image: "OP02-106.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-107",
    name: "Doberman",
    cost: "2",
    attribute: "Strike",
    power: "4000",
    counter: "1000",
    color: "Black",
    type: "Navy",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "C",
    image: "OP02-107.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-108",
    name: "Donquixote Rosinante",
    cost: "2",
    attribute: "Special",
    power: "2000",
    counter: "1000",
    color: "Black",
    type: "Navy/Donquixote Pirates",
    category: "CHARACTER",
    effect:
      "[Blocker] (After your opponent declares an attack, you may rest this card to make it the new target of the attack.)",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "C",
    image: "OP02-108.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-108_p1",
    name: "Donquixote Rosinante",
    cost: "2",
    attribute: "Special",
    power: "2000",
    counter: "1000",
    color: "Black",
    type: "Navy/Donquixote Pirates",
    category: "CHARACTER",
    effect:
      "[Blocker] (After your opponent declares an attack, you may rest this card to make it the new target of the attack.)",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "C",
    image: "OP02-108_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-109",
    name: "Jaguar.D.Saul",
    cost: "4",
    attribute: "Strike",
    power: "6000",
    counter: "1000",
    color: "Black",
    type: "Giant/Navy",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "C",
    image: "OP02-109.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-110",
    name: "Hina",
    cost: "5",
    attribute: "Special",
    power: "6000",
    counter: "1000",
    color: "Black",
    type: "Navy",
    category: "CHARACTER",
    effect:
      "[Blocker] (After your opponent declares an attack, you may rest this card to make it the new target of the attack.)",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "R",
    image: "OP02-110.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-111",
    name: "Fullbody",
    cost: "2",
    attribute: "Strike",
    power: "3000",
    counter: "1000",
    color: "Black",
    type: "Navy",
    category: "CHARACTER",
    effect:
      "[When Attacking] If you have [Jango], this card gains +3000 power during this battle.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "C",
    image: "OP02-111.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-112",
    name: "Bell-mère",
    cost: "1",
    attribute: "Ranged",
    power: "1000",
    counter: "1000",
    color: "Black",
    type: "Navy",
    category: "CHARACTER",
    effect:
      "[Activate: Main] You may rest this Character: Give up to 1 of your opponent's Characters −1 cost during this turn. Then, up to 1 of your Leader or Character cards gains +1000 power during this turn.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "UC",
    image: "OP02-112.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-113",
    name: "Helmeppo",
    cost: "3",
    attribute: "Slash",
    power: "3000",
    counter: "1000",
    color: "Black",
    type: "Navy",
    category: "CHARACTER",
    effect:
      "[When Attacking] Give up to 1 of your opponent's Characters −2 cost during this turn. Then, if there is a Character with a cost of 0, this Character gains +2000 power during this battle.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "UC",
    image: "OP02-113.png",
    life: "",
    trigger: "[Trigger] Play this card.",
  },
  {
    number: "OP02-114",
    name: "Borsalino",
    cost: "4",
    attribute: "Special",
    power: "5000",
    counter: "1000",
    color: "Black",
    type: "Navy",
    category: "CHARACTER",
    effect:
      "[Opponent's Turn] This Character gains +1000 power and cannot be K.O.'d by effects.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "SR",
    image: "OP02-114.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-114_p1",
    name: "Borsalino",
    cost: "4",
    attribute: "Special",
    power: "5000",
    counter: "1000",
    color: "Black",
    type: "Navy",
    category: "CHARACTER",
    effect:
      "[Opponent's Turn] This Character gains +1000 power and cannot be K.O.'d by effects.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "SR",
    image: "OP02-114_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-115",
    name: "Monkey.D.Garp",
    cost: "2",
    attribute: "Strike",
    power: "3000",
    counter: "2000",
    color: "Black",
    type: "Navy",
    category: "CHARACTER",
    effect:
      "[DON!! x2] [When Attacking] K.O. up to 1 of your opponent's Characters with a cost of 0.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "R",
    image: "OP02-115.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-115_p1",
    name: "Monkey.D.Garp",
    cost: "2",
    attribute: "Strike",
    power: "3000",
    counter: "2000",
    color: "Black",
    type: "Navy",
    category: "CHARACTER",
    effect:
      "[DON!! x2] [When Attacking] K.O. up to 1 of your opponent's Characters with a cost of 0.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "R",
    image: "OP02-115_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-116",
    name: "Yamakaji",
    cost: "3",
    attribute: "Slash",
    power: "5000",
    counter: "1000",
    color: "Black",
    type: "Navy",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "C",
    image: "OP02-116.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-117",
    name: "Ice Age",
    cost: "1",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Black",
    type: "Navy",
    category: "EVENT",
    effect:
      "[Main] Give up to 1 of your opponent's Characters −5 cost during this turn.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "UC",
    image: "OP02-117.png",
    life: "",
    trigger:
      "[Trigger] K.O. up to 1 of your opponent's Characters with a cost of 3 or less.",
  },
  {
    number: "OP02-118",
    name: "Yasakani Sacred Jewel",
    cost: "1",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Black",
    type: "Navy",
    category: "EVENT",
    effect:
      "[Counter] You may trash 1 card from your hand: Select up to 1 of your Characters. The selected Character cannot be K.O.'d during this battle.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "C",
    image: "OP02-118.png",
    life: "",
    trigger:
      "[Trigger] K.O. up to 1 of your opponent's Stages with a cost of 3 or less.",
  },
  {
    number: "OP02-119",
    name: "Meteor Volcano",
    cost: "2",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Black",
    type: "Navy",
    category: "EVENT",
    effect:
      "[Main] K.O. up to 1 of your opponent's Characters with a cost of 1 or less.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "R",
    image: "OP02-119.png",
    life: "",
    trigger: "[Trigger] Draw 2 cards and trash 1 card from your hand.",
  },
  {
    number: "OP02-120",
    name: "Uta",
    cost: "8",
    attribute: "Special",
    power: "8000",
    counter: "-",
    color: "Purple",
    type: "FILM",
    category: "CHARACTER",
    effect:
      "[On Play] DON!! −2 (You may return the specified number of DON!! cards from your field to your DON!! deck.): Your Leader and all of your Characters gain +1000 power until the start of your next turn.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "SEC",
    image: "OP02-120.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-120_p1",
    name: "Uta",
    cost: "8",
    attribute: "Special",
    power: "8000",
    counter: "-",
    color: "Purple",
    type: "FILM",
    category: "CHARACTER",
    effect:
      "[On Play] DON!! −2 (You may return the specified number of DON!! cards from your field to your DON!! deck.): Your Leader and all of your Characters gain +1000 power until the start of your next turn.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "SEC",
    image: "OP02-120_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-121",
    name: "Kuzan",
    cost: "10",
    attribute: "Special",
    power: "10000",
    counter: "-",
    color: "Black",
    type: "Former Navy",
    category: "CHARACTER",
    effect: "[Your Turn] Give all of your opponent's Characters −5 cost.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "SEC",
    image: "OP02-121.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP02-121_p1",
    name: "Kuzan",
    cost: "10",
    attribute: "Special",
    power: "10000",
    counter: "-",
    color: "Black",
    type: "Former Navy",
    category: "CHARACTER",
    effect: "[Your Turn] Give all of your opponent's Characters −5 cost.",
    cardSet: "-PARAMOUNT WAR- [OP02]",
    rarity: "SEC",
    image: "OP02-121_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-001_p2",
    name: "Portgas.D.Ace",
    cost: "",
    attribute: "Special",
    power: "5000",
    counter: "-",
    color: "Red",
    type: "Whitebeard Pirates",
    category: "LEADER",
    effect:
      "When this Leader attacks or is attacked, you may trash any number of Event or Stage cards from your hand. This Leader gains +1000 power during this battle for every card trashed.",
    cardSet: "Special Goods Set -Ace/Sabo/Luffy-",
    rarity: "L",
    image: "OP03-001_p2.png",
    life: "5",
    trigger: "",
  },
  {
    number: "OP03-001",
    name: "Portgas.D.Ace",
    cost: "",
    attribute: "Special",
    power: "5000",
    counter: "-",
    color: "Red",
    type: "Whitebeard Pirates",
    category: "LEADER",
    effect:
      "When this Leader attacks or is attacked, you may trash any number of Event or Stage cards from your hand. This Leader gains +1000 power during this battle for every card trashed.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "L",
    image: "OP03-001.png",
    life: "5",
    trigger: "",
  },
  {
    number: "OP03-001_p1",
    name: "Portgas.D.Ace",
    cost: "",
    attribute: "Special",
    power: "5000",
    counter: "-",
    color: "Red",
    type: "Whitebeard Pirates",
    category: "LEADER",
    effect:
      "When this Leader attacks or is attacked, you may trash any number of Event or Stage cards from your hand. This Leader gains +1000 power during this battle for every card trashed.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "L",
    image: "OP03-001_p1.png",
    life: "5",
    trigger: "",
  },
  {
    number: "OP03-002",
    name: "Adio",
    cost: "4",
    attribute: "Ranged",
    power: "5000",
    counter: "1000",
    color: "Red",
    type: "ODYSSEY",
    category: "CHARACTER",
    effect:
      "[DON!! x1] [When Attacking] Your opponent cannot activate a [Blocker] Character that has 2000 or less power during this battle.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "UC",
    image: "OP03-002.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-003",
    name: "Izo",
    cost: "1",
    attribute: "Ranged",
    power: "2000",
    counter: "1000",
    color: "Red",
    type: "Land of Wano/Whitebeard Pirates",
    category: "CHARACTER",
    effect:
      '[On Play] Look at 5 cards from the top of your deck; reveal up to 1 card with a type including "Whitebeard Pirates" other than [Izo] and add it to your hand. Then, place the rest at the bottom of your deck in any order.',
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "R",
    image: "OP03-003.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-004",
    name: "Curiel",
    cost: "3",
    attribute: "Ranged",
    power: "4000",
    counter: "1000",
    color: "Red",
    type: "Whitebeard Pirates",
    category: "CHARACTER",
    effect:
      "This Character cannot attack a Leader on the turn in which it is played.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "C",
    image: "OP03-004.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-005",
    name: "Thatch",
    cost: "1",
    attribute: "Slash",
    power: "2000",
    counter: "1000",
    color: "Red",
    type: "Whitebeard Pirates",
    category: "CHARACTER",
    effect:
      "[Activate: Main] [Once Per Turn] This Character gains +2000 power during this turn. Then, trash this Character at the end of this turn.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "UC",
    image: "OP03-005.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-006",
    name: "Speed Jil",
    cost: "4",
    attribute: "Slash",
    power: "6000",
    counter: "1000",
    color: "Red",
    type: "Whitebeard Pirates",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "C",
    image: "OP03-006.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-007",
    name: "Namule",
    cost: "3",
    attribute: "Strike",
    power: "5000",
    counter: "1000",
    color: "Red",
    type: "Fish-Man/Whitebeard Pirates",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "C",
    image: "OP03-007.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-008",
    name: "Buggy",
    cost: "1",
    attribute: "Slash",
    power: "3000",
    counter: "-",
    color: "Red",
    type: "Buggy Pirates",
    category: "CHARACTER",
    effect: "This Character cannot be K.O.'d in battle by",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "UC",
    image: "OP03-008.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-009",
    name: "Haruta",
    cost: "2",
    attribute: "Slash",
    power: "3000",
    counter: "1000",
    color: "Red",
    type: "Whitebeard Pirates",
    category: "CHARACTER",
    effect:
      "[Activate: Main] [Once Per Turn] Give up to 1 rested DON!! card to your Leader or 1 of your Characters.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "C",
    image: "OP03-009.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-010",
    name: "Fossa",
    cost: "2",
    attribute: "Slash",
    power: "2000",
    counter: "1000",
    color: "Red",
    type: "Whitebeard Pirates",
    category: "CHARACTER",
    effect:
      "[Blocker] (After your opponent declares an attack, you may rest this card to make it the new target of the attack.)",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "C",
    image: "OP03-010.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-011",
    name: "Blamenco",
    cost: "2",
    attribute: "Strike",
    power: "3000",
    counter: "1000",
    color: "Red",
    type: "Whitebeard Pirates",
    category: "CHARACTER",
    effect:
      "[DON!! x1] [When Attacking] Give up to 1 of your opponent's Characters −2000 power during this turn.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "UC",
    image: "OP03-011.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-012",
    name: "Marshall.D.Teach",
    cost: "4",
    attribute: "Strike",
    power: "6000",
    counter: "-",
    color: "Red",
    type: "Whitebeard Pirates",
    category: "CHARACTER",
    effect:
      "[When Attacking] You may trash 1 of your red Characters with 4000 power or more: Draw 1 card. Then, this Character gains +1000 power during this battle.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "R",
    image: "OP03-012.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-013",
    name: "Marco",
    cost: "5",
    attribute: "Special",
    power: "6000",
    counter: "1000",
    color: "Red",
    type: "Whitebeard Pirates",
    category: "CHARACTER",
    effect:
      "[Your Turn] [On Play] K.O. up to 1 of your opponent's Characters with 3000 power or less.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "SR",
    image: "OP03-013.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-013_p1",
    name: "Marco",
    cost: "5",
    attribute: "Special",
    power: "6000",
    counter: "1000",
    color: "Red",
    type: "Whitebeard Pirates",
    category: "CHARACTER",
    effect:
      "[Your Turn] [On Play] K.O. up to 1 of your opponent's Characters with 3000 power or less.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "SR",
    image: "OP03-013_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-014",
    name: "Monkey.D.Garp",
    cost: "3",
    attribute: "Strike",
    power: "5000",
    counter: "-",
    color: "Red",
    type: "Navy",
    category: "CHARACTER",
    effect:
      "[When Attacking] Play up to 1 red Character card with a cost of 1 from your hand.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "UC",
    image: "OP03-014.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-015",
    name: "Lim",
    cost: "3",
    attribute: "Wisdom",
    power: "2000",
    counter: "-",
    color: "Red",
    type: "ODYSSEY",
    category: "CHARACTER",
    effect:
      "[Blocker] (After your opponent declares an attack, you may rest this card to make it the new target of the attack.)",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "UC",
    image: "OP03-015.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-016",
    name: "Flame Emperor",
    cost: "7",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Red",
    type: "Whitebeard Pirates",
    category: "EVENT",
    effect:
      "[Main] If your Leader is [Portgas.D.Ace], K.O. up to 1 of your opponent's Characters with 8000 power or less, and your Leader gains [Double Attack] and +3000 power during this turn.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "R",
    image: "OP03-016.png",
    life: "",
    trigger:
      "[Trigger] K.O. up to 1 of your opponent's Characters with 6000 power or less.",
  },
  {
    number: "OP03-017",
    name: "Cross Fire",
    cost: "2",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Red",
    type: "Whitebeard Pirates",
    category: "EVENT",
    effect:
      "[Main]/[Counter] If your Leader's type includes \"Whitebeard Pirates\", give up to 1 of your opponent's Characters −4000 power during this turn.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "UC",
    image: "OP03-017.png",
    life: "",
    trigger: "[Trigger] Activate this card's [Main] effect.",
  },
  {
    number: "OP03-018",
    name: "Fire Fist",
    cost: "3",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Red",
    type: "Whitebeard Pirates",
    category: "EVENT",
    effect:
      "[Main] You may trash 1 Event from your hand: K.O. up to 1 of your opponent's Characters with 5000 power or less and up to 1 of your opponent's Characters with 4000 power or less.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "R",
    image: "OP03-018.png",
    life: "",
    trigger:
      "[Trigger] K.O. up to 1 of your opponent's Characters with 5000 power or less.",
  },
  {
    number: "OP03-018_p1",
    name: "Fire Fist",
    cost: "3",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Red",
    type: "Whitebeard Pirates",
    category: "EVENT",
    effect:
      "[Main] You may trash 1 Event from your hand: K.O. up to 1 of your opponent's Characters with 5000 power or less and up to 1 of your opponent's Characters with 4000 power or less.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "R",
    image: "OP03-018_p1.png",
    life: "",
    trigger:
      "[Trigger] K.O. up to 1 of your opponent's Characters with 5000 power or less.",
  },
  {
    number: "OP03-019",
    name: "Fiery Doll",
    cost: "2",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Red",
    type: "Whitebeard Pirates",
    category: "EVENT",
    effect: "[Main] Your Leader gains +4000 power during this turn.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "C",
    image: "OP03-019.png",
    life: "",
    trigger:
      "[Trigger] Give up to 1 of your opponent's Leader or Character cards −10000 power during this turn.",
  },
  {
    number: "OP03-020",
    name: "Striker",
    cost: "1",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Red",
    type: "Whitebeard Pirates",
    category: "STAGE",
    effect:
      "[Activate: Main] ② (You may rest the specified number of DON!! cards in your cost area.) You may rest this Stage: If your Leader is [Portgas.D.Ace], look at 5 cards from the top of your deck; reveal up to 1 Event and add it to your hand. Then, place the rest at the bottom of your deck in any order.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "C",
    image: "OP03-020.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-021",
    name: "Kuro",
    cost: "",
    attribute: "Slash",
    power: "5000",
    counter: "-",
    color: "Green",
    type: "East Blue/Black Cat Pirates",
    category: "LEADER",
    effect:
      "[Activate: Main] ③ (You may rest the specified number of DON!! cards in your cost area.) You may rest 2 of your {East Blue} type Characters: Set this Leader as active, and rest up to 1 of your opponent's Characters with a cost of 5 or less.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "L",
    image: "OP03-021.png",
    life: "5",
    trigger: "",
  },
  {
    number: "OP03-021_p1",
    name: "Kuro",
    cost: "",
    attribute: "Slash",
    power: "5000",
    counter: "-",
    color: "Green",
    type: "East Blue/Black Cat Pirates",
    category: "LEADER",
    effect:
      "[Activate: Main] ③ (You may rest the specified number of DON!! cards in your cost area.) You may rest 2 of your {East Blue} type Characters: Set this Leader as active, and rest up to 1 of your opponent's Characters with a cost of 5 or less.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "L",
    image: "OP03-021_p1.png",
    life: "5",
    trigger: "",
  },
  {
    number: "OP03-022",
    name: "Arlong",
    cost: "",
    attribute: "Slash",
    power: "5000",
    counter: "-",
    color: "Green/Yellow",
    type: "Fish-Man/East Blue/Arlong Pirates",
    category: "LEADER",
    effect:
      "[DON!! x2] [When Attacking] ① (You may rest the specified number of DON!! cards in your cost area.): Play up to 1 Character card with a cost of 4 or less and a [Trigger] from your hand.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "L",
    image: "OP03-022.png",
    life: "4",
    trigger: "",
  },
  {
    number: "OP03-022_p1",
    name: "Arlong",
    cost: "",
    attribute: "Slash",
    power: "5000",
    counter: "-",
    color: "Green/Yellow",
    type: "Fish-Man/East Blue/Arlong Pirates",
    category: "LEADER",
    effect:
      "[DON!! x2] [When Attacking] ① (You may rest the specified number of DON!! cards in your cost area.): Play up to 1 Character card with a cost of 4 or less and a [Trigger] from your hand.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "L",
    image: "OP03-022_p1.png",
    life: "4",
    trigger: "",
  },
  {
    number: "OP03-023",
    name: "Alvida",
    cost: "1",
    attribute: "Strike",
    power: "3000",
    counter: "1000",
    color: "Green",
    type: "East Blue/Alvida Pirates",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "C",
    image: "OP03-023.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-024",
    name: "Gin",
    cost: "4",
    attribute: "Strike",
    power: "5000",
    counter: "1000",
    color: "Green",
    type: "East Blue/Krieg Pirates",
    category: "CHARACTER",
    effect:
      "[On Play] If your Leader has the {East Blue} type, rest up to 2 of your opponent's Characters with a cost of 4 or less.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "R",
    image: "OP03-024.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-024_p1",
    name: "Gin",
    cost: "4",
    attribute: "Strike",
    power: "5000",
    counter: "1000",
    color: "Green",
    type: "East Blue/Krieg Pirates",
    category: "CHARACTER",
    effect:
      "[On Play] If your Leader has the {East Blue} type, rest up to 2 of your opponent's Characters with a cost of 4 or less.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "R",
    image: "OP03-024_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-025",
    name: "Krieg",
    cost: "6",
    attribute: "Ranged",
    power: "7000",
    counter: "-",
    color: "Green",
    type: "East Blue/Krieg Pirates",
    category: "CHARACTER",
    effect:
      "[On Play] You may trash 1 card from your hand: K.O. up to 2 of your opponent's rested Characters with a cost of 4 or less.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "SR",
    image: "OP03-025.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-025_p1",
    name: "Krieg",
    cost: "6",
    attribute: "Ranged",
    power: "7000",
    counter: "-",
    color: "Green",
    type: "East Blue/Krieg Pirates",
    category: "CHARACTER",
    effect:
      "[On Play] You may trash 1 card from your hand: K.O. up to 2 of your opponent's rested Characters with a cost of 4 or less.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "SR",
    image: "OP03-025_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-026",
    name: "Kuroobi",
    cost: "4",
    attribute: "Strike",
    power: "3000",
    counter: "1000",
    color: "Green",
    type: "Fish-Man/East Blue/Arlong Pirates",
    category: "CHARACTER",
    effect:
      "[On Play] If your Leader has the {East Blue} type, rest up to 1 of your opponent's Characters.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "UC",
    image: "OP03-026.png",
    life: "",
    trigger: "[Trigger] Play this card.",
  },
  {
    number: "OP03-027",
    name: "Sham",
    cost: "3",
    attribute: "Slash",
    power: "4000",
    counter: "1000",
    color: "Green",
    type: "East Blue/Black Cat Pirates",
    category: "CHARACTER",
    effect:
      "[On Play] If your Leader has the {East Blue} type, rest up to 1 of your opponent's Characters with a cost of 2 or less and, if you don't have [Buchi], play up to 1 [Buchi] from your hand.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "C",
    image: "OP03-027.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-028",
    name: "Jango",
    cost: "5",
    attribute: "Special",
    power: "6000",
    counter: "-",
    color: "Green",
    type: "East Blue/Black Cat Pirates",
    category: "CHARACTER",
    effect: "[On Play] Choose one:",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "R",
    image: "OP03-028.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-029",
    name: "Chew",
    cost: "4",
    attribute: "Ranged",
    power: "3000",
    counter: "1000",
    color: "Green",
    type: "Fish-Man/East Blue/Arlong Pirates",
    category: "CHARACTER",
    effect:
      "[On Play] K.O. up to 1 of your opponent's rested Characters with a cost of 4 or less.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "UC",
    image: "OP03-029.png",
    life: "",
    trigger: "[Trigger] Play this card.",
  },
  {
    number: "OP03-030",
    name: "Nami",
    cost: "2",
    attribute: "Wisdom",
    power: "2000",
    counter: "1000",
    color: "Green",
    type: "East Blue/Arlong Pirates",
    category: "CHARACTER",
    effect:
      "[On Play] Look at 5 cards from the top of your deck; reveal up to 1 green {East Blue} type card other than [Nami] and add it to your hand. Then, place the rest at the bottom of your deck in any order.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "R",
    image: "OP03-030.png",
    life: "",
    trigger: "[Trigger] Play this card.",
  },
  {
    number: "OP03-031",
    name: "Pearl",
    cost: "2",
    attribute: "Strike",
    power: "2000",
    counter: "1000",
    color: "Green",
    type: "East Blue/Krieg Pirates",
    category: "CHARACTER",
    effect:
      "[Blocker] (After your opponent declares an attack, you may rest this card to make it the new target of the attack.)",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "C",
    image: "OP03-031.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-032",
    name: "Buggy",
    cost: "3",
    attribute: "Slash",
    power: "5000",
    counter: "-",
    color: "Green",
    type: "East Blue/Buggy Pirates",
    category: "CHARACTER",
    effect: "This Character cannot be K.O.'d in battle by",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "C",
    image: "OP03-032.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-033",
    name: "Hatchan",
    cost: "4",
    attribute: "Slash",
    power: "4000",
    counter: "2000",
    color: "Green",
    type: "Fish-Man/East Blue/Arlong Pirates",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "UC",
    image: "OP03-033.png",
    life: "",
    trigger:
      "[Trigger] If your Leader has the {East Blue} type, play this card.",
  },
  {
    number: "OP03-034",
    name: "Buchi",
    cost: "4",
    attribute: "Slash",
    power: "5000",
    counter: "1000",
    color: "Green",
    type: "East Blue/Black Cat Pirates",
    category: "CHARACTER",
    effect:
      "[On Play] K.O. up to 1 of your opponent's rested Characters with a cost of 2 or less.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "UC",
    image: "OP03-034.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-035",
    name: "Momoo",
    cost: "2",
    attribute: "Strike",
    power: "4000",
    counter: "1000",
    color: "Green",
    type: "Animal/East Blue",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "C",
    image: "OP03-035.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-036",
    name: "Out-of-the-Bag",
    cost: "3",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Green",
    type: "East Blue/Black Cat Pirates",
    category: "EVENT",
    effect:
      "[Main] You may rest 1 of your {East Blue} type Characters: Set up to 1 of your [Kuro] cards as active.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "C",
    image: "OP03-036.png",
    life: "",
    trigger:
      "[Trigger] K.O. up to 1 of your opponent's rested Characters with a cost of 3 or less.",
  },
  {
    number: "OP03-037",
    name: "Tooth Attack",
    cost: "1",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Green",
    type: "Fish-Man/East Blue/Arlong Pirates",
    category: "EVENT",
    effect:
      "[Main] You may rest 1 of your {East Blue} type Characters: K.O. up to 1 of your opponent's rested Characters with a cost of 3 or less.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "C",
    image: "OP03-037.png",
    life: "",
    trigger:
      "[Trigger] Play up to 1 Character card with a cost of 4 or less and a [Trigger] from your hand.",
  },
  {
    number: "OP03-038",
    name: "Deathly Poison Gas Bomb MH5",
    cost: "1",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Green",
    type: "East Blue/Krieg Pirates",
    category: "EVENT",
    effect:
      "[Main] Rest up to 2 of your opponent's Characters with a cost of 2 or less.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "R",
    image: "OP03-038.png",
    life: "",
    trigger:
      "[Trigger] Rest up to 1 of your opponent's Characters with a cost of 5 or less.",
  },
  {
    number: "OP03-039",
    name: "One, Two, Jango",
    cost: "1",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Green",
    type: "East Blue/Black Cat Pirates",
    category: "EVENT",
    effect:
      "[Main] Rest up to 1 of your opponent's Characters with a cost of 1 or less. Then, up to 1 of your Characters gains +1000 power during this turn.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "UC",
    image: "OP03-039.png",
    life: "",
    trigger:
      "[Trigger] Rest up to 1 of your opponent's Characters with a cost of 4 or less.",
  },
  {
    number: "OP03-040",
    name: "Nami",
    cost: "",
    attribute: "Wisdom",
    power: "5000",
    counter: "-",
    color: "Blue",
    type: "East Blue",
    category: "LEADER",
    effect:
      "When your deck is reduced to 0, you win the game instead of losing, according to the rules.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "L",
    image: "OP03-040.png",
    life: "5",
    trigger: "",
  },
  {
    number: "OP03-040_p1",
    name: "Nami",
    cost: "",
    attribute: "Wisdom",
    power: "5000",
    counter: "-",
    color: "Blue",
    type: "East Blue",
    category: "LEADER",
    effect:
      "When your deck is reduced to 0, you win the game instead of losing, according to the rules.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "L",
    image: "OP03-040_p1.png",
    life: "5",
    trigger: "",
  },
  {
    number: "OP03-041",
    name: "Usopp",
    cost: "4",
    attribute: "Ranged",
    power: "5000",
    counter: "-",
    color: "Blue",
    type: "East Blue",
    category: "CHARACTER",
    effect: "[Rush] (This card can attack on the turn in which it is played.)",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "SR",
    image: "OP03-041.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-041_p1",
    name: "Usopp",
    cost: "4",
    attribute: "Ranged",
    power: "5000",
    counter: "-",
    color: "Blue",
    type: "East Blue",
    category: "CHARACTER",
    effect: "[Rush] (This card can attack on the turn in which it is played.)",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "SR",
    image: "OP03-041_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-042",
    name: "Usopp's Pirate Crew",
    cost: "1",
    attribute: "Wisdom",
    power: "-",
    counter: "1000",
    color: "Blue",
    type: "East Blue",
    category: "CHARACTER",
    effect: "[On Play] Add up to 1 blue [Usopp] from your trash to your hand.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "C",
    image: "OP03-042.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-043",
    name: "Gaimon",
    cost: "2",
    attribute: "Wisdom",
    power: "-",
    counter: "1000",
    color: "Blue",
    type: "East Blue",
    category: "CHARACTER",
    effect:
      "When you deal damage to your opponent's Life, you may trash 3 cards from the top of your deck. If you do, trash this Character.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "C",
    image: "OP03-043.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-044",
    name: "Kaya",
    cost: "1",
    attribute: "Wisdom",
    power: "-",
    counter: "2000",
    color: "Blue",
    type: "East Blue",
    category: "CHARACTER",
    effect: "[On Play] Draw 2 cards and trash 2 cards from your hand.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "R",
    image: "OP03-044.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-045",
    name: "Carne",
    cost: "3",
    attribute: "Slash",
    power: "3000",
    counter: "1000",
    color: "Blue",
    type: "East Blue",
    category: "CHARACTER",
    effect:
      "[Blocker] (After your opponent declares an attack, you may rest this card to make it the new target of the attack.)",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "UC",
    image: "OP03-045.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-046",
    name: "Genzo",
    cost: "2",
    attribute: "Wisdom",
    power: "4000",
    counter: "1000",
    color: "Blue",
    type: "East Blue",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "C",
    image: "OP03-046.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-047",
    name: "Zeff",
    cost: "5",
    attribute: "Strike",
    power: "6000",
    counter: "1000",
    color: "Blue",
    type: "East Blue",
    category: "CHARACTER",
    effect:
      "[DON!! x1] When this Character's attack deals damage to your opponent's Life, you may trash 7 cards from the top of your deck.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "R",
    image: "OP03-047.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-047_p1",
    name: "Zeff",
    cost: "5",
    attribute: "Strike",
    power: "6000",
    counter: "1000",
    color: "Blue",
    type: "East Blue",
    category: "CHARACTER",
    effect:
      "[DON!! x1] When this Character's attack deals damage to your opponent's Life, you may trash 7 cards from the top of your deck.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "R",
    image: "OP03-047_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-048",
    name: "Nojiko",
    cost: "2",
    attribute: "Wisdom",
    power: "-",
    counter: "1000",
    color: "Blue",
    type: "East Blue",
    category: "CHARACTER",
    effect:
      "[On Play] If your Leader is [Nami], return up to 1 of your opponent's Characters with a cost of 5 or less to the owner's hand.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "UC",
    image: "OP03-048.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-049",
    name: "Patty",
    cost: "3",
    attribute: "Slash",
    power: "5000",
    counter: "-",
    color: "Blue",
    type: "East Blue",
    category: "CHARACTER",
    effect:
      "[On Play] If you have 20 or less cards in your deck, return up to 1 Character with a cost of 3 or less to the owner's hand.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "UC",
    image: "OP03-049.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-050",
    name: "Boodle",
    cost: "2",
    attribute: "Wisdom",
    power: "-",
    counter: "1000",
    color: "Blue",
    type: "East Blue",
    category: "CHARACTER",
    effect:
      "[Blocker] (After your opponent declares an attack, you may rest this card to make it the new target of the attack.)",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "UC",
    image: "OP03-050.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-051",
    name: "Bell-mère",
    cost: "4",
    attribute: "Ranged",
    power: "5000",
    counter: "1000",
    color: "Blue",
    type: "East Blue/Former Navy",
    category: "CHARACTER",
    effect:
      "[DON!! x1] When this Character's attack deals damage to your opponent's Life, you may trash 7 cards from the top of your deck.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "R",
    image: "OP03-051.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-052",
    name: "Merry",
    cost: "1",
    attribute: "Wisdom",
    power: "3000",
    counter: "1000",
    color: "Blue",
    type: "East Blue",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "C",
    image: "OP03-052.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-053",
    name: "Yosaku & Johnny",
    cost: "1",
    attribute: "Slash",
    power: "3000",
    counter: "-",
    color: "Blue",
    type: "East Blue",
    category: "CHARACTER",
    effect:
      "[DON!! x1] If you have 20 or less cards in your deck, this Character gains +2000 power.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "C",
    image: "OP03-053.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-054",
    name: "Usopp's Rubber Band of Doom!!!",
    cost: "1",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Blue",
    type: "East Blue/Straw Hat Crew",
    category: "EVENT",
    effect:
      "[Counter] Up to 1 of your Leader or Character cards gains +2000 power during this battle. Then, you may trash 1 card from the top of your deck.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "C",
    image: "OP03-054.png",
    life: "",
    trigger:
      "[Trigger] Draw 1 card and you may trash 1 card from the top of your deck.",
  },
  {
    number: "OP03-055",
    name: "Gum-Gum Giant Gavel",
    cost: "1",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Blue",
    type: "East Blue/Straw Hat Crew",
    category: "EVENT",
    effect:
      "[Counter] You may trash 1 card from your hand: Up to 1 of your Leader gains +4000 power during this battle. Then, you may trash 2 cards from the top of your deck.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "C",
    image: "OP03-055.png",
    life: "",
    trigger:
      "[Trigger] Return up to 1 Character with a cost of 4 or less to the owner's hand.",
  },
  {
    number: "OP03-056",
    name: "Sanji's Pilaf",
    cost: "3",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Blue",
    type: "East Blue",
    category: "EVENT",
    effect: "[Main] Draw 2 cards.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "UC",
    image: "OP03-056.png",
    life: "",
    trigger: "[Trigger] Activate this card's [Main] effect.",
  },
  {
    number: "OP03-057",
    name: "Three Thousand Worlds",
    cost: "4",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Blue",
    type: "East Blue/Straw Hat Crew",
    category: "EVENT",
    effect:
      "[Main] Place up to 1 Character with a cost of 5 or less at the bottom of the owner's deck.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "R",
    image: "OP03-057.png",
    life: "",
    trigger:
      "[Trigger] Place up to 1 Character with a cost of 3 or less at the bottom of the owner's deck.",
  },
  {
    number: "OP03-058",
    name: "Iceburg",
    cost: "",
    attribute: "Wisdom",
    power: "5000",
    counter: "-",
    color: "Purple",
    type: "Water Seven/Galley-La Company",
    category: "LEADER",
    effect: "This Leader cannot attack.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "L",
    image: "OP03-058.png",
    life: "5",
    trigger: "",
  },
  {
    number: "OP03-058_p1",
    name: "Iceburg",
    cost: "",
    attribute: "Wisdom",
    power: "5000",
    counter: "-",
    color: "Purple",
    type: "Water Seven/Galley-La Company",
    category: "LEADER",
    effect: "This Leader cannot attack.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "L",
    image: "OP03-058_p1.png",
    life: "5",
    trigger: "",
  },
  {
    number: "OP03-059",
    name: "Kaku",
    cost: "5",
    attribute: "Slash",
    power: "6000",
    counter: "1000",
    color: "Purple",
    type: "Water Seven/Galley-La Company",
    category: "CHARACTER",
    effect:
      "[When Attacking] DON!! −1 (You may return the specified number of DON!! cards from your field to your DON!! deck.): This Character gains [Banish] during this battle.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "UC",
    image: "OP03-059.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-060",
    name: "Kalifa",
    cost: "4",
    attribute: "Wisdom",
    power: "4000",
    counter: "2000",
    color: "Purple",
    type: "Water Seven/Galley-La Company",
    category: "CHARACTER",
    effect:
      "[When Attacking] DON!! −1 (You may return the specified number of DON!! cards from your field to your DON!! deck.): Draw 2 cards and trash 1 card from your hand.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "UC",
    image: "OP03-060.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-061",
    name: "Kiwi & Mozu",
    cost: "2",
    attribute: "Slash",
    power: "4000",
    counter: "1000",
    color: "Purple",
    type: "Water Seven/The Franky Family",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "C",
    image: "OP03-061.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-062",
    name: "Kokoro",
    cost: "1",
    attribute: "Wisdom",
    power: "2000",
    counter: "1000",
    color: "Purple",
    type: "Merfolk/Water Seven",
    category: "CHARACTER",
    effect:
      "[On Play] Look at 5 cards from the top of your deck; reveal up to 1 {Water Seven} type card other than [Kokoro] and add it to your hand. Then, place the rest at the bottom of your deck in any order.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "R",
    image: "OP03-062.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-063",
    name: "Zambai",
    cost: "3",
    attribute: "Slash",
    power: "2000",
    counter: "-",
    color: "Purple",
    type: "Water Seven/The Franky Family",
    category: "CHARACTER",
    effect:
      "[Blocker] (After your opponent declares an attack, you may rest this card to make it the new target of the attack.)",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "UC",
    image: "OP03-063.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-064",
    name: "Tilestone",
    cost: "5",
    attribute: "Strike",
    power: "6000",
    counter: "1000",
    color: "Purple",
    type: "Water Seven/Galley-La Company",
    category: "CHARACTER",
    effect:
      "[On K.O.] If your Leader has the {Galley-La Company} type, add up to 1 DON!! card from your DON!! deck and rest it.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "C",
    image: "OP03-064.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-065",
    name: "Chimney & Gonbe",
    cost: "2",
    attribute: "Wisdom",
    power: "2000",
    counter: "1000",
    color: "Purple",
    type: "Animal/Water Seven",
    category: "CHARACTER",
    effect:
      "[Blocker] (After your opponent declares an attack, you may rest this card to make it the new target of the attack.)",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "C",
    image: "OP03-065.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-066",
    name: "Paulie",
    cost: "5",
    attribute: "Strike",
    power: "6000",
    counter: "-",
    color: "Purple",
    type: "Water Seven/Galley-La Company",
    category: "CHARACTER",
    effect:
      "[On Play] ➁ (You may rest the specified number of DON!! cards in your cost area.): Add up to 1 DON!! card from your DON!! deck and set it as active. Then, if you have 8 or more DON!! cards on your field, K.O. up to 1 of your opponent's Characters with a cost of 4 or less.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "SR",
    image: "OP03-066.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-066_p1",
    name: "Paulie",
    cost: "5",
    attribute: "Strike",
    power: "6000",
    counter: "-",
    color: "Purple",
    type: "Water Seven/Galley-La Company",
    category: "CHARACTER",
    effect:
      "[On Play] ➁ (You may rest the specified number of DON!! cards in your cost area.): Add up to 1 DON!! card from your DON!! deck and set it as active. Then, if you have 8 or more DON!! cards on your field, K.O. up to 1 of your opponent's Characters with a cost of 4 or less.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "SR",
    image: "OP03-066_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-067",
    name: "Peepley Lulu",
    cost: "5",
    attribute: "Ranged",
    power: "5000",
    counter: "1000",
    color: "Purple",
    type: "Water Seven/Galley-La Company",
    category: "CHARACTER",
    effect:
      "[DON!! x1] [When Attacking] If your Leader has the {Galley-La Company} type, add up to 1 DON!! card from your DON!! deck and rest it.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "UC",
    image: "OP03-067.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-068",
    name: "Minozebra",
    cost: "4",
    attribute: "Strike",
    power: "5000",
    counter: "1000",
    color: "Purple",
    type: "Impel Down/Jailer Beast",
    category: "CHARACTER",
    effect:
      "[Banish] (When this card deals damage, the target card is trashed without activating its Trigger.)",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "C",
    image: "OP03-068.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-069",
    name: "Minorhinoceros",
    cost: "3",
    attribute: "Strike",
    power: "5000",
    counter: "-",
    color: "Purple",
    type: "Impel Down/Jailer Beast",
    category: "CHARACTER",
    effect:
      "[On K.O.] If your Leader has the {Impel Down} type, draw 2 cards and trash 1 card from your hand.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "C",
    image: "OP03-069.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-070",
    name: "Monkey.D.Luffy",
    cost: "6",
    attribute: "Strike",
    power: "7000",
    counter: "-",
    color: "Purple",
    type: "Water Seven/Straw Hat Crew",
    category: "CHARACTER",
    effect:
      "[On Play] DON!! −1 (You may return the specified number of DON!! cards from your field to your DON!! deck.) You may trash 1 Character card with a cost of 5 from your hand: This Character gains [Rush] during this turn.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "R",
    image: "OP03-070.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-071",
    name: "Rob Lucci",
    cost: "5",
    attribute: "Strike",
    power: "6000",
    counter: "1000",
    color: "Purple",
    type: "Water Seven/Galley-La Company",
    category: "CHARACTER",
    effect:
      "[When Attacking] DON!! −1 (You may return the specified number of DON!! cards from your field to your DON!! deck.): Rest up to 1 of your opponent's Characters with a cost of 5 or less.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "R",
    image: "OP03-071.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-072",
    name: "Gum-Gum Jet Gatling",
    cost: "-",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Purple",
    type: "Water Seven/Straw Hat Crew",
    category: "EVENT",
    effect:
      "[Counter] You may trash 1 card from your hand: Up to 1 of your Leader or Character cards gains +3000 power during this battle.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "R",
    image: "OP03-072.png",
    life: "",
    trigger:
      "[Trigger] Add up to 1 DON!! card from your DON!! deck and set it as active.",
  },
  {
    number: "OP03-073",
    name: "Hull Dismantler Slash",
    cost: "1",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Purple",
    type: "Water Seven/The Franky Family",
    category: "EVENT",
    effect:
      "[Main] DON!! −1 (You may return the specified number of DON!! cards from your field to your DON!! deck.): If your Leader has the {Water Seven} type, K.O. up to 1 of your opponent's Characters with a cost of 2 or less.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "C",
    image: "OP03-073.png",
    life: "",
    trigger: "[Trigger] Activate this card's [Main] effect.",
  },
  {
    number: "OP03-074",
    name: "Top Knot",
    cost: "2",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Purple",
    type: "Water Seven/Galley-La Company",
    category: "EVENT",
    effect:
      "[Main] DON!! −2 (You may return the specified number of DON!! cards from your field to your DON!! deck.): Place up to 1 of your opponent's Characters with a cost of 4 or less at the bottom of the owner's deck.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "UC",
    image: "OP03-074.png",
    life: "",
    trigger: "[Trigger] Activate this card's [Main] effect.",
  },
  {
    number: "OP03-075",
    name: "Galley-La Company",
    cost: "3",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Purple",
    type: "Water Seven/Galley-La Company",
    category: "STAGE",
    effect:
      "[Activate: Main] You may rest this Stage: If your Leader is [Iceburg], add up to 1 DON!! card from your DON!! deck and rest it.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "C",
    image: "OP03-075.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-076",
    name: "Rob Lucci",
    cost: "",
    attribute: "Strike",
    power: "5000",
    counter: "-",
    color: "Black",
    type: "CP9",
    category: "LEADER",
    effect:
      "[Your Turn] [Once Per Turn] You may trash 2 cards from your hand: When your opponent's Character is K.O.'d, set this Leader as active.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "L",
    image: "OP03-076.png",
    life: "5",
    trigger: "",
  },
  {
    number: "OP03-076_p1",
    name: "Rob Lucci",
    cost: "",
    attribute: "Strike",
    power: "5000",
    counter: "-",
    color: "Black",
    type: "CP9",
    category: "LEADER",
    effect:
      "[Your Turn] [Once Per Turn] You may trash 2 cards from your hand: When your opponent's Character is K.O.'d, set this Leader as active.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "L",
    image: "OP03-076_p1.png",
    life: "5",
    trigger: "",
  },
  {
    number: "OP03-077",
    name: "Charlotte Linlin",
    cost: "",
    attribute: "Special",
    power: "5000",
    counter: "-",
    color: "Black/Yellow",
    type: "The Four Emperors/Big Mom Pirates",
    category: "LEADER",
    effect:
      "[DON!! x2] [When Attacking] ② (You may rest the specified number of DON!! cards in your cost area.) You may trash 1 card from your hand: If you have 1 or less Life cards, add up to 1 card from the top of your deck to the top of your Life cards.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "L",
    image: "OP03-077.png",
    life: "4",
    trigger: "",
  },
  {
    number: "OP03-077_p1",
    name: "Charlotte Linlin",
    cost: "",
    attribute: "Special",
    power: "5000",
    counter: "-",
    color: "Black/Yellow",
    type: "The Four Emperors/Big Mom Pirates",
    category: "LEADER",
    effect:
      "[DON!! x2] [When Attacking] ② (You may rest the specified number of DON!! cards in your cost area.) You may trash 1 card from your hand: If you have 1 or less Life cards, add up to 1 card from the top of your deck to the top of your Life cards.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "L",
    image: "OP03-077_p1.png",
    life: "4",
    trigger: "",
  },
  {
    number: "OP03-078",
    name: "Issho",
    cost: "8",
    attribute: "Slash",
    power: "9000",
    counter: "-",
    color: "Black",
    type: "Navy",
    category: "CHARACTER",
    effect:
      "[DON!! x1] [Your Turn] Give all of your opponent's Characters −3 cost.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "SR",
    image: "OP03-078.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-078_p1",
    name: "Issho",
    cost: "8",
    attribute: "Slash",
    power: "9000",
    counter: "-",
    color: "Black",
    type: "Navy",
    category: "CHARACTER",
    effect:
      "[DON!! x1] [Your Turn] Give all of your opponent's Characters −3 cost.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "SR",
    image: "OP03-078_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-079",
    name: "Vergo",
    cost: "5",
    attribute: "Strike",
    power: "5000",
    counter: "2000",
    color: "Black",
    type: "Navy/Donquixote Pirates",
    category: "CHARACTER",
    effect: "[DON!! x1] This Character cannot be K.O.'d in battle.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "UC",
    image: "OP03-079.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-080",
    name: "Kaku",
    cost: "5",
    attribute: "Slash",
    power: "6000",
    counter: "1000",
    color: "Black",
    type: "CP9",
    category: "CHARACTER",
    effect:
      '[On Play] You may place 2 cards with a type including "CP" from your trash at the bottom of your deck in any order: K.O. up to 1 of your opponent\'s Characters with a cost of 3 or less.',
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "SR",
    image: "OP03-080.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-080_p1",
    name: "Kaku",
    cost: "5",
    attribute: "Slash",
    power: "6000",
    counter: "1000",
    color: "Black",
    type: "CP9",
    category: "CHARACTER",
    effect:
      '[On Play] You may place 2 cards with a type including "CP" from your trash at the bottom of your deck in any order: K.O. up to 1 of your opponent\'s Characters with a cost of 3 or less.',
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "SR",
    image: "OP03-080_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-081",
    name: "Kalifa",
    cost: "4",
    attribute: "Special",
    power: "4000",
    counter: "2000",
    color: "Black",
    type: "CP9",
    category: "CHARACTER",
    effect:
      "[On Play] Draw 2 cards and trash 2 cards from your hand. Then, give up to 1 of your opponent's Characters −2 cost during this turn.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "R",
    image: "OP03-081.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-081_p1",
    name: "Kalifa",
    cost: "4",
    attribute: "Special",
    power: "4000",
    counter: "2000",
    color: "Black",
    type: "CP9",
    category: "CHARACTER",
    effect:
      "[On Play] Draw 2 cards and trash 2 cards from your hand. Then, give up to 1 of your opponent's Characters −2 cost during this turn.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "R",
    image: "OP03-081_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-082",
    name: "Kumadori",
    cost: "4",
    attribute: "Strike",
    power: "6000",
    counter: "1000",
    color: "Black",
    type: "CP9",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "C",
    image: "OP03-082.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-083",
    name: "Corgy",
    cost: "1",
    attribute: "Wisdom",
    power: "-",
    counter: "1000",
    color: "Black",
    type: "World Government",
    category: "CHARACTER",
    effect:
      "[On Play] Look at 5 cards from the top of your deck and trash up to 2 cards. Then, place the rest at the bottom of your deck in any order.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "C",
    image: "OP03-083.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-084",
    name: "Jerry",
    cost: "2",
    attribute: "Strike",
    power: "4000",
    counter: "1000",
    color: "Black",
    type: "CP6",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "C",
    image: "OP03-084.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-085",
    name: "Jabra",
    cost: "5",
    attribute: "Strike",
    power: "7000",
    counter: "1000",
    color: "Black",
    type: "CP9",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "C",
    image: "OP03-085.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-086",
    name: "Spandam",
    cost: "1",
    attribute: "Slash",
    power: "2000",
    counter: "1000",
    color: "Black",
    type: "CP9",
    category: "CHARACTER",
    effect:
      '[On Play] If your Leader\'s type includes "CP", look at 3 cards from the top of your deck; reveal up to 1 card with a type including "CP" other than [Spandam] and add it to your hand. Then, trash the rest.',
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "R",
    image: "OP03-086.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-086_p1",
    name: "Spandam",
    cost: "1",
    attribute: "Slash",
    power: "2000",
    counter: "1000",
    color: "Black",
    type: "CP9",
    category: "CHARACTER",
    effect:
      '[On Play] If your Leader\'s type includes "CP", look at 3 cards from the top of your deck; reveal up to 1 card with a type including "CP" other than [Spandam] and add it to your hand. Then, trash the rest.',
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "R",
    image: "OP03-086_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-087",
    name: "Nero",
    cost: "3",
    attribute: "Ranged",
    power: "5000",
    counter: "1000",
    color: "Black",
    type: "CP9",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "C",
    image: "OP03-087.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-088",
    name: "Fukurou",
    cost: "3",
    attribute: "Strike",
    power: "3000",
    counter: "1000",
    color: "Black",
    type: "CP9",
    category: "CHARACTER",
    effect: "This Character cannot be K.O.'d by effects.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "UC",
    image: "OP03-088.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-089",
    name: "Brannew",
    cost: "2",
    attribute: "Wisdom",
    power: "3000",
    counter: "1000",
    color: "Black",
    type: "Navy",
    category: "CHARACTER",
    effect:
      "[On Play] Look at 3 cards from the top of your deck; reveal up to 1 {Navy} type card other than [Brannew] and add it to your hand. Then, trash the rest.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "R",
    image: "OP03-089.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-090",
    name: "Blueno",
    cost: "5",
    attribute: "Strike",
    power: "6000",
    counter: "1000",
    color: "Black",
    type: "CP9",
    category: "CHARACTER",
    effect: "[DON!! x1] This Character gains [Blocker].",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "R",
    image: "OP03-090.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-091",
    name: "Helmeppo",
    cost: "1",
    attribute: "Wisdom",
    power: "-",
    counter: "1000",
    color: "Black",
    type: "Navy",
    category: "CHARACTER",
    effect:
      "[On Play] Set the cost of up to 1 of your opponent's Characters with no base effect to 0 during this turn.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "C",
    image: "OP03-091.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-092",
    name: "Rob Lucci",
    cost: "6",
    attribute: "Strike",
    power: "7000",
    counter: "-",
    color: "Black",
    type: "CP9",
    category: "CHARACTER",
    effect:
      '[On Play] You may place 2 cards with a type including "CP" from your trash at the bottom of your deck in any order: This Character gains [Rush] during this turn.',
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "SR",
    image: "OP03-092.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-092_p1",
    name: "Rob Lucci",
    cost: "6",
    attribute: "Strike",
    power: "7000",
    counter: "-",
    color: "Black",
    type: "CP9",
    category: "CHARACTER",
    effect:
      '[On Play] You may place 2 cards with a type including "CP" from your trash at the bottom of your deck in any order: This Character gains [Rush] during this turn.',
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "SR",
    image: "OP03-092_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-093",
    name: "Wanze",
    cost: "2",
    attribute: "Slash",
    power: "4000",
    counter: "-",
    color: "Black",
    type: "CP7",
    category: "CHARACTER",
    effect:
      "[On Play] You may trash 1 card from your hand: If your Leader's type includes \"CP\", K.O. up to 1 of your opponent's Characters with a cost of 1 or less.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "UC",
    image: "OP03-093.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-094",
    name: "Air Door",
    cost: "4",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Black",
    type: "CP9",
    category: "EVENT",
    effect:
      '[Main] If your Leader\'s type includes "CP", look at 5 cards from the top of your deck; play up to 1 Character card with a type including "CP" and a cost of 5 or less. Then, trash the rest.',
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "UC",
    image: "OP03-094.png",
    life: "",
    trigger:
      "[Trigger] Play up to 1 black Character card with a cost of 3 or less from your trash.",
  },
  {
    number: "OP03-095",
    name: "Soap Sheep",
    cost: "1",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Black",
    type: "CP9",
    category: "EVENT",
    effect:
      "[Main] Give up to 2 of your opponent's Characters −2 cost during this turn.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "C",
    image: "OP03-095.png",
    life: "",
    trigger: "[Trigger] Your opponent trashes 1 card from their hand.",
  },
  {
    number: "OP03-096",
    name: "Tempest Kick Sky Slicer",
    cost: "2",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Black",
    type: "CP9",
    category: "EVENT",
    effect:
      "[Main] K.O. up to 1 of your opponent's Characters with a cost of 0 or your opponent's Stages with a cost of 3 or less.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "UC",
    image: "OP03-096.png",
    life: "",
    trigger: "[Trigger] Draw 2 cards.",
  },
  {
    number: "OP03-097",
    name: "Six King Pistol",
    cost: "-",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Black",
    type: "CP9",
    category: "EVENT",
    effect:
      "[Counter] You may trash 1 card from your hand: Up to 1 of your Leader or Character cards gains +3000 power during this battle.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "R",
    image: "OP03-097.png",
    life: "",
    trigger:
      "[Trigger] Draw 1 card. Then, K.O. up to 1 of your opponent's Characters with a cost of 1 or less.",
  },
  {
    number: "OP03-098",
    name: "Enies Lobby",
    cost: "2",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Black",
    type: "World Government",
    category: "STAGE",
    effect:
      "[Activate: Main] You may rest this Stage: If your Leader's type includes \"CP\", give up to 1 of your opponent's Characters −2 cost during this turn.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "C",
    image: "OP03-098.png",
    life: "",
    trigger: "[Trigger] Play this card.",
  },
  {
    number: "OP03-099",
    name: "Charlotte Katakuri",
    cost: "",
    attribute: "Strike",
    power: "5000",
    counter: "-",
    color: "Yellow",
    type: "Big Mom Pirates",
    category: "LEADER",
    effect:
      "[DON!! x1] [When Attacking] Look at up to 1 card from the top of your or your opponent's Life cards, and place it at the top or bottom of the Life cards. Then, this Leader gains +1000 power during this battle.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "L",
    image: "OP03-099.png",
    life: "5",
    trigger: "",
  },
  {
    number: "OP03-099_p1",
    name: "Charlotte Katakuri",
    cost: "",
    attribute: "Strike",
    power: "5000",
    counter: "-",
    color: "Yellow",
    type: "Big Mom Pirates",
    category: "LEADER",
    effect:
      "[DON!! x1] [When Attacking] Look at up to 1 card from the top of your or your opponent's Life cards, and place it at the top or bottom of the Life cards. Then, this Leader gains +1000 power during this battle.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "L",
    image: "OP03-099_p1.png",
    life: "5",
    trigger: "",
  },
  {
    number: "OP03-100",
    name: "Kingbaum",
    cost: "3",
    attribute: "Strike",
    power: "5000",
    counter: "-",
    color: "Yellow",
    type: "Big Mom Pirates/Homies",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "C",
    image: "OP03-100.png",
    life: "",
    trigger:
      "[Trigger] You may trash 1 card from the top or bottom of your Life cards: Play this card.",
  },
  {
    number: "OP03-101",
    name: "Camie",
    cost: "1",
    attribute: "Wisdom",
    power: "3000",
    counter: "1000",
    color: "Yellow",
    type: "Merfolk",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "C",
    image: "OP03-101.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-102",
    name: "Sanji",
    cost: "2",
    attribute: "Strike",
    power: "3000",
    counter: "2000",
    color: "Yellow",
    type: "The Vinsmoke Family",
    category: "CHARACTER",
    effect:
      "[DON!! x2] [When Attacking] You may add 1 card from the top or bottom of your Life cards to your hand: Add up to 1 card from the top of your deck to the top of your Life cards.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "R",
    image: "OP03-102.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-103",
    name: "Bobbin the Disposer",
    cost: "2",
    attribute: "Slash",
    power: "4000",
    counter: "1000",
    color: "Yellow",
    type: "Big Mom Pirates",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "C",
    image: "OP03-103.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-104",
    name: "Shirley",
    cost: "3",
    attribute: "Wisdom",
    power: "3000",
    counter: "1000",
    color: "Yellow",
    type: "Merfolk",
    category: "CHARACTER",
    effect:
      "[Blocker] (After your opponent declares an attack, you may rest this card to make it the new target of the attack.)",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "UC",
    image: "OP03-104.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-105",
    name: "Charlotte Oven",
    cost: "3",
    attribute: "Special",
    power: "4000",
    counter: "1000",
    color: "Yellow",
    type: "Big Mom Pirates",
    category: "CHARACTER",
    effect:
      "[DON!! x1] [When Attacking] You may trash 1 card with a [Trigger] from your hand: This Character gains +3000 power during this battle.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "UC",
    image: "OP03-105.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-106",
    name: "Charlotte Opera",
    cost: "4",
    attribute: "Special",
    power: "6000",
    counter: "1000",
    color: "Yellow",
    type: "Big Mom Pirates",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "C",
    image: "OP03-106.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-107",
    name: "Charlotte Galette",
    cost: "2",
    attribute: "Special",
    power: "2000",
    counter: "1000",
    color: "Yellow",
    type: "Big Mom Pirates",
    category: "CHARACTER",
    effect:
      "[Blocker] (After your opponent declares an attack, you may rest this card to make it the new target of the attack.)",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "C",
    image: "OP03-107.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-108",
    name: "Charlotte Cracker",
    cost: "4",
    attribute: "Slash",
    power: "5000",
    counter: "1000",
    color: "Yellow",
    type: "Big Mom Pirates",
    category: "CHARACTER",
    effect:
      "[DON!! x1] If you have less Life cards than your opponent, this Character gains [Double Attack] and +1000 power.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "SR",
    image: "OP03-108.png",
    life: "",
    trigger: "[Trigger] You may trash 1 card from your hand: Play this card.",
  },
  {
    number: "OP03-108_p1",
    name: "Charlotte Cracker",
    cost: "4",
    attribute: "Slash",
    power: "5000",
    counter: "1000",
    color: "Yellow",
    type: "Big Mom Pirates",
    category: "CHARACTER",
    effect:
      "[DON!! x1] If you have less Life cards than your opponent, this Character gains [Double Attack] and +1000 power.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "SR",
    image: "OP03-108_p1.png",
    life: "",
    trigger: "[Trigger] You may trash 1 card from your hand: Play this card.",
  },
  {
    number: "OP03-109",
    name: "Charlotte Chiffon",
    cost: "2",
    attribute: "Wisdom",
    power: "3000",
    counter: "-",
    color: "Yellow",
    type: "Big Mom Pirates",
    category: "CHARACTER",
    effect:
      "[On Play] You may trash 1 card from the top or bottom of your Life cards: Add up to 1 card from the top of your deck to the top of your Life cards.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "C",
    image: "OP03-109.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-110",
    name: "Charlotte Smoothie",
    cost: "4",
    attribute: "Special",
    power: "5000",
    counter: "1000",
    color: "Yellow",
    type: "Big Mom Pirates",
    category: "CHARACTER",
    effect:
      "[When Attacking] You may add 1 card from the top or bottom of your Life cards to your hand: This Character gains +2000 power during this battle.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "R",
    image: "OP03-110.png",
    life: "",
    trigger: "[Trigger] You may trash 1 card from your hand: Play this card.",
  },
  {
    number: "OP03-111",
    name: "Charlotte Praline",
    cost: "3",
    attribute: "Wisdom",
    power: "5000",
    counter: "1000",
    color: "Yellow",
    type: "Merfolk/The Sun Pirates",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "C",
    image: "OP03-111.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-112",
    name: "Charlotte Pudding",
    cost: "1",
    attribute: "Wisdom",
    power: "2000",
    counter: "1000",
    color: "Yellow",
    type: "Big Mom Pirates",
    category: "CHARACTER",
    effect:
      "[On Play] Look at 4 cards from the top of your deck; reveal up to 1 [Sanji] or {Big Mom Pirates} type card other than [Charlotte Pudding] and add it to your hand. Then, place the rest at the bottom of your deck in any order.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "R",
    image: "OP03-112.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-112_p1",
    name: "Charlotte Pudding",
    cost: "1",
    attribute: "Wisdom",
    power: "2000",
    counter: "1000",
    color: "Yellow",
    type: "Big Mom Pirates",
    category: "CHARACTER",
    effect:
      "[On Play] Look at 4 cards from the top of your deck; reveal up to 1 [Sanji] or {Big Mom Pirates} type card other than [Charlotte Pudding] and add it to your hand. Then, place the rest at the bottom of your deck in any order.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "R",
    image: "OP03-112_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-113",
    name: "Charlotte Perospero",
    cost: "3",
    attribute: "Special",
    power: "5000",
    counter: "-",
    color: "Yellow",
    type: "Big Mom Pirates",
    category: "CHARACTER",
    effect:
      "[On K.O.] Look at 3 cards from the top of your deck; reveal up to 1 {Big Mom Pirates} type card and add it to your hand. Then, place the rest at the bottom of your deck in any order.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "SR",
    image: "OP03-113.png",
    life: "",
    trigger: "[Trigger] You may trash 1 card from your hand: Play this card.",
  },
  {
    number: "OP03-113_p1",
    name: "Charlotte Perospero",
    cost: "3",
    attribute: "Special",
    power: "5000",
    counter: "-",
    color: "Yellow",
    type: "Big Mom Pirates",
    category: "CHARACTER",
    effect:
      "[On K.O.] Look at 3 cards from the top of your deck; reveal up to 1 {Big Mom Pirates} type card and add it to your hand. Then, place the rest at the bottom of your deck in any order.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "SR",
    image: "OP03-113_p1.png",
    life: "",
    trigger: "[Trigger] You may trash 1 card from your hand: Play this card.",
  },
  {
    number: "OP03-114",
    name: "Charlotte Linlin",
    cost: "10",
    attribute: "Special",
    power: "12000",
    counter: "-",
    color: "Yellow",
    type: "The Four Emperors/Big Mom Pirates",
    category: "CHARACTER",
    effect:
      "[On Play] If your Leader has the {Big Mom Pirates} type, add up to 1 card from the top of your deck to the top of your Life cards. Then, trash up to 1 card from the top of your opponent's Life cards.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "SR",
    image: "OP03-114.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-114_p1",
    name: "Charlotte Linlin",
    cost: "10",
    attribute: "Special",
    power: "12000",
    counter: "-",
    color: "Yellow",
    type: "The Four Emperors/Big Mom Pirates",
    category: "CHARACTER",
    effect:
      "[On Play] If your Leader has the {Big Mom Pirates} type, add up to 1 card from the top of your deck to the top of your Life cards. Then, trash up to 1 card from the top of your opponent's Life cards.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "SR",
    image: "OP03-114_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-115",
    name: "Streusen",
    cost: "1",
    attribute: "Slash",
    power: "1000",
    counter: "2000",
    color: "Yellow",
    type: "Big Mom Pirates",
    category: "CHARACTER",
    effect:
      "[On Play] You may trash 1 card with a [Trigger] from your hand: K.O. up to 1 of your opponent's Characters with a cost of 1 or less.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "R",
    image: "OP03-115.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-116",
    name: "Shirahoshi",
    cost: "5",
    attribute: "Wisdom",
    power: "-",
    counter: "1000",
    color: "Yellow",
    type: "Merfolk",
    category: "CHARACTER",
    effect: "[On Play] Draw 3 cards and trash 2 cards from your hand.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "UC",
    image: "OP03-116.png",
    life: "",
    trigger: "[Trigger] Play this card.",
  },
  {
    number: "OP03-117",
    name: "Napoleon",
    cost: "3",
    attribute: "Slash",
    power: "3000",
    counter: "1000",
    color: "Yellow",
    type: "Big Mom Pirates/Homies",
    category: "CHARACTER",
    effect:
      "[Activate: Main] You may rest this Character: Up to 1 of your [Charlotte Linlin] cards gains +1000 power until the start of your next turn.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "UC",
    image: "OP03-117.png",
    life: "",
    trigger: "[Trigger] Play this card.",
  },
  {
    number: "OP03-118",
    name: "Ikoku Sovereignty",
    cost: "2",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Yellow",
    type: "The Four Emperors/Big Mom Pirates",
    category: "EVENT",
    effect:
      "[Counter] Up to 1 of your Leader or Character cards gains +5000 power during this battle.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "UC",
    image: "OP03-118.png",
    life: "",
    trigger:
      "[Trigger] You may trash 2 cards from your hand: Add up to 1 card from the top of your deck to the top of your Life cards.",
  },
  {
    number: "OP03-119",
    name: "Buzz Cut Mochi",
    cost: "2",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Yellow",
    type: "Big Mom Pirates",
    category: "EVENT",
    effect:
      "[Main] If you have less Life cards than your opponent, K.O. up to 1 of your opponent's Characters with a cost of 4 or less.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "R",
    image: "OP03-119.png",
    life: "",
    trigger:
      "[Trigger] Play up to 1 Character card with a cost of 4 or less and a [Trigger] from your hand.",
  },
  {
    number: "OP03-120",
    name: "Tropical Torment",
    cost: "3",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Yellow",
    type: "Big Mom Pirates",
    category: "EVENT",
    effect:
      "[Main] If your opponent has 4 or more Life cards, trash up to 1 card from the top of your opponent's Life cards.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "C",
    image: "OP03-120.png",
    life: "",
    trigger: "[Trigger] Activate this card's [Main] effect.",
  },
  {
    number: "OP03-121",
    name: "Thunder Bolt",
    cost: "2",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Yellow",
    type: "The Four Emperors/Big Mom Pirates",
    category: "EVENT",
    effect:
      "[Main] You may trash 1 card from the top of your Life cards: K.O. up to 1 of your opponent's Characters with a cost of 5 or less.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "C",
    image: "OP03-121.png",
    life: "",
    trigger:
      "[Trigger] K.O. up to 1 of your opponent's Characters with a cost of 5 or less.",
  },
  {
    number: "OP03-122",
    name: "Sogeking",
    cost: "7",
    attribute: "Ranged",
    power: "6000",
    counter: "1000",
    color: "Blue",
    type: "Sniper Island",
    category: "CHARACTER",
    effect: "Also treat this card's name as [Usopp] according to the rules.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "SEC",
    image: "OP03-122.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-122_p1",
    name: "Sogeking",
    cost: "7",
    attribute: "Ranged",
    power: "6000",
    counter: "1000",
    color: "Blue",
    type: "Sniper Island",
    category: "CHARACTER",
    effect: "Also treat this card's name as [Usopp] according to the rules.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "SEC",
    image: "OP03-122_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-122_p2",
    name: "Sogeking",
    cost: "7",
    attribute: "Ranged",
    power: "6000",
    counter: "1000",
    color: "Blue",
    type: "Sniper Island",
    category: "CHARACTER",
    effect: "Also treat this card's name as [Usopp] according to the rules.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "SEC",
    image: "OP03-122_p2.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-123",
    name: "Charlotte Katakuri",
    cost: "8",
    attribute: "Strike",
    power: "8000",
    counter: "-",
    color: "Yellow",
    type: "Big Mom Pirates",
    category: "CHARACTER",
    effect:
      "[On Play] Add up to 1 Character with a cost of 8 or less to the top or bottom of the owner's Life cards face-up.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "SEC",
    image: "OP03-123.png",
    life: "",
    trigger: "",
  },
  {
    number: "OP03-123_p1",
    name: "Charlotte Katakuri",
    cost: "8",
    attribute: "Strike",
    power: "8000",
    counter: "-",
    color: "Yellow",
    type: "Big Mom Pirates",
    category: "CHARACTER",
    effect:
      "[On Play] Add up to 1 Character with a cost of 8 or less to the top or bottom of the owner's Life cards face-up.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "SEC",
    image: "OP03-123_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "P-001",
    name: "Monkey.D.Luffy",
    cost: "6",
    attribute: "Strike",
    power: "7000",
    counter: "-",
    color: "Red",
    type: "Supernovas/Straw Hat Crew",
    category: "CHARACTER",
    effect: "[DON!! x2] This Character gains [Rush].",
    cardSet: "Included in Promotion Pack 2022",
    rarity: "P",
    image: "P-001.png",
    life: "",
    trigger: "",
  },
  {
    number: "P-001_p1",
    name: "Monkey.D.Luffy",
    cost: "6",
    attribute: "Strike",
    power: "7000",
    counter: "-",
    color: "Red",
    type: "Supernovas/Straw Hat Crew",
    category: "CHARACTER",
    effect: "[DON!! x2] This Character gains [Rush].",
    cardSet: "Super Pre-Release",
    rarity: "P",
    image: "P-001_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "P-001_p2",
    name: "Monkey.D.Luffy",
    cost: "6",
    attribute: "Strike",
    power: "7000",
    counter: "-",
    color: "Red",
    type: "Supernovas/Straw Hat Crew",
    category: "CHARACTER",
    effect: "[DON!! x2] This Character gains [Rush].",
    cardSet: "Super Pre-Release",
    rarity: "P",
    image: "P-001_p2.png",
    life: "",
    trigger: "",
  },
  {
    number: "P-002",
    name: "I Smell Adventure!!!",
    cost: "1",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Red",
    type: "Straw Hat Crew",
    category: "EVENT",
    effect:
      "[Main] Return all cards in your hand to your deck and shuffle your deck. Then, draw cards equal to the number you returned to your deck.",
    cardSet: "Included in Promotion Pack 2022",
    rarity: "P",
    image: "P-002.png",
    life: "",
    trigger: "[Trigger] Activate this card's [Main] effect.",
  },
  {
    number: "P-003",
    name: 'Eustass"Captain"Kid',
    cost: "3",
    attribute: "Special",
    power: "4000",
    counter: "1000",
    color: "Green",
    type: "Supernovas/Kid Pirates",
    category: "CHARACTER",
    effect: "[DON!! x2] This Character gains [Double Attack].",
    cardSet: "Included in Promotion Pack 2022",
    rarity: "P",
    image: "P-003.png",
    life: "",
    trigger: "",
  },
  {
    number: "P-004",
    name: "Crocodile",
    cost: "4",
    attribute: "Special",
    power: "5000",
    counter: "1000",
    color: "Blue",
    type: "The Seven Warlords of the Sea/Baroque Works",
    category: "CHARACTER",
    effect: "[DON!! x1] This Character gains [Blocker].",
    cardSet: "Included in Promotion Pack 2022",
    rarity: "P",
    image: "P-004.png",
    life: "",
    trigger: "",
  },
  {
    number: "P-005",
    name: "Kaido",
    cost: "7",
    attribute: "Strike",
    power: "8000",
    counter: "-",
    color: "Purple",
    type: "The Four Emperors/Animal Kingdom Pirates",
    category: "CHARACTER",
    effect:
      "[Activate: Main]  DON!! −2 (You may return the specified number of DON!! cards from your field to your DON!! deck.): This Character gains [Banish] during this turn.",
    cardSet: "Included in Promotion Pack 2022",
    rarity: "P",
    image: "P-005.png",
    life: "",
    trigger: "",
  },
  {
    number: "P-006",
    name: "Monkey.D.Luffy",
    cost: "3",
    attribute: "Strike",
    power: "3000",
    counter: "2000",
    color: "Red",
    type: "Supernovas/Straw Hat Crew",
    category: "CHARACTER",
    effect: "[DON!! x2] [Your Turn] This Character gains +2000 power.",
    cardSet: "Tournament Pack Vol.1",
    rarity: "P",
    image: "P-006.png",
    life: "",
    trigger: "",
  },
  {
    number: "P-007",
    name: "Monkey.D.Luffy",
    cost: "4",
    attribute: "Strike",
    power: "5000",
    counter: "1000",
    color: "Red",
    type: "Supernovas/Straw Hat Crew",
    category: "CHARACTER",
    effect:
      "[DON!! x1] This Character cannot be K.O.'d in battle by ＜Strike＞ attribute Leaders or Characters.",
    cardSet: "Tournament Pack Vol.1",
    rarity: "P",
    image: "P-007.png",
    life: "",
    trigger: "",
  },
  {
    number: "P-008",
    name: "Yamato",
    cost: "3",
    attribute: "Strike",
    power: "5000",
    counter: "-",
    color: "Green",
    type: "Land of Wano",
    category: "CHARACTER",
    effect:
      "[Activate: Main] You may rest this Character: Rest 1 of your opponent's Characters with a cost of 2 or less.",
    cardSet: "Tournament Pack Vol.1",
    rarity: "P",
    image: "P-008.png",
    life: "",
    trigger: "",
  },
  {
    number: "P-009",
    name: "Trafalgar Law",
    cost: "6",
    attribute: "Slash",
    power: "7000",
    counter: "-",
    color: "Blue",
    type: "Supernovas/Heart Pirates",
    category: "CHARACTER",
    effect:
      "[On Play] If your opponent has 6 or more cards in their hand, your opponent adds 1 card from their Life area to their hand.",
    cardSet: "Tournament Pack Vol.1",
    rarity: "P",
    image: "P-009.png",
    life: "",
    trigger: "",
  },
  {
    number: "P-010",
    name: "Kaido",
    cost: "8",
    attribute: "Special",
    power: "9000",
    counter: "-",
    color: "Purple",
    type: "The Four Emperors/Animal Kingdom Pirates",
    category: "CHARACTER",
    effect:
      "[End of Your Turn] Add 1 DON!! card from your DON!! deck and set it as active.",
    cardSet: "Tournament Pack Vol.1",
    rarity: "P",
    image: "P-010.png",
    life: "",
    trigger: "",
  },
  {
    number: "P-011",
    name: "Uta",
    cost: "",
    attribute: "Special",
    power: "5000",
    counter: "-",
    color: "Red",
    type: "FILM",
    category: "LEADER",
    effect:
      "[Activate: Main] [Once Per Turn] ① (You may rest the specified number of DON!! cards in your cost area.): Up to 1 of your Characters with no base effect gains +2000 power during this turn.",
    cardSet: "Included in FILM RED Promotion Card Set",
    rarity: "L",
    image: "P-011.png",
    life: "5",
    trigger: "",
  },
  {
    number: "P-012",
    name: "Jellyfish Pirates",
    cost: "3",
    attribute: "Special",
    power: "5000",
    counter: "1000",
    color: "Red",
    type: "FILM/Jellyfish Pirates",
    category: "CHARACTER",
    effect: "-",
    cardSet: "Included in FILM RED Promotion Card Set",
    rarity: "P",
    image: "P-012.png",
    life: "",
    trigger: "",
  },
  {
    number: "P-013",
    name: "Gordon",
    cost: "1",
    attribute: "Wisdom",
    power: "2000",
    counter: "-",
    color: "Red",
    type: "FILM",
    category: "CHARACTER",
    effect:
      "[Activate: Main] You may place this Character at the bottom of the owner's deck: Give up to 1 of your opponent's Characters −3000 power during this turn.",
    cardSet: "Included in FILM RED Promotion Card Set",
    rarity: "P",
    image: "P-013.png",
    life: "",
    trigger: "",
  },
  {
    number: "P-014",
    name: "Koby",
    cost: "3",
    attribute: "Strike",
    power: "3000",
    counter: "-",
    color: "Red",
    type: "FILM/Navy",
    category: "CHARACTER",
    effect:
      "[Blocker] (After your opponent declares an attack, you may rest this card to make it the new target of the attack.)",
    cardSet: "Included in FILM RED Promotion Card Set",
    rarity: "P",
    image: "P-014.png",
    life: "",
    trigger: "[Trigger] Play this card.",
  },
  {
    number: "P-015",
    name: "Sunny-Kun",
    cost: "1",
    attribute: "Wisdom",
    power: "3000",
    counter: "1000",
    color: "Red",
    type: "FILM/Straw Hat Crew",
    category: "CHARACTER",
    effect: "-",
    cardSet: "Included in FILM RED Promotion Card Set",
    rarity: "P",
    image: "P-015.png",
    life: "",
    trigger: "",
  },
  {
    number: "P-016",
    name: "Shanks",
    cost: "8",
    attribute: "Slash",
    power: "10000",
    counter: "1000",
    color: "Red",
    type: "FILM/The Four Emperors/Red-Haired Pirates",
    category: "CHARACTER",
    effect: "-",
    cardSet: "Included in FILM RED Promotion Card Set",
    rarity: "P",
    image: "P-016.png",
    life: "",
    trigger: "",
  },
  {
    number: "P-016_p1",
    name: "Shanks",
    cost: "8",
    attribute: "Slash",
    power: "10000",
    counter: "1000",
    color: "Red",
    type: "FILM/The Four Emperors/Red-Haired Pirates",
    category: "CHARACTER",
    effect: "-",
    cardSet: "Premium Card Collection -FILM RED Edition-",
    rarity: "P",
    image: "P-016_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "P-017",
    name: "Trafalgar Law",
    cost: "4",
    attribute: "Slash",
    power: "5000",
    counter: "1000",
    color: "Red",
    type: "FILM/Supernovas/Heart Pirates",
    category: "CHARACTER",
    effect:
      "[On Play] Give up to 1 of your opponent's Characters −2000 power during this turn.",
    cardSet: "Included in FILM RED Promotion Card Set",
    rarity: "P",
    image: "P-017.png",
    life: "",
    trigger: "",
  },
  {
    number: "P-018",
    name: "Bartolomeo",
    cost: "2",
    attribute: "Strike",
    power: "3000",
    counter: "-",
    color: "Red",
    type: "FILM/Supernovas/Barto Club",
    category: "CHARACTER",
    effect:
      "[Blocker] (After your opponent declares an attack, you may rest this card to make it the new target of the attack.)",
    cardSet: "Included in FILM RED Promotion Card Set",
    rarity: "P",
    image: "P-018.png",
    life: "",
    trigger: "",
  },
  {
    number: "P-019",
    name: "Bepo",
    cost: "3",
    attribute: "Strike",
    power: "2000",
    counter: "2000",
    color: "Red",
    type: "FILM/Minks/Heart Pirates",
    category: "CHARACTER",
    effect:
      "[DON!! x1] [When Attacking] K.O. up to 1 of your opponent's Characters with 3000 power or less.",
    cardSet: "Included in FILM RED Promotion Card Set",
    rarity: "P",
    image: "P-019.png",
    life: "",
    trigger: "",
  },
  {
    number: "P-020",
    name: "Helmeppo",
    cost: "1",
    attribute: "Slash",
    power: "2000",
    counter: "1000",
    color: "Red",
    type: "FILM/Navy",
    category: "CHARACTER",
    effect:
      "[On Play] Up to 1 of your Leader or Character cards gains +1000 power during this turn.",
    cardSet: "Included in FILM RED Promotion Card Set",
    rarity: "P",
    image: "P-020.png",
    life: "",
    trigger: "",
  },
  {
    number: "P-021",
    name: "Benn.Beckman",
    cost: "7",
    attribute: "Ranged",
    power: "9000",
    counter: "1000",
    color: "Red",
    type: "FILM/Red-Haired Pirates",
    category: "CHARACTER",
    effect: "-",
    cardSet: "Included in FILM RED Promotion Card Set",
    rarity: "P",
    image: "P-021.png",
    life: "",
    trigger: "",
  },
  {
    number: "P-022",
    name: "Monkey.D.Luffy",
    cost: "4",
    attribute: "Strike",
    power: "6000",
    counter: "1000",
    color: "Red",
    type: "FILM/Supernovas/Straw Hat Crew",
    category: "CHARACTER",
    effect: "-",
    cardSet: "Included in FILM RED Promotion Card Set",
    rarity: "P",
    image: "P-022.png",
    life: "",
    trigger: "",
  },
  {
    number: "P-022_p1",
    name: "Monkey.D.Luffy",
    cost: "4",
    attribute: "Strike",
    power: "6000",
    counter: "1000",
    color: "Red",
    type: "FILM/Supernovas/Straw Hat Crew",
    category: "CHARACTER",
    effect: "-",
    cardSet: "Premium Card Collection -FILM RED Edition-",
    rarity: "P",
    image: "P-022_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "P-023",
    name: "Yasopp",
    cost: "6",
    attribute: "Ranged",
    power: "8000",
    counter: "1000",
    color: "Red",
    type: "FILM/Red-Haired Pirates",
    category: "CHARACTER",
    effect: "-",
    cardSet: "Included in FILM RED Promotion Card Set",
    rarity: "P",
    image: "P-023.png",
    life: "",
    trigger: "",
  },
  {
    number: "P-024",
    name: "I'm Gonna Be King of the Pirates!!",
    cost: "2",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Green",
    type: "Supernovas/Straw Hat Crew",
    category: "EVENT",
    effect:
      "[Main] Your Leader gains +1000 power for each of your Characters during this turn.",
    cardSet: "Included in Pirates Party Card Vol.1",
    rarity: "P",
    image: "P-024.png",
    life: "",
    trigger:
      "[Trigger] Up to 1 of your Leader or Character cards gains +1000 power during this turn.",
  },
  {
    number: "P-026",
    name: "Morgan",
    cost: "4",
    attribute: "Slash",
    power: "5000",
    counter: "1000",
    color: "Black",
    type: "Navy",
    category: "CHARACTER",
    effect:
      "[When Attacking] Give up to 1 of your opponent's Characters −3 cost during this turn.",
    cardSet: "Included in Pirates Party Card Vol.2",
    rarity: "P",
    image: "P-026.png",
    life: "",
    trigger: "",
  },
  {
    number: "P-028",
    name: "Portgas.D.Ace",
    cost: "5",
    attribute: "Special",
    power: "6000",
    counter: "-",
    color: "Red",
    type: "Whitebeard Pirates",
    category: "CHARACTER",
    effect: "[Double Attack] (This card deals 2 damage.)",
    cardSet: "Included in Event Pack Vol.1",
    rarity: "P",
    image: "P-028.png",
    life: "",
    trigger: "",
  },
  {
    number: "P-029",
    name: "Bartolomeo",
    cost: "2",
    attribute: "Special",
    power: "3000",
    counter: "1000",
    color: "Green",
    type: "FILM/Supernovas/Barto Club",
    category: "CHARACTER",
    effect:
      "[End of Your Turn] You may rest this Character: Set up to 1 of your {FILM} type Characters other than [Bartolomeo] as active.",
    cardSet: "Included in Event Pack Vol.1",
    rarity: "P",
    image: "P-029.png",
    life: "",
    trigger: "",
  },
  {
    number: "P-030",
    name: "Jinbe",
    cost: "4",
    attribute: "Strike",
    power: "5000",
    counter: "1000",
    color: "Blue",
    type: "Fish-Man/The Seven Warlords of the Sea/The Sun Pirates",
    category: "CHARACTER",
    effect:
      "[On K.O.] Place up to 1 Character with a cost of 3 or less at the bottom of the owner's deck.",
    cardSet: "Included in Event Pack Vol.1",
    rarity: "P",
    image: "P-030.png",
    life: "",
    trigger: "",
  },
  {
    number: "P-031",
    name: "Uta",
    cost: "5",
    attribute: "Special",
    power: "6000",
    counter: "-",
    color: "Purple",
    type: "FILM",
    category: "CHARACTER",
    effect:
      "[On Play] Add up to 1 DON!! card from your DON!! deck and rest it.",
    cardSet: "Included in Event Pack Vol.1",
    rarity: "P",
    image: "P-031.png",
    life: "",
    trigger: "",
  },
  {
    number: "P-032",
    name: "Sengoku",
    cost: "5",
    attribute: "Wisdom",
    power: "6000",
    counter: "-",
    color: "Black",
    type: "Navy",
    category: "CHARACTER",
    effect:
      "[DON!! x1] [Your Turn] Give all of your opponent's Characters −2 cost.",
    cardSet: "Included in Event Pack Vol.1",
    rarity: "P",
    image: "P-032.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST01-001",
    name: "Monkey.D.Luffy",
    cost: "",
    attribute: "Strike",
    power: "5000",
    counter: "-",
    color: "Red",
    type: "Supernovas/Straw Hat Crew",
    category: "LEADER",
    effect:
      "[Activate: Main] [Once Per Turn] Give this Leader or 1 of your Characters up to 1 rested DON!! card.",
    cardSet: "-Straw Hat Crew-[ST-01]",
    rarity: "L",
    image: "ST01-001.png",
    life: "5",
    trigger: "",
  },
  {
    number: "ST01-002",
    name: "Usopp",
    cost: "2",
    attribute: "Ranged",
    power: "2000",
    counter: "1000",
    color: "Red",
    type: "Straw Hat Crew",
    category: "CHARACTER",
    effect:
      "[DON!! x2] [When Attacking] Your opponent cannot activate a [Blocker] Character that has 5000 or more power during this battle.",
    cardSet: "-Straw Hat Crew-[ST-01]",
    rarity: "C",
    image: "ST01-002.png",
    life: "",
    trigger: "[Trigger] Play this card.",
  },
  {
    number: "ST01-002_p1",
    name: "Usopp",
    cost: "2",
    attribute: "Ranged",
    power: "2000",
    counter: "1000",
    color: "Red",
    type: "Straw Hat Crew",
    category: "CHARACTER",
    effect:
      "[DON!! x2] [When Attacking] Your opponent cannot activate a [Blocker] Character that has 5000 or more power during this battle.",
    cardSet: "Premium Card Collection -FILM RED Edition-",
    rarity: "C",
    image: "ST01-002_p1.png",
    life: "",
    trigger: "[Trigger] Play this card.",
  },
  {
    number: "ST01-003",
    name: "Karoo",
    cost: "1",
    attribute: "Strike",
    power: "3000",
    counter: "1000",
    color: "Red",
    type: "Animal/Alabasta",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-Straw Hat Crew-[ST-01]",
    rarity: "C",
    image: "ST01-003.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST01-004",
    name: "Sanji",
    cost: "2",
    attribute: "Strike",
    power: "4000",
    counter: "-",
    color: "Red",
    type: "Straw Hat Crew",
    category: "CHARACTER",
    effect: "[DON!! x2] This Character gains [Rush].",
    cardSet: "-Straw Hat Crew-[ST-01]",
    rarity: "C",
    image: "ST01-004.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST01-004_p1",
    name: "Sanji",
    cost: "2",
    attribute: "Strike",
    power: "4000",
    counter: "-",
    color: "Red",
    type: "Straw Hat Crew",
    category: "CHARACTER",
    effect: "[DON!! x2] This Character gains [Rush].",
    cardSet: "Included in Online Regional Participation Pack Vol.1",
    rarity: "C",
    image: "ST01-004_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST01-004_p2",
    name: "Sanji",
    cost: "2",
    attribute: "Strike",
    power: "4000",
    counter: "-",
    color: "Red",
    type: "Straw Hat Crew",
    category: "CHARACTER",
    effect: "[DON!! x2] This Character gains [Rush].",
    cardSet: "Premium Card Collection -FILM RED Edition-",
    rarity: "C",
    image: "ST01-004_p2.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST01-005",
    name: "Jinbe",
    cost: "3",
    attribute: "Strike",
    power: "5000",
    counter: "-",
    color: "Red",
    type: "Fish-Man/Straw Hat Crew",
    category: "CHARACTER",
    effect:
      "[DON!! x1] [When Attacking] Up to 1 of your Leader or Character cards other than this card gains +1000 power during this turn.",
    cardSet: "-Straw Hat Crew-[ST-01]",
    rarity: "C",
    image: "ST01-005.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST01-006",
    name: "Tony Tony.Chopper",
    cost: "1",
    attribute: "Strike",
    power: "1000",
    counter: "-",
    color: "Red",
    type: "Animal/Straw Hat Crew",
    category: "CHARACTER",
    effect:
      "[Blocker] (After your opponent declares an attack, you may rest this card to make it the new target of the attack.)",
    cardSet: "-Straw Hat Crew-[ST-01]",
    rarity: "C",
    image: "ST01-006.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST01-006_p1",
    name: "Tony Tony.Chopper",
    cost: "1",
    attribute: "Strike",
    power: "1000",
    counter: "-",
    color: "Red",
    type: "Animal/Straw Hat Crew",
    category: "CHARACTER",
    effect:
      "[Blocker] (After your opponent declares an attack, you may rest this card to make it the new target of the attack.)",
    cardSet: "Premium Card Collection -FILM RED Edition-",
    rarity: "C",
    image: "ST01-006_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST01-007",
    name: "Nami",
    cost: "1",
    attribute: "Special",
    power: "1000",
    counter: "1000",
    color: "Red",
    type: "Straw Hat Crew",
    category: "CHARACTER",
    effect:
      "[Activate: Main] [Once Per Turn] Give up to 1 rested DON!! card to your Leader or 1 of your Characters.",
    cardSet: "-Straw Hat Crew-[ST-01]",
    rarity: "C",
    image: "ST01-007.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST01-007_p1",
    name: "Nami",
    cost: "1",
    attribute: "Special",
    power: "1000",
    counter: "1000",
    color: "Red",
    type: "Straw Hat Crew",
    category: "CHARACTER",
    effect:
      "[Activate: Main] [Once Per Turn] Give up to 1 rested DON!! card to your Leader or 1 of your Characters.",
    cardSet: "Premium Card Collection -FILM RED Edition-",
    rarity: "C",
    image: "ST01-007_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST01-008",
    name: "Nico Robin",
    cost: "3",
    attribute: "Wisdom",
    power: "5000",
    counter: "1000",
    color: "Red",
    type: "Straw Hat Crew",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-Straw Hat Crew-[ST-01]",
    rarity: "C",
    image: "ST01-008.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST01-009",
    name: "Nefeltari Vivi",
    cost: "2",
    attribute: "Slash",
    power: "4000",
    counter: "1000",
    color: "Red",
    type: "Alabasta",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-Straw Hat Crew-[ST-01]",
    rarity: "C",
    image: "ST01-009.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST01-010",
    name: "Franky",
    cost: "4",
    attribute: "Strike",
    power: "6000",
    counter: "1000",
    color: "Red",
    type: "Straw Hat Crew",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-Straw Hat Crew-[ST-01]",
    rarity: "C",
    image: "ST01-010.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST01-011",
    name: "Brook",
    cost: "2",
    attribute: "Slash",
    power: "3000",
    counter: "2000",
    color: "Red",
    type: "Straw Hat Crew",
    category: "CHARACTER",
    effect:
      "[On Play] Give up to 2 rested DON!! cards to your Leader or 1 of your Characters.",
    cardSet: "-Straw Hat Crew-[ST-01]",
    rarity: "C",
    image: "ST01-011.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST01-011_p1",
    name: "Brook",
    cost: "2",
    attribute: "Slash",
    power: "3000",
    counter: "2000",
    color: "Red",
    type: "Straw Hat Crew",
    category: "CHARACTER",
    effect:
      "[On Play] Give up to 2 rested DON!! cards to your Leader or 1 of your Characters.",
    cardSet: "Premium Card Collection -FILM RED Edition-",
    rarity: "C",
    image: "ST01-011_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST01-012",
    name: "Monkey.D.Luffy",
    cost: "5",
    attribute: "Strike",
    power: "6000",
    counter: "-",
    color: "Red",
    type: "Supernovas/Straw Hat Crew",
    category: "CHARACTER",
    effect: "[Rush] (This card can attack on the turn in which it is played.)",
    cardSet: "-Straw Hat Crew-[ST-01]",
    rarity: "SR",
    image: "ST01-012.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST01-012_p1",
    name: "Monkey.D.Luffy",
    cost: "5",
    attribute: "Strike",
    power: "6000",
    counter: "-",
    color: "Red",
    type: "Supernovas/Straw Hat Crew",
    category: "CHARACTER",
    effect: "[Rush] (This card can attack on the turn in which it is played.)",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "SP CARD",
    image: "ST01-012_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST01-013",
    name: "Roronoa Zoro",
    cost: "3",
    attribute: "Slash",
    power: "5000",
    counter: "-",
    color: "Red",
    type: "Supernovas/Straw Hat Crew",
    category: "CHARACTER",
    effect: "[DON!! x1] This Character gains +1000 power.",
    cardSet: "-Straw Hat Crew-[ST-01]",
    rarity: "SR",
    image: "ST01-013.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST01-013_p1",
    name: "Roronoa Zoro",
    cost: "3",
    attribute: "Slash",
    power: "5000",
    counter: "-",
    color: "Red",
    type: "Supernovas/Straw Hat Crew",
    category: "CHARACTER",
    effect: "[DON!! x1] This Character gains +1000 power.",
    cardSet: "Premium Card Collection -FILM RED Edition-",
    rarity: "SR",
    image: "ST01-013_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST01-014",
    name: "Guard Point",
    cost: "1",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Red",
    type: "Animal/Straw Hat Crew",
    category: "EVENT",
    effect:
      "[Counter] Up to 1 of your Leader or Character cards gains +3000 power during this battle.",
    cardSet: "-Straw Hat Crew-[ST-01]",
    rarity: "C",
    image: "ST01-014.png",
    life: "",
    trigger:
      "[Trigger] Up to 1 of your Leader or Character cards gains +1000 power during this turn.",
  },
  {
    number: "ST01-015",
    name: "Gum-Gum Jet Pistol",
    cost: "4",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Red",
    type: "Supernovas/Straw Hat Crew",
    category: "EVENT",
    effect:
      "[Main] K.O. up to 1 of your opponent's Characters with 6000 power or less.",
    cardSet: "-Straw Hat Crew-[ST-01]",
    rarity: "C",
    image: "ST01-015.png",
    life: "",
    trigger: "[Trigger] Activate this card's [Main] effect.",
  },
  {
    number: "ST01-016",
    name: "Diable Jambe",
    cost: "1",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Red",
    type: "Straw Hat Crew",
    category: "EVENT",
    effect:
      "[Main] Select up to 1 of your {Straw Hat Crew} type Leader or Character cards. Your opponent cannot activate [Blocker] if that Leader or Character attacks during this turn.",
    cardSet: "-Straw Hat Crew-[ST-01]",
    rarity: "C",
    image: "ST01-016.png",
    life: "",
    trigger:
      "[Trigger] K.O. up to 1 of your opponent's [Blocker] Characters with a cost of 3 or less.",
  },
  {
    number: "ST01-017",
    name: "Thousand Sunny",
    cost: "2",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Red",
    type: "Straw Hat Crew",
    category: "STAGE",
    effect:
      "[Activate: Main] You may rest this Stage: Up to 1 {Straw Hat Crew} type Leader or Character card on your field gains +1000 power during this turn.",
    cardSet: "-Straw Hat Crew-[ST-01]",
    rarity: "C",
    image: "ST01-017.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST02-001",
    name: 'Eustass"Captain"Kid',
    cost: "",
    attribute: "Special",
    power: "5000",
    counter: "-",
    color: "Green",
    type: "Supernovas/Kid Pirates",
    category: "LEADER",
    effect:
      "[Activate: Main] [Once Per Turn]  ③ (You may rest the specified number of DON!! cards in your cost area.) You may trash 1 card from your hand: Set this Leader as active.",
    cardSet: "-Worst Generation-[ST-02]",
    rarity: "L",
    image: "ST02-001.png",
    life: "5",
    trigger: "",
  },
  {
    number: "ST02-002",
    name: "Vito",
    cost: "3",
    attribute: "Ranged",
    power: "5000",
    counter: "1000",
    color: "Green",
    type: "Firetank Pirates",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-Worst Generation-[ST-02]",
    rarity: "C",
    image: "ST02-002.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST02-003",
    name: "Urouge",
    cost: "2",
    attribute: "Strike",
    power: "3000",
    counter: "1000",
    color: "Green",
    type: "Supernovas/Fallen Monk Pirates",
    category: "CHARACTER",
    effect:
      "[DON!! x1] If you have 3 or more Characters, this card gains +2000 power.",
    cardSet: "-Worst Generation-[ST-02]",
    rarity: "C",
    image: "ST02-003.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST02-004",
    name: 'Capone"Gang"Bege',
    cost: "1",
    attribute: "Special",
    power: "1000",
    counter: "-",
    color: "Green",
    type: "Supernovas/Firetank Pirates",
    category: "CHARACTER",
    effect:
      "[Blocker] (After your opponent declares an attack, you may rest this card to make it the new target of the attack.)",
    cardSet: "-Worst Generation-[ST-02]",
    rarity: "C",
    image: "ST02-004.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST02-005",
    name: "Killer",
    cost: "3",
    attribute: "Slash",
    power: "3000",
    counter: "1000",
    color: "Green",
    type: "Supernovas/Kid Pirates",
    category: "CHARACTER",
    effect:
      "[On Play] K.O. up to 1 of your opponent's rested Characters with a cost of 3 or less.",
    cardSet: "-Worst Generation-[ST-02]",
    rarity: "C",
    image: "ST02-005.png",
    life: "",
    trigger: "[Trigger] Play this card.",
  },
  {
    number: "ST02-006",
    name: "Koby",
    cost: "4",
    attribute: "Strike",
    power: "6000",
    counter: "1000",
    color: "Green",
    type: "Navy",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-Worst Generation-[ST-02]",
    rarity: "C",
    image: "ST02-006.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST02-007",
    name: "Jewelry Bonney",
    cost: "1",
    attribute: "Special",
    power: "1000",
    counter: "1000",
    color: "Green",
    type: "Supernovas/Bonney Pirates",
    category: "CHARACTER",
    effect:
      "[Activate: Main] ➀ (You may rest the specified number of DON!! cards in your cost area.) You may rest this Character: Look at 5 cards from the top of your deck; reveal up to 1 {Supernovas} type card and add it to your hand. Then, place the rest at the bottom of your deck in any order.",
    cardSet: "-Worst Generation-[ST-02]",
    rarity: "C",
    image: "ST02-007.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST02-008",
    name: "Scratchmen Apoo",
    cost: "2",
    attribute: "Ranged",
    power: "3000",
    counter: "2000",
    color: "Green",
    type: "Supernovas/On-Air Pirates",
    category: "CHARACTER",
    effect:
      "[DON!! x1] [When Attacking] Rest up to 1 of your opponent's DON!! cards.",
    cardSet: "-Worst Generation-[ST-02]",
    rarity: "C",
    image: "ST02-008.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST02-009",
    name: "Trafalgar Law",
    cost: "5",
    attribute: "Slash",
    power: "6000",
    counter: "1000",
    color: "Green",
    type: "Supernovas/Heart Pirates",
    category: "CHARACTER",
    effect:
      "[On Play] Set up to 1 of your {Supernovas} or {Heart Pirates} type rested Characters with a cost of 5 or less as active.",
    cardSet: "-Worst Generation-[ST-02]",
    rarity: "SR",
    image: "ST02-009.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST02-010",
    name: "Basil Hawkins",
    cost: "5",
    attribute: "Slash",
    power: "6000",
    counter: "-",
    color: "Green",
    type: "Supernovas/Hawkins Pirates",
    category: "CHARACTER",
    effect:
      "[DON!! x1] [Once Per Turn] [Your Turn] If this Character battles your opponent's Character, set this card as active.",
    cardSet: "-Worst Generation-[ST-02]",
    rarity: "C",
    image: "ST02-010.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST02-011",
    name: "Heat",
    cost: "2",
    attribute: "Special",
    power: "4000",
    counter: "1000",
    color: "Green",
    type: "Kid Pirates",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-Worst Generation-[ST-02]",
    rarity: "C",
    image: "ST02-011.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST02-012",
    name: "Bepo",
    cost: "1",
    attribute: "Strike",
    power: "3000",
    counter: "1000",
    color: "Green",
    type: "Minks/Heart Pirates",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-Worst Generation-[ST-02]",
    rarity: "C",
    image: "ST02-012.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST02-013",
    name: 'Eustass"Captain"Kid',
    cost: "7",
    attribute: "Special",
    power: "7000",
    counter: "-",
    color: "Green",
    type: "Supernovas/Kid Pirates",
    category: "CHARACTER",
    effect:
      "[Blocker] (After your opponent declares an attack, you may rest this card to make it the new target of the attack.)",
    cardSet: "-Worst Generation-[ST-02]",
    rarity: "SR",
    image: "ST02-013.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST02-014",
    name: "X.Drake",
    cost: "4",
    attribute: "Slash",
    power: "5000",
    counter: "1000",
    color: "Green",
    type: "Navy/Supernovas/Drake Pirates",
    category: "CHARACTER",
    effect:
      "[DON!! x1] [Your Turn] If this Character is rested, your {Supernovas} or {Navy} type Leaders and Characters gain +1000 power.",
    cardSet: "-Worst Generation-[ST-02]",
    rarity: "C",
    image: "ST02-014.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST02-015",
    name: "Scalpel",
    cost: "1",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Green",
    type: "Supernovas/Heart Pirates",
    category: "EVENT",
    effect:
      "[Counter] Up to 1 of your Leader or Character cards gains +2000 power during this battle. Then, set up to 1 of your DON!! cards as active.",
    cardSet: "-Worst Generation-[ST-02]",
    rarity: "C",
    image: "ST02-015.png",
    life: "",
    trigger: "[Trigger] Set up to 2 of your DON!! cards as active.",
  },
  {
    number: "ST02-016",
    name: "Repel",
    cost: "2",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Green",
    type: "Supernovas/Kid Pirates",
    category: "EVENT",
    effect:
      "[Counter] Up to 1 of your Leader or Character cards gains +4000 power during this battle. Then, set up to 1 of your DON!! cards as active.",
    cardSet: "-Worst Generation-[ST-02]",
    rarity: "C",
    image: "ST02-016.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST02-017",
    name: "Straw Sword",
    cost: "2",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Green",
    type: "Supernovas/Hawkins Pirates",
    category: "EVENT",
    effect: "[Main] Rest up to 1 of your opponent's Characters.",
    cardSet: "-Worst Generation-[ST-02]",
    rarity: "C",
    image: "ST02-017.png",
    life: "",
    trigger:
      "[Trigger] Play up to 1 {Supernovas} type card with a cost of 2 or less from your hand.",
  },
  {
    number: "ST03-001",
    name: "Crocodile",
    cost: "",
    attribute: "Special",
    power: "5000",
    counter: "-",
    color: "Blue",
    type: "The Seven Warlords of the Sea/Baroque Works",
    category: "LEADER",
    effect:
      "[Activate: Main] [Once Per Turn] DON!! −4 (You may return the specified number of DON!! cards from your field to your DON!! deck.): Return up to 1 Character with a cost of 5 or less to the owner's hand.",
    cardSet: "-The Seven Warlords of the Sea-[ST-03]",
    rarity: "L",
    image: "ST03-001.png",
    life: "5",
    trigger: "",
  },
  {
    number: "ST03-002",
    name: "Edward Weevil",
    cost: "3",
    attribute: "Slash",
    power: "5000",
    counter: "1000",
    color: "Blue",
    type: "The Seven Warlords of the Sea",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-The Seven Warlords of the Sea-[ST-03]",
    rarity: "C",
    image: "ST03-002.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST03-003",
    name: "Crocodile",
    cost: "5",
    attribute: "Special",
    power: "6000",
    counter: "-",
    color: "Blue",
    type: "The Seven Warlords of the Sea/Baroque Works",
    category: "CHARACTER",
    effect:
      "[Blocker] (After your opponent declares an attack, you may rest this card to make it the new target of the attack.)",
    cardSet: "-The Seven Warlords of the Sea-[ST-03]",
    rarity: "SR",
    image: "ST03-003.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST03-004",
    name: "Gecko Moria",
    cost: "4",
    attribute: "Special",
    power: "5000",
    counter: "1000",
    color: "Blue",
    type: "The Seven Warlords of the Sea/Thriller Bark Pirates",
    category: "CHARACTER",
    effect:
      "[On Play] Add up to 1 {The Seven Warlords of the Sea} or {Thriller Bark Pirates} type Character with a cost of 4 or less other than [Gecko Moria] from your trash to your hand.",
    cardSet: "-The Seven Warlords of the Sea-[ST-03]",
    rarity: "C",
    image: "ST03-004.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST03-005",
    name: "Dracule Mihawk",
    cost: "4",
    attribute: "Slash",
    power: "5000",
    counter: "2000",
    color: "Blue",
    type: "The Seven Warlords of the Sea",
    category: "CHARACTER",
    effect:
      "[DON!! x1] [When Attacking] Draw 2 cards and trash 2 cards from your hand.",
    cardSet: "-The Seven Warlords of the Sea-[ST-03]",
    rarity: "C",
    image: "ST03-005.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST03-006",
    name: "Jinbe",
    cost: "2",
    attribute: "Strike",
    power: "4000",
    counter: "1000",
    color: "Blue",
    type: "Fish-Man/The Seven Warlords of the Sea/The Sun Pirates",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-The Seven Warlords of the Sea-[ST-03]",
    rarity: "C",
    image: "ST03-006.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST03-007",
    name: "Sentomaru",
    cost: "3",
    attribute: "Slash",
    power: "4000",
    counter: "1000",
    color: "Blue",
    type: "Navy",
    category: "CHARACTER",
    effect:
      "[DON!! x1] [Activate: Main] [Once Per Turn] ➁ (You may rest the specified number of DON!! cards in your cost area.): Play up to 1 [Pacifista] with a cost of 4 or less from your deck, then shuffle your deck.",
    cardSet: "-The Seven Warlords of the Sea-[ST-03]",
    rarity: "C",
    image: "ST03-007.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST03-008",
    name: "Trafalgar Law",
    cost: "1",
    attribute: "Slash",
    power: "1000",
    counter: "-",
    color: "Blue",
    type: "The Seven Warlords of the Sea/Heart Pirates",
    category: "CHARACTER",
    effect:
      "[Blocker] (After your opponent declares an attack, you may rest this card to make it the new target of the attack.)",
    cardSet: "-The Seven Warlords of the Sea-[ST-03]",
    rarity: "C",
    image: "ST03-008.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST03-009",
    name: "Donquixote Doflamingo",
    cost: "7",
    attribute: "Special",
    power: "7000",
    counter: "-",
    color: "Blue",
    type: "The Seven Warlords of the Sea/Donquixote Pirates",
    category: "CHARACTER",
    effect:
      "[On Play] Return up to 1 Character with a cost of 7 or less to the owner's hand.",
    cardSet: "-The Seven Warlords of the Sea-[ST-03]",
    rarity: "SR",
    image: "ST03-009.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST03-009_p1",
    name: "Donquixote Doflamingo",
    cost: "7",
    attribute: "Special",
    power: "7000",
    counter: "-",
    color: "Blue",
    type: "The Seven Warlords of the Sea/Donquixote Pirates",
    category: "CHARACTER",
    effect:
      "[On Play] Return up to 1 Character with a cost of 7 or less to the owner's hand.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "SP CARD",
    image: "ST03-009_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST03-010",
    name: "Bartholomew Kuma",
    cost: "2",
    attribute: "Strike",
    power: "3000",
    counter: "-",
    color: "Blue",
    type: "The Seven Warlords of the Sea/Revolutionary Army",
    category: "CHARACTER",
    effect:
      "[On Play] Look at 3 cards from the top of your deck and return them to the top or bottom of the deck in any order.",
    cardSet: "-The Seven Warlords of the Sea-[ST-03]",
    rarity: "C",
    image: "ST03-010.png",
    life: "",
    trigger: "[Trigger] Play this card.",
  },
  {
    number: "ST03-011",
    name: "Buggy",
    cost: "1",
    attribute: "Slash",
    power: "3000",
    counter: "1000",
    color: "Blue",
    type: "The Seven Warlords of the Sea/Buggy's Delivery",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-The Seven Warlords of the Sea-[ST-03]",
    rarity: "C",
    image: "ST03-011.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST03-012",
    name: "Pacifista",
    cost: "4",
    attribute: "Special",
    power: "6000",
    counter: "1000",
    color: "Blue",
    type: "Biological Weapon/Navy",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-The Seven Warlords of the Sea-[ST-03]",
    rarity: "C",
    image: "ST03-012.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST03-013",
    name: "Boa Hancock",
    cost: "3",
    attribute: "Special",
    power: "1000",
    counter: "1000",
    color: "Blue",
    type: "The Seven Warlords of the Sea/Kuja Pirates",
    category: "CHARACTER",
    effect:
      "[Blocker] (After your opponent declares an attack, you may rest this card to make it the new target of the attack.)",
    cardSet: "-The Seven Warlords of the Sea-[ST-03]",
    rarity: "C",
    image: "ST03-013.png",
    life: "",
    trigger: "[Trigger] Play this card.",
  },
  {
    number: "ST03-014",
    name: "Marshall.D.Teach",
    cost: "4",
    attribute: "Special",
    power: "4000",
    counter: "1000",
    color: "Blue",
    type: "The Seven Warlords of the Sea/Blackbeard Pirates",
    category: "CHARACTER",
    effect:
      "[On Play] Return up to 1 Character with a cost of 3 or less to the owner's hand.",
    cardSet: "-The Seven Warlords of the Sea-[ST-03]",
    rarity: "C",
    image: "ST03-014.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST03-014_p1",
    name: "Marshall.D.Teach",
    cost: "4",
    attribute: "Special",
    power: "4000",
    counter: "1000",
    color: "Blue",
    type: "The Seven Warlords of the Sea/Blackbeard Pirates",
    category: "CHARACTER",
    effect:
      "[On Play] Return up to 1 Character with a cost of 3 or less to the owner's hand.",
    cardSet: "Included in Online Regional Participation Pack Vol.1",
    rarity: "C",
    image: "ST03-014_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST03-015",
    name: "Sables",
    cost: "4",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Blue",
    type: "The Seven Warlords of the Sea/Baroque Works",
    category: "EVENT",
    effect:
      "[Main] Return up to 1 Character with a cost of 7 or less to the owner's hand.",
    cardSet: "-The Seven Warlords of the Sea-[ST-03]",
    rarity: "C",
    image: "ST03-015.png",
    life: "",
    trigger: "[Trigger] Activate this card's [Main] effect.",
  },
  {
    number: "ST03-016",
    name: "Thrust Pad Cannon",
    cost: "2",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Blue",
    type: "The Seven Warlords of the Sea/Revolutionary Army",
    category: "EVENT",
    effect:
      "[Counter] Return up to 1 Character with a cost of 3 or less to the owner's hand.",
    cardSet: "-The Seven Warlords of the Sea-[ST-03]",
    rarity: "C",
    image: "ST03-016.png",
    life: "",
    trigger: "[Trigger] Activate this card's [Counter] effect.",
  },
  {
    number: "ST03-017",
    name: "Love-Love Mellow",
    cost: "2",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Blue",
    type: "The Seven Warlords of the Sea/Kuja Pirates",
    category: "EVENT",
    effect:
      "[Counter] Up to 1 of your Leader or Character cards gains +4000 power during this battle. Then, draw 1 card if you have 3 or less cards in your hand.",
    cardSet: "-The Seven Warlords of the Sea-[ST-03]",
    rarity: "C",
    image: "ST03-017.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST04-001",
    name: "Kaido",
    cost: "",
    attribute: "Strike",
    power: "5000",
    counter: "-",
    color: "Purple",
    type: "The Four Emperors/Animal Kingdom Pirates",
    category: "LEADER",
    effect:
      "[Activate: Main] [Once Per Turn] DON!! −7 (You may return the specified number of DON!! cards from your field to your DON!! deck.): Trash up to 1 of your opponent's Life cards.",
    cardSet: "-Animal Kingdom Pirates-[ST-04]",
    rarity: "L",
    image: "ST04-001.png",
    life: "5",
    trigger: "",
  },
  {
    number: "ST04-002",
    name: "Ulti",
    cost: "4",
    attribute: "Strike",
    power: "5000",
    counter: "2000",
    color: "Purple",
    type: "Animal Kingdom Pirates",
    category: "CHARACTER",
    effect:
      "[On Play] DON!! −1 (You may return the specified number of DON!! cards from your field to your DON!! deck.): Play up to 1 [Page One] card with a cost of 4 or less from your hand.",
    cardSet: "-Animal Kingdom Pirates-[ST-04]",
    rarity: "C",
    image: "ST04-002.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST04-003",
    name: "Kaido",
    cost: "9",
    attribute: "Strike",
    power: "10000",
    counter: "-",
    color: "Purple",
    type: "The Four Emperors/Animal Kingdom Pirates",
    category: "CHARACTER",
    effect:
      "[On Play] DON!! −5 (You may return the specified number of DON!! cards from your field to your DON!! deck.): K.O. up to 1 of your opponent's Characters with a cost of 6 or less. This Character gains [Rush] during this turn.",
    cardSet: "-Animal Kingdom Pirates-[ST-04]",
    rarity: "SR",
    image: "ST04-003.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST04-003_p1",
    name: "Kaido",
    cost: "9",
    attribute: "Strike",
    power: "10000",
    counter: "-",
    color: "Purple",
    type: "The Four Emperors/Animal Kingdom Pirates",
    category: "CHARACTER",
    effect:
      "[On Play] DON!! −5 (You may return the specified number of DON!! cards from your field to your DON!! deck.): K.O. up to 1 of your opponent's Characters with a cost of 6 or less. This Character gains [Rush] during this turn.",
    cardSet: "-PILLARS OF STRENGTH- [OP03]",
    rarity: "SP CARD",
    image: "ST04-003_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST04-004",
    name: "King",
    cost: "6",
    attribute: "Special",
    power: "7000",
    counter: "-",
    color: "Purple",
    type: "Animal Kingdom Pirates",
    category: "CHARACTER",
    effect:
      "[On Play] DON!! −1 (You may return the specified number of DON!! cards from your field to your DON!! deck.): K.O. up to 1 of your opponent's Characters with a cost of 4 or less.",
    cardSet: "-Animal Kingdom Pirates-[ST-04]",
    rarity: "SR",
    image: "ST04-004.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST04-005",
    name: "Queen",
    cost: "5",
    attribute: "Strike",
    power: "6000",
    counter: "1000",
    color: "Purple",
    type: "Animal Kingdom Pirates",
    category: "CHARACTER",
    effect:
      "[Blocker] (After your opponent declares an attack, you may rest this card to make it the new target of the attack.)",
    cardSet: "-Animal Kingdom Pirates-[ST-04]",
    rarity: "C",
    image: "ST04-005.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST04-006",
    name: "Sasaki",
    cost: "3",
    attribute: "Ranged",
    power: "4000",
    counter: "1000",
    color: "Purple",
    type: "Animal Kingdom Pirates",
    category: "CHARACTER",
    effect:
      "[On Play] DON!! −1 (You may return the specified number of DON!! cards from your field to your DON!! deck.): Draw 1 card.",
    cardSet: "-Animal Kingdom Pirates-[ST-04]",
    rarity: "C",
    image: "ST04-006.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST04-007",
    name: "Sheepshead",
    cost: "2",
    attribute: "Slash",
    power: "4000",
    counter: "1000",
    color: "Purple",
    type: "Animal Kingdom Pirates/Smile",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-Animal Kingdom Pirates-[ST-04]",
    rarity: "C",
    image: "ST04-007.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST04-008",
    name: "Jack",
    cost: "3",
    attribute: "Strike",
    power: "4000",
    counter: "1000",
    color: "Purple",
    type: "Animal Kingdom Pirates",
    category: "CHARACTER",
    effect:
      "[On Play] You may trash 1 card from your hand: Add up to 1 DON!! card from your DON!! deck and set it as active.",
    cardSet: "-Animal Kingdom Pirates-[ST-04]",
    rarity: "C",
    image: "ST04-008.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST04-008_p1",
    name: "Jack",
    cost: "3",
    attribute: "Strike",
    power: "4000",
    counter: "1000",
    color: "Purple",
    type: "Animal Kingdom Pirates",
    category: "CHARACTER",
    effect:
      "[On Play] You may trash 1 card from your hand: Add up to 1 DON!! card from your DON!! deck and set it as active.",
    cardSet: "Included in Online Regional Participation Pack Vol.1",
    rarity: "C",
    image: "ST04-008_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST04-009",
    name: "Ginrummy",
    cost: "1",
    attribute: "Strike",
    power: "3000",
    counter: "1000",
    color: "Purple",
    type: "Animal Kingdom Pirates/Smile",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-Animal Kingdom Pirates-[ST-04]",
    rarity: "C",
    image: "ST04-009.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST04-010",
    name: "Who's.Who",
    cost: "3",
    attribute: "Slash",
    power: "3000",
    counter: "-",
    color: "Purple",
    type: "Animal Kingdom Pirates",
    category: "CHARACTER",
    effect:
      "[On Play] DON!! −1 (You may return the specified number of DON!! cards from your field to your DON!! deck.): K.O. up to 1 of your opponent's Characters with a cost of 3 or less.",
    cardSet: "-Animal Kingdom Pirates-[ST-04]",
    rarity: "C",
    image: "ST04-010.png",
    life: "",
    trigger: "[Trigger] Play this card.",
  },
  {
    number: "ST04-011",
    name: "Black Maria",
    cost: "2",
    attribute: "Special",
    power: "2000",
    counter: "1000",
    color: "Purple",
    type: "Animal Kingdom Pirates",
    category: "CHARACTER",
    effect:
      "[Blocker] (After your opponent declares an attack, you may rest this card to make it the new target of the attack.)",
    cardSet: "-Animal Kingdom Pirates-[ST-04]",
    rarity: "C",
    image: "ST04-011.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST04-012",
    name: "Page One",
    cost: "4",
    attribute: "Strike",
    power: "6000",
    counter: "1000",
    color: "Purple",
    type: "Animal Kingdom Pirates",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-Animal Kingdom Pirates-[ST-04]",
    rarity: "C",
    image: "ST04-012.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST04-013",
    name: "X.Drake",
    cost: "3",
    attribute: "Slash",
    power: "5000",
    counter: "1000",
    color: "Purple",
    type: "Navy/Drake Pirates/Animal Kingdom Pirates",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-Animal Kingdom Pirates-[ST-04]",
    rarity: "C",
    image: "ST04-013.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST04-014",
    name: 'Lead Performer "Disaster"',
    cost: "4",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Purple",
    type: "Animal Kingdom Pirates",
    category: "EVENT",
    effect:
      "[Main] Draw 1 card, then add up to 1 DON!! card from your DON!! deck and set it as active.",
    cardSet: "-Animal Kingdom Pirates-[ST-04]",
    rarity: "C",
    image: "ST04-014.png",
    life: "",
    trigger: "[Trigger] Activate this card's [Main] effect.",
  },
  {
    number: "ST04-015",
    name: "Brachio Bomber",
    cost: "6",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Purple",
    type: "Animal Kingdom Pirates",
    category: "EVENT",
    effect:
      "[Main] K.O. up to 1 of your opponent's Characters with a cost of 6 or less, then add up to 1 DON!! card from your DON!! deck and set it as active.",
    cardSet: "-Animal Kingdom Pirates-[ST-04]",
    rarity: "C",
    image: "ST04-015.png",
    life: "",
    trigger:
      "[Trigger] Add up to 1 DON!! card from your DON!! deck and set it as active.",
  },
  {
    number: "ST04-016",
    name: "Blast Breath",
    cost: "1",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Purple",
    type: "The Four Emperors/Animal Kingdom Pirates",
    category: "EVENT",
    effect:
      "[Counter] DON!! −1 (You may return the specified number of DON!! cards from your field to your DON!! deck.): Up to 1 of your Leader or Character cards gains +4000 power during this battle.",
    cardSet: "-Animal Kingdom Pirates-[ST-04]",
    rarity: "C",
    image: "ST04-016.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST04-017",
    name: "Onigashima Island",
    cost: "3",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Purple",
    type: "Animal Kingdom Pirates",
    category: "STAGE",
    effect:
      "[Activate: Main] You may rest this Stage: If your Leader has the {Animal Kingdom Pirates} type, add up to 1 DON!! card from your DON!! deck and rest it.",
    cardSet: "-Animal Kingdom Pirates-[ST-04]",
    rarity: "C",
    image: "ST04-017.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST05-001",
    name: "Shanks",
    cost: "",
    attribute: "Slash",
    power: "5000",
    counter: "-",
    color: "Purple",
    type: "FILM/The Four Emperors/Red-Haired Pirates",
    category: "LEADER",
    effect:
      "[Activate: Main] [Once Per Turn] DON!! −3 (You may return the specified number of DON!! cards from your field to your DON!! deck.): All of your {FILM} type Characters gain +2000 power during this turn.",
    cardSet: "ONE PIECE FILM edition [ST-05]",
    rarity: "L",
    image: "ST05-001.png",
    life: "5",
    trigger: "",
  },
  {
    number: "ST05-002",
    name: "Ain",
    cost: "4",
    attribute: "Special",
    power: "5000",
    counter: "-",
    color: "Purple",
    type: "FILM/Neo Navy",
    category: "CHARACTER",
    effect:
      "[On Play] Add up to 1 DON!! card from your DON!! deck and rest it.",
    cardSet: "ONE PIECE FILM edition [ST-05]",
    rarity: "C",
    image: "ST05-002.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST05-003",
    name: "Ann",
    cost: "2",
    attribute: "Special",
    power: "3000",
    counter: "-",
    color: "Purple",
    type: "FILM/The Pirates Fest",
    category: "CHARACTER",
    effect:
      "[Blocker] (After your opponent declares an attack, you may rest this card to make it the new target of the attack.)",
    cardSet: "ONE PIECE FILM edition [ST-05]",
    rarity: "C",
    image: "ST05-003.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST05-004",
    name: "Uta",
    cost: "4",
    attribute: "Special",
    power: "5000",
    counter: "1000",
    color: "Purple",
    type: "FILM",
    category: "CHARACTER",
    effect:
      "[Blocker] (After your opponent declares an attack, you may rest this card to make it the new target of the attack.)",
    cardSet: "ONE PIECE FILM edition [ST-05]",
    rarity: "SR",
    image: "ST05-004.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST05-005",
    name: "Carina",
    cost: "2",
    attribute: "Wisdom",
    power: "3000",
    counter: "1000",
    color: "Purple",
    type: "FILM/Grantesoro",
    category: "CHARACTER",
    effect:
      "[Activate: Main] [Once Per Turn] You may rest this Character and trash 1 {FILM} type card from your hand: If your opponent has more DON!! cards on their field than you, add 2 DON!! cards from your DON!! deck and rest them.",
    cardSet: "ONE PIECE FILM edition [ST-05]",
    rarity: "C",
    image: "ST05-005.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST05-006",
    name: "Gild Tesoro",
    cost: "5",
    attribute: "Special",
    power: "6000",
    counter: "-",
    color: "Purple",
    type: "FILM/Grantesoro",
    category: "CHARACTER",
    effect:
      "[When Attacking] DON!! −2 (You may return the specified number of DON!! cards from your field to your DON!! deck.): Draw 2 cards.",
    cardSet: "ONE PIECE FILM edition [ST-05]",
    rarity: "C",
    image: "ST05-006.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST05-007",
    name: "Gordon",
    cost: "1",
    attribute: "Wisdom",
    power: "3000",
    counter: "1000",
    color: "Purple",
    type: "FILM",
    category: "CHARACTER",
    effect: "-",
    cardSet: "ONE PIECE FILM edition [ST-05]",
    rarity: "C",
    image: "ST05-007.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST05-008",
    name: "Shiki",
    cost: "6",
    attribute: "Slash",
    power: "7000",
    counter: "1000",
    color: "Purple",
    type: "FILM/Golden Lion Pirates",
    category: "CHARACTER",
    effect:
      "If you have 8 or more DON!! cards on your field, this Character cannot be K.O.'d in battle.",
    cardSet: "ONE PIECE FILM edition [ST-05]",
    rarity: "C",
    image: "ST05-008.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST05-009",
    name: "Scarlet",
    cost: "2",
    attribute: "Strike",
    power: "3000",
    counter: "1000",
    color: "Purple",
    type: "FILM/Animal/Golden Lion Pirates",
    category: "CHARACTER",
    effect: "-",
    cardSet: "ONE PIECE FILM edition [ST-05]",
    rarity: "C",
    image: "ST05-009.png",
    life: "",
    trigger: "[Trigger] Play this card.",
  },
  {
    number: "ST05-010",
    name: "Zephyr",
    cost: "7",
    attribute: "Strike",
    power: "8000",
    counter: "-",
    color: "Purple",
    type: "FILM/Neo Navy",
    category: "CHARACTER",
    effect:
      "When this Character battles ＜Strike＞ attribute Characters, this Character gains +3000 power during this turn.",
    cardSet: "ONE PIECE FILM edition [ST-05]",
    rarity: "C",
    image: "ST05-010.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST05-011",
    name: "Douglas Bullet",
    cost: "8",
    attribute: "Special",
    power: "10000",
    counter: "-",
    color: "Purple",
    type: "FILM/The Pirates Fest",
    category: "CHARACTER",
    effect:
      "[Activate: Main] [Once Per Turn] DON!! −4 (You may return the specified number of DON!! cards from your field to your DON!! deck.): Rest up to 2 of your opponent's Characters with a cost of 6 or less. Then, this Character gains [Double Attack] during this turn.",
    cardSet: "ONE PIECE FILM edition [ST-05]",
    rarity: "SR",
    image: "ST05-011.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST05-012",
    name: "Baccarat",
    cost: "3",
    attribute: "Special",
    power: "5000",
    counter: "1000",
    color: "Purple",
    type: "FILM/Grantesoro",
    category: "CHARACTER",
    effect: "-",
    cardSet: "ONE PIECE FILM edition [ST-05]",
    rarity: "C",
    image: "ST05-012.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST05-013",
    name: "Bins",
    cost: "4",
    attribute: "Special",
    power: "6000",
    counter: "1000",
    color: "Purple",
    type: "FILM/Neo Navy",
    category: "CHARACTER",
    effect: "-",
    cardSet: "ONE PIECE FILM edition [ST-05]",
    rarity: "C",
    image: "ST05-013.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST05-014",
    name: "Buena Festa",
    cost: "1",
    attribute: "Wisdom",
    power: "-",
    counter: "2000",
    color: "Purple",
    type: "FILM/The Pirates Fest",
    category: "CHARACTER",
    effect:
      "[On Play] Look at 5 cards from the top of your deck; reveal up to 1 {FILM} type card other than [Buena Festa] and add it to your hand. Then, place the rest at the bottom of your deck in any order.",
    cardSet: "ONE PIECE FILM edition [ST-05]",
    rarity: "C",
    image: "ST05-014.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST05-015",
    name: "Dr. Indigo",
    cost: "2",
    attribute: "Wisdom",
    power: "4000",
    counter: "1000",
    color: "Purple",
    type: "FILM/Scientist/Golden Lion Pirates",
    category: "CHARACTER",
    effect: "-",
    cardSet: "ONE PIECE FILM edition [ST-05]",
    rarity: "C",
    image: "ST05-015.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST05-016",
    name: "Lion's Threat Imperial Earth Bind",
    cost: "3",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Purple",
    type: "FILM/Golden Lion Pirates",
    category: "EVENT",
    effect:
      "[Main] DON!! −2 (You may return the specified number of DON!! cards from your field to your DON!! deck.): K.O. up to 1 of your opponent's Characters with a cost of 5 or less.",
    cardSet: "ONE PIECE FILM edition [ST-05]",
    rarity: "C",
    image: "ST05-016.png",
    life: "",
    trigger:
      "[Trigger] Add up to 1 DON!! card from your DON!! deck and set it as active.",
  },
  {
    number: "ST05-017",
    name: "Union Armada",
    cost: "2",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Purple",
    type: "FILM/The Pirates Fest",
    category: "EVENT",
    effect:
      "[Counter] Up to 1 of your {FILM} type Leader or Character cards gains +4000 power during this battle. If that card is a Character, that Character cannot be K.O.'d during this turn.",
    cardSet: "ONE PIECE FILM edition [ST-05]",
    rarity: "C",
    image: "ST05-017.png",
    life: "",
    trigger:
      "[Trigger] Add up to 1 DON!! card from your DON!! deck and set it as active.",
  },
  {
    number: "ST06-001",
    name: "Sakazuki",
    cost: "",
    attribute: "Special",
    power: "5000",
    counter: "-",
    color: "Black",
    type: "Navy",
    category: "LEADER",
    effect:
      "[Activate: Main] [Once Per Turn] ③ (You may rest the specified number of DON!! cards in your cost area.) You may trash 1 card from your hand: K.O. up to 1 of your opponent's Characters with a cost of 0.",
    cardSet: "-Absolute Justice- [ST-06]",
    rarity: "L",
    image: "ST06-001.png",
    life: "5",
    trigger: "",
  },
  {
    number: "ST06-002",
    name: "Koby",
    cost: "1",
    attribute: "Strike",
    power: "2000",
    counter: "1000",
    color: "Black",
    type: "Navy",
    category: "CHARACTER",
    effect:
      "[On Play] You may trash 1 card from your hand: K.O. up to 1 of your opponent's Characters with a cost of 0.",
    cardSet: "-Absolute Justice- [ST-06]",
    rarity: "C",
    image: "ST06-002.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST06-003",
    name: "Jango",
    cost: "1",
    attribute: "Slash",
    power: "3000",
    counter: "1000",
    color: "Black",
    type: "Navy",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-Absolute Justice- [ST-06]",
    rarity: "C",
    image: "ST06-003.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST06-004",
    name: "Smoker",
    cost: "5",
    attribute: "Special",
    power: "7000",
    counter: "-",
    color: "Black",
    type: "Navy",
    category: "CHARACTER",
    effect: "This Character cannot be K.O.'d by effects.",
    cardSet: "-Absolute Justice- [ST-06]",
    rarity: "SR",
    image: "ST06-004.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST06-005",
    name: "Sengoku",
    cost: "5",
    attribute: "Wisdom",
    power: "6000",
    counter: "1000",
    color: "Black",
    type: "Navy",
    category: "CHARACTER",
    effect:
      "[When Attacking] Give up to 1 of your opponent's Characters −4 cost during this turn.",
    cardSet: "-Absolute Justice- [ST-06]",
    rarity: "C",
    image: "ST06-005.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST06-006",
    name: "Tashigi",
    cost: "3",
    attribute: "Slash",
    power: "4000",
    counter: "2000",
    color: "Black",
    type: "Navy",
    category: "CHARACTER",
    effect:
      "[Activate: Main] You may rest this Character: Give up to 1 of your opponent's Characters −2 cost during this turn.",
    cardSet: "-Absolute Justice- [ST-06]",
    rarity: "C",
    image: "ST06-006.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST06-007",
    name: "Tsuru",
    cost: "2",
    attribute: "Wisdom",
    power: "2000",
    counter: "1000",
    color: "Black",
    type: "Navy",
    category: "CHARACTER",
    effect:
      "[Blocker] (After your opponent declares an attack, you may rest this card to make it the new target of the attack.)",
    cardSet: "-Absolute Justice- [ST-06]",
    rarity: "C",
    image: "ST06-007.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST06-008",
    name: "Hina",
    cost: "3",
    attribute: "Special",
    power: "5000",
    counter: "-",
    color: "Black",
    type: "Navy",
    category: "CHARACTER",
    effect:
      "[On Play] Give up to 1 of your opponent's Characters −4 cost during this turn.",
    cardSet: "-Absolute Justice- [ST-06]",
    rarity: "C",
    image: "ST06-008.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST06-009",
    name: "Fullbody",
    cost: "2",
    attribute: "Strike",
    power: "4000",
    counter: "1000",
    color: "Black",
    type: "Navy",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-Absolute Justice- [ST-06]",
    rarity: "C",
    image: "ST06-009.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST06-010",
    name: "Helmeppo",
    cost: "2",
    attribute: "Slash",
    power: "3000",
    counter: "1000",
    color: "Black",
    type: "Navy",
    category: "CHARACTER",
    effect:
      "[On Play] Give up to 1 of your opponent's Characters −3 cost during this turn.",
    cardSet: "-Absolute Justice- [ST-06]",
    rarity: "C",
    image: "ST06-010.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST06-011",
    name: "Momonga",
    cost: "4",
    attribute: "Slash",
    power: "6000",
    counter: "1000",
    color: "Black",
    type: "Navy",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-Absolute Justice- [ST-06]",
    rarity: "C",
    image: "ST06-011.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST06-012",
    name: "Monkey.D.Garp",
    cost: "5",
    attribute: "Strike",
    power: "6000",
    counter: "-",
    color: "Black",
    type: "Navy",
    category: "CHARACTER",
    effect:
      "[Activate: Main] You may trash 1 card from your hand and rest this Character: K.O. up to 1 of your opponent's Characters with a cost of 4 or less.",
    cardSet: "-Absolute Justice- [ST-06]",
    rarity: "SR",
    image: "ST06-012.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST06-013",
    name: "T-Bone",
    cost: "3",
    attribute: "Slash",
    power: "5000",
    counter: "1000",
    color: "Black",
    type: "Navy",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-Absolute Justice- [ST-06]",
    rarity: "C",
    image: "ST06-013.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST06-014",
    name: "Shockwave",
    cost: "2",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Black",
    type: "Navy",
    category: "EVENT",
    effect:
      "[Counter] Up to 1 of your Leader or Character cards gains +4000 power during this battle. Then, K.O. up to 1 of your opponent's active Characters with a cost of 3 or less.",
    cardSet: "-Absolute Justice- [ST-06]",
    rarity: "C",
    image: "ST06-014.png",
    life: "",
    trigger:
      "[Trigger] K.O. up to 1 of your opponent's Characters with a cost of 4 or less.",
  },
  {
    number: "ST06-015",
    name: "Great Eruption",
    cost: "1",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Black",
    type: "Navy",
    category: "EVENT",
    effect:
      "[Main] Draw 1 card. Then, give up to 1 of your opponent's Characters −2 cost during this turn.",
    cardSet: "-Absolute Justice- [ST-06]",
    rarity: "C",
    image: "ST06-015.png",
    life: "",
    trigger:
      "[Trigger] Your opponent chooses 1 card from their hand and trashes it.",
  },
  {
    number: "ST06-016",
    name: "White Out",
    cost: "1",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Black",
    type: "Navy",
    category: "EVENT",
    effect:
      "[Counter] Up to 1 of your Leader or Character cards gains +2000 power during this battle.",
    cardSet: "-Absolute Justice- [ST-06]",
    rarity: "C",
    image: "ST06-016.png",
    life: "",
    trigger:
      "[Trigger] Draw 1 card and none of your Characters can be K.O.'d during this turn.",
  },
  {
    number: "ST06-017",
    name: "Navy HQ",
    cost: "1",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Black",
    type: "Navy",
    category: "STAGE",
    effect:
      "[On Play] Give up to 1 of your opponent's Characters −1 cost during this turn.",
    cardSet: "-Absolute Justice- [ST-06]",
    rarity: "C",
    image: "ST06-017.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST07-001",
    name: "Charlotte Linlin",
    cost: "",
    attribute: "Special",
    power: "5000",
    counter: "-",
    color: "Yellow",
    type: "The Four Emperors/Big Mom Pirates",
    category: "LEADER",
    effect:
      "[DON!! x2] [When Attacking] You may add 1 card from the top or bottom of your Life cards to your hand: If you have 2 or less Life cards, add up to 1 card from your hand to the top of your Life cards.",
    cardSet: "Big Mom Pirates [ST-07]",
    rarity: "L",
    image: "ST07-001.png",
    life: "5",
    trigger: "",
  },
  {
    number: "ST07-002",
    name: "Charlotte Anana",
    cost: "1",
    attribute: "Wisdom",
    power: "3000",
    counter: "1000",
    color: "Yellow",
    type: "Big Mom Pirates",
    category: "CHARACTER",
    effect: "-",
    cardSet: "Big Mom Pirates [ST-07]",
    rarity: "C",
    image: "ST07-002.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST07-003",
    name: "Charlotte Katakuri",
    cost: "4",
    attribute: "Strike",
    power: "6000",
    counter: "-",
    color: "Yellow",
    type: "Big Mom Pirates",
    category: "CHARACTER",
    effect:
      "[On Play] Look at up to 1 card from the top of your or your opponent's Life cards, and place it at the top or bottom of the Life cards. Then, if you have less Life cards than your opponent, this Character gains [Rush] during this turn.",
    cardSet: "Big Mom Pirates [ST-07]",
    rarity: "SR",
    image: "ST07-003.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST07-004",
    name: "Charlotte Snack",
    cost: "5",
    attribute: "Slash",
    power: "6000",
    counter: "-",
    color: "Yellow",
    type: "Big Mom Pirates",
    category: "CHARACTER",
    effect:
      "[DON!! x1] [When Attacking] You may add 1 card from the top or bottom of your Life cards to your hand: This Character gains [Banish] and +1000 power during this battle.",
    cardSet: "Big Mom Pirates [ST-07]",
    rarity: "C",
    image: "ST07-004.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST07-005",
    name: "Charlotte Daifuku",
    cost: "4",
    attribute: "Slash",
    power: "5000",
    counter: "1000",
    color: "Yellow",
    type: "Big Mom Pirates",
    category: "CHARACTER",
    effect:
      "[DON!! x1] [When Attacking] You may add 1 card from the top or bottom of your Life cards to your hand: Add up to 1 card from the top of your deck to the top of your Life cards.",
    cardSet: "Big Mom Pirates [ST-07]",
    rarity: "C",
    image: "ST07-005.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST07-006",
    name: "Charlotte Flampe",
    cost: "2",
    attribute: "Ranged",
    power: "4000",
    counter: "1000",
    color: "Yellow",
    type: "Big Mom Pirates",
    category: "CHARACTER",
    effect: "-",
    cardSet: "Big Mom Pirates [ST-07]",
    rarity: "C",
    image: "ST07-006.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST07-007",
    name: "Charlotte Brulee",
    cost: "3",
    attribute: "Special",
    power: "1000",
    counter: "1000",
    color: "Yellow",
    type: "Big Mom Pirates",
    category: "CHARACTER",
    effect:
      "[Blocker] (After your opponent declares an attack, you may rest this card to make it the new target of the attack.)",
    cardSet: "Big Mom Pirates [ST-07]",
    rarity: "C",
    image: "ST07-007.png",
    life: "",
    trigger: "[Trigger] Play this card.",
  },
  {
    number: "ST07-008",
    name: "Charlotte Pudding",
    cost: "2",
    attribute: "Wisdom",
    power: "3000",
    counter: "2000",
    color: "Yellow",
    type: "Big Mom Pirates",
    category: "CHARACTER",
    effect:
      "[On Play] Look at up to 1 card from the top of your or your opponent's Life cards, and place it at the top or bottom of the Life cards.",
    cardSet: "Big Mom Pirates [ST-07]",
    rarity: "C",
    image: "ST07-008.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST07-008_p1",
    name: "Charlotte Pudding",
    cost: "2",
    attribute: "Wisdom",
    power: "3000",
    counter: "2000",
    color: "Yellow",
    type: "Big Mom Pirates",
    category: "CHARACTER",
    effect:
      "[On Play] Look at up to 1 card from the top of yours or your opponent's Life cards, and place it at the top or bottom of the Life cards.",
    cardSet: "Included in Online Regional Participation Pack Vol.1",
    rarity: "C",
    image: "ST07-008_p1.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST07-009",
    name: "Charlotte Mont-d'or",
    cost: "3",
    attribute: "Special",
    power: "4000",
    counter: "1000",
    color: "Yellow",
    type: "Big Mom Pirates",
    category: "CHARACTER",
    effect:
      "[Activate: Main] You may rest this Character and add 1 card from the top or bottom of your Life cards to your hand: K.O. up to 1 of your opponent's Characters with a cost of 3 or less.",
    cardSet: "Big Mom Pirates [ST-07]",
    rarity: "C",
    image: "ST07-009.png",
    life: "",
    trigger: "[Trigger] You may trash 1 card from your hand: Play this card.",
  },
  {
    number: "ST07-010",
    name: "Charlotte Linlin",
    cost: "7",
    attribute: "Special",
    power: "8000",
    counter: "-",
    color: "Yellow",
    type: "The Four Emperors/Big Mom Pirates",
    category: "CHARACTER",
    effect: "[On Play] Your opponent chooses one:",
    cardSet: "Big Mom Pirates [ST-07]",
    rarity: "SR",
    image: "ST07-010.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST07-011",
    name: "Zeus",
    cost: "3",
    attribute: "Special",
    power: "3000",
    counter: "1000",
    color: "Yellow",
    type: "Big Mom Pirates/Homies",
    category: "CHARACTER",
    effect:
      "[Activate: Main] You may rest this Character: Up to 1 of your [Charlotte Linlin] cards gains [Banish] during this turn.",
    cardSet: "Big Mom Pirates [ST-07]",
    rarity: "C",
    image: "ST07-011.png",
    life: "",
    trigger: "[Trigger] Play this card.",
  },
  {
    number: "ST07-012",
    name: "Baron Tamago",
    cost: "4",
    attribute: "Strike",
    power: "6000",
    counter: "1000",
    color: "Yellow",
    type: "Big Mom Pirates",
    category: "CHARACTER",
    effect: "-",
    cardSet: "Big Mom Pirates [ST-07]",
    rarity: "C",
    image: "ST07-012.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST07-013",
    name: "Prometheus",
    cost: "3",
    attribute: "Special",
    power: "3000",
    counter: "1000",
    color: "Yellow",
    type: "Big Mom Pirates/Homies",
    category: "CHARACTER",
    effect:
      "[Activate: Main] You may rest this Character: Up to 1 of your [Charlotte Linlin] cards gains [Double Attack] during this turn.",
    cardSet: "Big Mom Pirates [ST-07]",
    rarity: "C",
    image: "ST07-013.png",
    life: "",
    trigger: "[Trigger] Play this card.",
  },
  {
    number: "ST07-014",
    name: "Pekoms",
    cost: "3",
    attribute: "Strike",
    power: "5000",
    counter: "1000",
    color: "Yellow",
    type: "Minks/Big Mom Pirates",
    category: "CHARACTER",
    effect: "-",
    cardSet: "Big Mom Pirates [ST-07]",
    rarity: "C",
    image: "ST07-014.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST07-015",
    name: "Soul Pocus",
    cost: "5",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Yellow",
    type: "The Four Emperors/Big Mom Pirates",
    category: "EVENT",
    effect: "[Main] Your opponent chooses one:",
    cardSet: "Big Mom Pirates [ST-07]",
    rarity: "C",
    image: "ST07-015.png",
    life: "",
    trigger: "[Trigger] Activate this card's [Main] effect.",
  },
  {
    number: "ST07-016",
    name: "Power Mochi",
    cost: "1",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Yellow",
    type: "Big Mom Pirates",
    category: "EVENT",
    effect:
      "[Counter] Look at up to 1 card from the top of your or your opponent's Life cards, and place it at the top or bottom of the Life cards. Then, up to 1 of your Leader or Character cards gains +2000 power during this battle.",
    cardSet: "Big Mom Pirates [ST-07]",
    rarity: "C",
    image: "ST07-016.png",
    life: "",
    trigger:
      "[Trigger] Draw 1 card, look at up to 1 card from the top of your or your opponent's Life cards, and place it at the top or bottom of the Life cards.",
  },
  {
    number: "ST07-017",
    name: "Queen Mama Chanter",
    cost: "2",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Yellow",
    type: "Big Mom Pirates/Homies",
    category: "STAGE",
    effect:
      "[Activate: Main] You may rest this Stage and add 1 card from the top or bottom of your Life cards to your hand: Add up to 1 of your Characters with a cost of 3 to the top of the owner's Life cards face-up.",
    cardSet: "Big Mom Pirates [ST-07]",
    rarity: "C",
    image: "ST07-017.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST08-001",
    name: "Monkey.D.Luffy",
    cost: "",
    attribute: "Strike",
    power: "5000",
    counter: "-",
    color: "Black",
    type: "Straw Hat Crew",
    category: "LEADER",
    effect:
      "[Your Turn] When a Character is K.O.'d, give up to 1 rested DON!! card to this Leader.",
    cardSet: "-Monkey D. Luffy-[ST-08]",
    rarity: "L",
    image: "ST08-001.png",
    life: "5",
    trigger: "",
  },
  {
    number: "ST08-002",
    name: "Uta",
    cost: "2",
    attribute: "Special",
    power: "3000",
    counter: "-",
    color: "Black",
    type: "FILM",
    category: "CHARACTER",
    effect: "This Character cannot be K.O.'d in battle by Leaders.",
    cardSet: "-Monkey D. Luffy-[ST-08]",
    rarity: "SR",
    image: "ST08-002.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST08-003",
    name: "Gaimon",
    cost: "2",
    attribute: "Wisdom",
    power: "4000",
    counter: "1000",
    color: "Black",
    type: "East Blue",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-Monkey D. Luffy-[ST-08]",
    rarity: "C",
    image: "ST08-003.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST08-004",
    name: "Koby",
    cost: "4",
    attribute: "Strike",
    power: "1000",
    counter: "1000",
    color: "Black",
    type: "Navy",
    category: "CHARACTER",
    effect:
      "[Activate: Main] You may rest this Character: K.O. up to 1 of your opponent's Characters with a cost of 2 or less.",
    cardSet: "-Monkey D. Luffy-[ST-08]",
    rarity: "C",
    image: "ST08-004.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST08-005",
    name: "Shanks",
    cost: "9",
    attribute: "Slash",
    power: "10000",
    counter: "-",
    color: "Black",
    type: "Red-Haired Pirates",
    category: "CHARACTER",
    effect:
      "[On Play] You may trash 1 card from your hand: K.O. all Characters with a cost of 1 or less.",
    cardSet: "-Monkey D. Luffy-[ST-08]",
    rarity: "SR",
    image: "ST08-005.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST08-006",
    name: "Shirahoshi",
    cost: "4",
    attribute: "Wisdom",
    power: "-",
    counter: "1000",
    color: "Black",
    type: "Merfolk",
    category: "CHARACTER",
    effect:
      "[Blocker] (After your opponent declares an attack, you may rest this card to make it the new target of the attack.)",
    cardSet: "-Monkey D. Luffy-[ST-08]",
    rarity: "C",
    image: "ST08-006.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST08-007",
    name: "Nefeltari Vivi",
    cost: "3",
    attribute: "Slash",
    power: "1000",
    counter: "1000",
    color: "Black",
    type: "Alabasta",
    category: "CHARACTER",
    effect:
      "[Blocker] (After your opponent declares an attack, you may rest this card to make it the new target of the attack.)",
    cardSet: "-Monkey D. Luffy-[ST-08]",
    rarity: "C",
    image: "ST08-007.png",
    life: "",
    trigger: "[Trigger] Play this card.",
  },
  {
    number: "ST08-008",
    name: "Higuma",
    cost: "1",
    attribute: "Slash",
    power: "2000",
    counter: "1000",
    color: "Black",
    type: "Mountain Bandits",
    category: "CHARACTER",
    effect:
      "[On Play] Give up to 1 of your opponent's Characters −2 cost during this turn.",
    cardSet: "-Monkey D. Luffy-[ST-08]",
    rarity: "C",
    image: "ST08-008.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST08-009",
    name: "Makino",
    cost: "2",
    attribute: "Wisdom",
    power: "-",
    counter: "2000",
    color: "Black",
    type: "Windmill Village",
    category: "CHARACTER",
    effect: "[On Play] If there is a Character with a cost of 0, draw 1 card.",
    cardSet: "-Monkey D. Luffy-[ST-08]",
    rarity: "C",
    image: "ST08-009.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST08-010",
    name: "Monkey.D.Garp",
    cost: "5",
    attribute: "Strike",
    power: "7000",
    counter: "1000",
    color: "Black",
    type: "Navy",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-Monkey D. Luffy-[ST-08]",
    rarity: "C",
    image: "ST08-010.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST08-011",
    name: "Monkey.D.Luffy",
    cost: "3",
    attribute: "Strike",
    power: "5000",
    counter: "1000",
    color: "Black",
    type: "Straw Hat Crew",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-Monkey D. Luffy-[ST-08]",
    rarity: "C",
    image: "ST08-011.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST08-012",
    name: "Laboon",
    cost: "4",
    attribute: "Strike",
    power: "6000",
    counter: "1000",
    color: "Black",
    type: "Animal",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-Monkey D. Luffy-[ST-08]",
    rarity: "C",
    image: "ST08-012.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST08-013",
    name: "Mr.2.Bon.Kurei(Bentham)",
    cost: "5",
    attribute: "Strike",
    power: "6000",
    counter: "-",
    color: "Black",
    type: "Former Baroque Works",
    category: "CHARACTER",
    effect:
      "[DON!! x1] At the end of a battle in which this Character battles your opponent's Character, you may K.O. the opponent’s Character you battled with. If you do, K.O. this Character.",
    cardSet: "-Monkey D. Luffy-[ST-08]",
    rarity: "C",
    image: "ST08-013.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST08-014",
    name: "Gum-Gum Bell",
    cost: "2",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Black",
    type: "Straw Hat Crew",
    category: "EVENT",
    effect:
      "[Main] You may add 1 card from the top of your Life cards to your hand: Give up to 1 of your opponent's Characters −7 cost during this turn.",
    cardSet: "-Monkey D. Luffy-[ST-08]",
    rarity: "C",
    image: "ST08-014.png",
    life: "",
    trigger:
      "[Trigger] Add up to 1 black Character card with a cost of 2 or less from your trash to your hand.",
  },
  {
    number: "ST08-015",
    name: "Gum-Gum Pistol",
    cost: "3",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Black",
    type: "Straw Hat Crew",
    category: "EVENT",
    effect:
      "[Main] K.O. up to 1 of your opponent's Characters with a cost of 2 or less.",
    cardSet: "-Monkey D. Luffy-[ST-08]",
    rarity: "C",
    image: "ST08-015.png",
    life: "",
    trigger: "[Trigger] Draw 1 card.",
  },
  {
    number: "ST09-001",
    name: "Yamato",
    cost: "",
    attribute: "Strike",
    power: "5000",
    counter: "-",
    color: "Yellow",
    type: "Land of Wano",
    category: "LEADER",
    effect:
      "[DON!! x1] [Opponent's Turn] If you have 2 or less Life cards, this Leader gains +1000 power.",
    cardSet: "-Yamato-[ST-09]",
    rarity: "L",
    image: "ST09-001.png",
    life: "5",
    trigger: "",
  },
  {
    number: "ST09-002",
    name: "Uzuki Tempura",
    cost: "4",
    attribute: "Slash",
    power: "5000",
    counter: "1000",
    color: "Yellow",
    type: "Land of Wano",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-Yamato-[ST-09]",
    rarity: "C",
    image: "ST09-002.png",
    life: "",
    trigger:
      "[Trigger] Rest up to 1 of your opponent's Characters with a cost of 2 or less and add this card to your hand.",
  },
  {
    number: "ST09-003",
    name: "Ulti",
    cost: "4",
    attribute: "Strike",
    power: "6000",
    counter: "1000",
    color: "Yellow",
    type: "Animal Kingdom Pirates",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-Yamato-[ST-09]",
    rarity: "C",
    image: "ST09-003.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST09-004",
    name: "Kaido",
    cost: "4",
    attribute: "Special",
    power: "5000",
    counter: "1000",
    color: "Yellow",
    type: "The Four Emperors/Animal Kingdom Pirates",
    category: "CHARACTER",
    effect:
      "[DON!! x1] If you have 2 or less Life cards, this Character cannot be K.O.'d in battle.",
    cardSet: "-Yamato-[ST-09]",
    rarity: "C",
    image: "ST09-004.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST09-005",
    name: "Kouzuki Oden",
    cost: "7",
    attribute: "Slash",
    power: "7000",
    counter: "-",
    color: "Yellow",
    type: "Land of Wano/Kouzuki Clan",
    category: "CHARACTER",
    effect: "[DON!! x1] This Character gains [Double Attack].",
    cardSet: "-Yamato-[ST-09]",
    rarity: "SR",
    image: "ST09-005.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST09-006",
    name: "Kouzuki Momonosuke",
    cost: "2",
    attribute: "Special",
    power: "4000",
    counter: "1000",
    color: "Yellow",
    type: "Land of Wano/Kouzuki Clan",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-Yamato-[ST-09]",
    rarity: "C",
    image: "ST09-006.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST09-007",
    name: "Shinobu",
    cost: "3",
    attribute: "Special",
    power: "2000",
    counter: "-",
    color: "Yellow",
    type: "Land of Wano",
    category: "CHARACTER",
    effect:
      "[Blocker] (After your opponent declares an attack, you may rest this card to make it the new target of the attack.)",
    cardSet: "-Yamato-[ST-09]",
    rarity: "C",
    image: "ST09-007.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST09-008",
    name: "Shimotsuki Ushimaru",
    cost: "5",
    attribute: "Slash",
    power: "6000",
    counter: "-",
    color: "Yellow",
    type: "Land of Wano",
    category: "CHARACTER",
    effect:
      "[DON!! x1] [When Attacking] You may add 1 card from the top or bottom of your Life cards to your hand: Play up to 1 yellow {Land of Wano} type Character card with a cost of 4 or less from your hand.",
    cardSet: "-Yamato-[ST-09]",
    rarity: "C",
    image: "ST09-008.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST09-009",
    name: "Fugetsu Omusubi",
    cost: "3",
    attribute: "Slash",
    power: "4000",
    counter: "1000",
    color: "Yellow",
    type: "Land of Wano",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-Yamato-[ST-09]",
    rarity: "C",
    image: "ST09-009.png",
    life: "",
    trigger:
      "[Trigger] K.O. up to 1 of your opponent's Characters with a cost of 1 or less and add this card to your hand.",
  },
  {
    number: "ST09-010",
    name: "Portgas.D.Ace",
    cost: "6",
    attribute: "Special",
    power: "7000",
    counter: "-",
    color: "Yellow",
    type: "Land of Wano/Whitebeard Pirates",
    category: "CHARACTER",
    effect:
      "[Once Per Turn] If this Character would be K.O.'d, you may trash 1 card from the top or bottom of your Life cards instead.",
    cardSet: "-Yamato-[ST-09]",
    rarity: "SR",
    image: "ST09-010.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST09-011",
    name: "Monkey.D.Luffy",
    cost: "3",
    attribute: "Strike",
    power: "5000",
    counter: "1000",
    color: "Yellow",
    type: "Land of Wano/Straw Hat Crew",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-Yamato-[ST-09]",
    rarity: "C",
    image: "ST09-011.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST09-012",
    name: "Yamato",
    cost: "3",
    attribute: "Strike",
    power: "3000",
    counter: "2000",
    color: "Yellow",
    type: "Land of Wano",
    category: "CHARACTER",
    effect:
      "[When Attacking] You may add 1 card from the top or bottom of your Life cards to your hand: This Character gains +2000 power until the start of your next turn.",
    cardSet: "-Yamato-[ST-09]",
    rarity: "C",
    image: "ST09-012.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST09-013",
    name: "Yamato",
    cost: "5",
    attribute: "Special",
    power: "7000",
    counter: "1000",
    color: "Yellow",
    type: "Land of Wano",
    category: "CHARACTER",
    effect: "-",
    cardSet: "-Yamato-[ST-09]",
    rarity: "C",
    image: "ST09-013.png",
    life: "",
    trigger: "",
  },
  {
    number: "ST09-014",
    name: "Narikabura Arrow",
    cost: "1",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Yellow",
    type: "Land of Wano",
    category: "EVENT",
    effect:
      "[Counter] If you have 2 or less Life cards, give up to 1 of your opponent's Leader or Character cards −3000 power during this turn.",
    cardSet: "-Yamato-[ST-09]",
    rarity: "C",
    image: "ST09-014.png",
    life: "",
    trigger:
      "[Trigger] You may trash 2 cards from your hand: Add up to 1 card from the top of your deck to the top of your Life cards.",
  },
  {
    number: "ST09-015",
    name: "Thunder Bagua",
    cost: "2",
    attribute: "",
    power: "-",
    counter: "-",
    color: "Yellow",
    type: "Land of Wano",
    category: "EVENT",
    effect:
      "[Counter] Up to 1 of your Leader or Character cards gains +4000 power during this battle. Then, if you have 2 or less Life cards, add up to 1 of your opponent's Characters with a cost of 3 or less to the top or bottom of the owner's Life cards face-up.",
    cardSet: "-Yamato-[ST-09]",
    rarity: "C",
    image: "ST09-015.png",
    life: "",
    trigger: "[Trigger] Draw 1 card.",
  },
];
