import CardSearchResponse from "../../components/CardResults";
import Header from "./Header";

const MainTemplate = ({ children }: any) => {
  return (
    <div className="w-100 h-100" style={{ paddingTop: '65px' }}>
      <Header />
      <div className="container d-flex justify-content-center">
        <CardSearchResponse />
      </div>
      <div className="px-3 px-sm-none text-white h-100">
        {children}
      </div>
    </div>
  );
};

export default MainTemplate;
