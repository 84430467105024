import { useDispatch, useSelector } from "react-redux";
import ILanguage from "../interfaces/Itranslation";
import en from "../language/en-en";
import es from "../language/es-es";
import pt from "../language/pt-br";
import { set_language } from "../redux/actions/translation";

const useTranslation = () => {
  const { translation } = useSelector((state: any) => state);
  const dispatch: any = useDispatch();

  const t = (key: string) => {
    const path: any = key.split(".");
    let meta_data: any = en;

    switch (translation.language) {
      case "en-en":
        meta_data = en;
        break;
      case "es-es":
        meta_data = es;
        break;
      case "pt-br":
        meta_data = pt;
        break;
      default:
        meta_data = en;
        break;
    }

    path.forEach((item: any) => {
      if (!meta_data[item]) {
        meta_data = "";
        return meta_data;
      }
      meta_data = meta_data[item];
    });
    return meta_data;
  };

  const setLanguage = (language: ILanguage) => {
    dispatch(set_language(language));
  };

  return { t, setLanguage };
};

export default useTranslation;
