import { Dropdown, Popover, Whisper } from "rsuite"
import WhisperComponent from "../WhisperComponent"
import { BiWorld } from "react-icons/bi"
import useTranslation from "../../hooks/useTranslation"
import React from "react"
import { useSelector } from "react-redux"

const TranslateIcon = () => {
    const { translation } = useSelector((state: any) => state)
    const { t, setLanguage } = useTranslation();
    const ref: any = React.useRef();

    const MenuPopover = React.forwardRef(({ onSelect, ...rest }: any, ref) => (
        <Popover ref={ref} {...rest} full>
            <Dropdown.Menu onSelect={onSelect} className="text-white">
                <Dropdown.Item eventKey={1}>{t('languages.english')}</Dropdown.Item>
                <Dropdown.Item eventKey={2}>{t('languages.spanish')}</Dropdown.Item>
                <Dropdown.Item eventKey={3}>{t('languages.portuguese')}</Dropdown.Item>
            </Dropdown.Menu>
        </Popover>
    ));

    const _handleSelect = (eventKey: any) => {
        switch (eventKey) {
            case 1:
                setLanguage({ language: "en-en" });
                break;
            case 2:
                setLanguage({ language: "es-es" });
                break;
            case 3:
                setLanguage({ language: "pt-br" });
                break;
            default:
                break;
        }
        ref.current.close();
    };

    return (
        <Whisper
            placement="bottom"
            trigger="click"
            controlId="control-id-click"
            ref={ref}
            speaker={
                <MenuPopover onSelect={_handleSelect} />
            }
        >
            <button className="nav-link">
                <WhisperComponent text={t(`languages.${translation.language}`)}>
                    <BiWorld className="text-dark" style={{ fontSize: "27px" }} />
                </WhisperComponent>
            </button>
        </Whisper>
    )
}

export default TranslateIcon