/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import SearchButton from "../../components/SearchButton";
import { AiOutlineInstagram } from 'react-icons/ai';
import { BsDiscord, BsTwitter } from 'react-icons/bs';
import Logo from '../../assets/images/png/logo_op.png';
import { useDispatch } from "react-redux";
import { save_search } from "../../redux/actions/card_list";
import { useDatabase } from "../../hooks/useDatabase";
import useTranslation from "../../hooks/useTranslation";
import TranslateIcon from "../../components/TranslateIcon";
import { useDebounce } from "../../hooks/useDebounce";
import { Link } from "react-router-dom";

const Header = () => {

    const dispatch: any = useDispatch();

    const { searchDB } = useDatabase();


    const [search, setSearch] = useState({
        open: false,
        value: ''
    });

    useEffect(() => {
        document.addEventListener('keydown', (e) => {
            if (e.key.toLowerCase() === 'k' && e.ctrlKey) {
                e.preventDefault();
                toggleOpen();
            }
        });
    }, []);

    const { t } = useTranslation()

    const toggleOpen = () => {
        setSearch((prevState: any) => {
            return {
                ...prevState,
                open: !prevState.open
            }
        });
    }

    const handleSearch = (e: any) => {
        let list: any = searchDB("name", e.target.value)
        if (list.length > 10) {
            list = list.slice(0, 8)
        }

        if (e.target.value === '') {
            list = []
        }
        dispatch(save_search([...list]))
    }

    const debounceSearch = useDebounce(handleSearch, 400)

    return (
        <nav className="navbar navbar-expand-lg px-md-5 top-0 bg-body-tertiary position-fixed w-100" style={{ zIndex: 6, height: "65px" }}>
            <div className="container-fluid">
                <img className="navbar-brand" style={{ width: '150px' }} src={Logo} alt="" />
                <button className="btn  border-0 navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="d-none d-sm-block collapse navbar-collapse d-flex justify-content-around" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <Link className="nav-link" to={'/'}>{t('header.menu.home')}</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to={'/decks'}>{t('header.menu.decks')}</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to={'/events'}>{t('header.menu.events')}</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to={'/cards'}>{t('header.menu.cards')}</Link>
                        </li>
                    </ul>

                    <SearchButton open={search.open} toggleOpen={toggleOpen} handleSearch={debounceSearch} />

                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <TranslateIcon />
                        </li>

                        <li className="nav-item">
                            <a className="nav-link text-dark" href="/">
                                <BsTwitter style={{ fontSize: "27px" }} />
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link text-dark" href="/">
                                <AiOutlineInstagram style={{ fontSize: "27px" }} />
                            </a>
                        </li>

                        <li className="nav-item">
                            <a className="nav-link text-dark" href="/">
                                <BsDiscord style={{ fontSize: "27px" }} />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Header;