import { toaster } from "rsuite";
import ToastMessage from "../components/ToastMessage";
import { PlacementType } from "rsuite/esm/toaster/ToastContainer";

interface IToastMessage {
  type: "success" | "error" | "warning" | "info" | "default" | "none";
  header: string;
  text: string;
  duration?: number;
  placement?: PlacementType;
}

export const _pushToastMessage = (data: IToastMessage) => {
  const message: any = ToastMessage(data);
  toaster.push(message, {
    placement: data.placement || "topEnd",
    duration: data.duration || 10000,
  });
};
