import { useState } from "react";

interface IPagination {
  type: "all" | "pages";
  onNext: any;
  onPrev: any;
  data?: any;
  service?: any;
  handleError?: any;
}

interface IPage {
  page?: number;
  pages?: number;
  total_records: number;
  items_per_page: number;
  dataFilter?: any;
  url_next_page?: string;
  data?: any;
}
const usePagination = ({
  type = "all",
  onNext,
  onPrev,
  data = [],
  service,
  handleError,
}: IPagination) => {
  const [currentPage, setCurrentPage] = useState<any>({
    page: 0,
    pages: 0,
    total_records: 0,
    items_per_page: 10,
    dataFilter: {},
    data: [],
  });

  const setPaginationData = (payload: IPage) => {
    const pages = Math.ceil(payload.total_records / payload.items_per_page);
    setCurrentPage({
      ...currentPage,
      page: 1,
      pages: pages,
      total_records: payload.total_records,
      items_per_page: payload.items_per_page,
      dataFilter: payload.dataFilter,
      url_next_page: payload.url_next_page,
      data: payload.data,
    });

    if (type === "all") {
      return onNext(payload.data.slice(0, payload.items_per_page));
    }
  };

  const setPage = (page: any = null) => {
    if (type === "all") {
      const nextPage: number = page ? page : currentPage.page + 1;
      setCurrentPage({
        ...currentPage,
        page: nextPage,
      });

      const data: any = currentPage.data.slice(
        (nextPage - 1) * currentPage.items_per_page,
        (nextPage - 1) * currentPage.items_per_page + currentPage.items_per_page
      );

      return onNext(data);
    }
  };

  return { setPaginationData, currentPage, setPage };
};

export default usePagination;
