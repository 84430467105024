import { handleActions } from "redux-actions";
import { SAVE_SEARCH } from "../actions/card_list";

export const INITIAL_STATE = {
  list: [],
};

export default handleActions(
  {
    [SAVE_SEARCH]: (state: any, payload: any) => {
      return { list: payload.payload };
    },
  },
  INITIAL_STATE
);
