import { createAction } from 'redux-actions';

export const START_LOADING: any = createAction('START_LOADING');
export const END_LOADING: any = createAction('END_LOADING');
export const SAVE_STATE: any = createAction('SAVE_STATUS');

export const LOADING_ON = () => (dispatch: any) => {
    dispatch(START_LOADING());
    dispatch(SAVE_STATE());
}

export const LOADING_OFF = () => (dispatch: any) => {
    dispatch(END_LOADING());
    dispatch(SAVE_STATE());
}