import "./style.scss"

const Counter = ({ onPlus, onMinus, quantity, id }: any) => {
    return (
        <div className="counter-component">
            <button className="counter-component__btn__right" onClick={() => onMinus(id)}>-</button>
            <button className="counter-component__btn__center text-center">{quantity || 0}</button>
            <button className="counter-component__btn__left" onClick={() => onPlus(id)}>+</button>
        </div>
    )
}

export default Counter