import { createAction } from 'redux-actions';

export const SAVE_SEARCH: any = createAction('SAVE_SEARCH');
export const SAVE_STATE: any = createAction('SAVE_STATUS');

export const save_search = (data:any) => (dispatch: any) => {
    dispatch(SAVE_SEARCH(data));
    dispatch(SAVE_STATE());
}

