import "./style.scss"
import { useRef } from "react"
import { FaPlus, FaMinus } from "react-icons/fa"
import { _applyAddDeckRules, _applyRemoveDeckRules } from "../../helpers/character_rules"
import { useDispatch, useSelector } from "react-redux"
import { update_deck } from "../../redux/actions/deck"

const Card = ({ item, className, style, onClick, hideTags }: any) => {
    const data = item
    const counterRef: any = useRef(null)
    const { deck } = useSelector((state: any) => state)
    const dispatch: any = useDispatch();

    const _handleEnter = (e: any) => {
        if (counterRef.current) {
            counterRef.current.classList.add("show")
        }
    }
    const _handleLeave = (e: any) => {
        if (counterRef.current) {
            counterRef.current.classList.remove("show")
        }
    }

    const _handleComponentClick = (e: any) => {
        if (e.target.classList.value === "card-img-top") {
            onClick()
        }
    }

    const _handleClick = (action: any) => {
        switch (data?.category?.toLowerCase()) {
            case "leader":
                _handleLeader(action)
                break;
            case "character":
                _handleCharacter(action)
                break;
            case "stage":
                _handleStage(action)
                break;
            case "event":
                _handleEvent(action)
                break;
            default:
                break;
        }
    }

    const _handleLeader = (action: any) => {

        /**
         * TODO: If exists a leader, show confirmation modal
         * TODO: remove all characters that colors dont match from deck
         */
        if (action === "add") deck.leader = { ...data, quantity: 1 }

        /**
         * TODO: show confirmation modal
         * TODO: remove all characters from deck
         */
        if (action === "remove") deck.leader = {}
        dispatch(update_deck(deck))
    }

    const _handleCharacter = (action: any) => {
        const char = deck.characters.find((character: any) => character.number === data?.number)

        if (action === "add") {

            const { errors, deck: deckValidated }: any = _applyAddDeckRules(deck, data, char, "characters")

            if (errors > 0) return

            dispatch(update_deck(deckValidated))
        }

        if (action === "remove") {

            const { deck: deckValidated }: any = _applyRemoveDeckRules(deck, data, char, "characters")



            dispatch(update_deck(deckValidated))
        }
    }

    const _handleStage = (action: any) => {
        const stage = deck.stages.find((stage: any) => stage.number === data?.number)
        if (action === "add") {
            const { errors, deck: deckValidated }: any = _applyAddDeckRules(deck, data, stage, "stages")

            if (errors > 0) return



            dispatch(update_deck(deckValidated))
        }

        if (action === "remove") {

            const { deck: deckValidated }: any = _applyRemoveDeckRules(deck, data, stage, "stages")



            dispatch(update_deck(deckValidated))
        }
    }

    const _handleEvent = (action: any) => {
        const event = deck.events.find((event: any) => event.number === data?.number)
        if (action === "add") {
            const { errors, deck: deckValidated }: any = _applyAddDeckRules(deck, data, event, "events")

            if (errors > 0) return

            dispatch(update_deck(deckValidated))
        }

        if (action === "remove") {

            const { deck: deckValidated }: any = _applyRemoveDeckRules(deck, data, event, "events")

            dispatch(update_deck(deckValidated))
        }
    }
    return (
        <>
            <div
                className={`card card-component text-white border border-1 border-secondary shadow-default ${className}`}
                style={{ ...style, background: "#1B1F22", cursor: "pointer", overflow: "hidden" }}
                onClick={_handleComponentClick}
                onMouseEnter={_handleEnter}
                onMouseLeave={_handleLeave}
            >
                <img src={`${process.env.REACT_APP_URL_IMG}/${item.image}`} className="card-img-top" alt="..." />
                {!hideTags ? (
                    <div className="position-absolute d-flex justify-content-end align-items-end flex-column" style={{ top: "10px", right: "5px" }}>
                        {item.icons && item.icons.includes("restricted") && <span className="badge bg-danger mb-1" style={{ width: "fit-content" }}>Restricted</span>}
                        {item.icons && item.icons.includes("banned") && <span className="badge bg-danger mb-1" style={{ width: "fit-content" }}>Banned</span>}
                    </div>
                ) : null}
                <div className="card-component__counter" ref={counterRef}>
                    <button className="btn" style={{ color: "#fff" }} onClick={() => _handleClick("remove")}>
                        <FaMinus />
                    </button>

                    <button className="btn" style={{ color: "#fff" }} onClick={() => _handleClick("add")}>
                        <FaPlus />
                    </button>
                </div>
            </div>
        </>
    )
}

export default Card