import { handleActions } from 'redux-actions';
import { START_LOADING, END_LOADING } from '../actions/loader';

export const INITIAL_STATE = {
    isLoading: false,
};

export default handleActions({
    [START_LOADING]: () => {
        return { isLoading: true };
    },
    [END_LOADING]: () => {
        return { isLoading: false };
    }
}, INITIAL_STATE);