/* eslint-disable react-hooks/exhaustive-deps */
import ContainerBox from "../../components/ContainerBox";
import { FiFilter, FiSearch, } from "react-icons/fi";
import { AiOutlineCreditCard } from "react-icons/ai";
import useTranslation from "../../hooks/useTranslation";
import Icon from "../../components/Icon";
import FilterCards from "../../components/FilterCards";
import { Fragment, useEffect, useState } from "react";
import { Pagination, Tag, TagGroup } from "rsuite";
import { _getSets, colors, types } from "../../mocks/card_info";
import Card from "../../components/Card";
import { useDatabase } from "../../hooks/useDatabase";
import usePagination from "../../hooks/usePagination";
import { card_space } from "../../helpers/arrays";
import { useSelector } from "react-redux";
import CardDetails from "../../components/CardDetails";
import { _countAllCards } from "../../helpers/character_rules";

const Decks = () => {
    const { t } = useTranslation()
    const { queryDb } = useDatabase()
    const { deck } = useSelector((state: any) => state)
    const [open, setOpen] = useState({
        open: false,
        item: null
    })

    const [totalCards, setTotalCards] = useState<any>(0)

    const [cards, setCards] = useState<any>([])

    const [filters, setFilters] = useState<any>({
        open: false,
        colors: colors,
        types: types,
        sets: _getSets(),
        filters: {
            colors: [],
            types: [],
            sets: []
        }
    })

    useEffect(() => {
        const result: any = queryDb([])
        setPaginationData({
            ...currentPage,
            page: 1,
            total_records: result.length,
            items_per_page: 6,
            dataFilter: [],
            data: result,
        })
    }, [])
    useEffect(() => {
        const total = _countAllCards(deck)
        setTotalCards(total)
    }, [deck])

    const _handleNext = (data: any) => {
        setCards(data)
    }

    const _handlePrev = (data: any) => console.log(data)

    const { currentPage, setPaginationData, setPage } = usePagination({ type: "all", onNext: _handleNext, onPrev: _handlePrev });

    const _toggleFilters = () => {
        setFilters((prevState: any) => {
            return {
                ...prevState,
                open: !prevState.open
            }
        })
    }

    const _handleClearFilters = () => {
        setFilters({
            ...filters,
            filters: {
                colors: [],
                types: [],
                sets: []
            }
        })
    }

    const _handleFilters = (target: any, data: any) => {
        setFilters({
            ...filters,
            filters: {
                ...filters.filters,
                [target]: data
            }
        })
    }

    const _handleRemoveFilter = (target: any, data: any) => {
        setFilters({
            ...filters,
            filters: {
                ...filters.filters,
                [target]: filters.filters[target].filter((item: any) => parseInt(item) !== parseInt(data))
            }
        })
    }

    const _handleActivePage = (page: number) => {
        if (page < 0) return
        if (page > currentPage.pages) return
        setPage(page)
    }

    const _handleSearch = () => {
        const filterList: any = []
        filters.filters.colors.forEach((item: any) => {
            filterList.push({ ...filters.colors.find((i: any) => parseInt(i.id) === parseInt(item)), type: 'Color' })
        });
        filters.filters.types.forEach((item: any) => {
            filterList.push({ ...filters.types.find((i: any) => parseInt(i.id) === parseInt(item)), type: 'Category' })
        });
        filters.filters.sets.forEach((item: any) => {
            filterList.push({ ...filters.sets.find((i: any) => parseInt(i.id) === parseInt(item)), type: 'Number' })
        });

        const result: any = queryDb(filterList)

        setPaginationData({
            ...currentPage,
            page: 1,
            total_records: result.length,
            items_per_page: 6,
            dataFilter: filterList,
            data: result,
        })
    }

    return (
        <div className="container-fluid h-100">
            <CardDetails item={open.item} handleClose={() => setOpen({ ...open, open: false })} open={open.open} />
            <FilterCards open={filters.open} filters={filters.filters} data={filters} handleFilters={_handleFilters} handleSearch={_handleSearch} handleClose={_toggleFilters} clearFilters={_handleClearFilters} />

            <div className="row h-100">
                <div className="col-12 col-md-4 px-0 px-md-3 py-5">
                    <ContainerBox className="px-2 py-2 position-sticky" style={{ overflowY: "auto", overflowX: "hidden", top: "90px" }}>
                        <form className="form-class">
                            <div className="col-12 d-flex justify-content-end mb-3">
                                <Icon className="" style={{ fontSize: "20px" }} component={AiOutlineCreditCard} />
                                <Icon className="border-b-3-default" style={{ fontSize: "20px" }} component={FiFilter} onClick={_toggleFilters} />
                            </div>
                            <TagGroup className="mb-3">
                                {Object.keys(filters.filters).map((key: any) => (
                                    <Fragment key={`filter-${key}`}>
                                        {filters.filters[key].map((item: any) => (
                                            <Tag closable onClose={() => _handleRemoveFilter(key, item)} key={`tag-${key}-${item}`}>
                                                {filters[key].find((i: any) => parseInt(i.id) === parseInt(item)).label}
                                            </Tag>
                                        ))}
                                    </Fragment>
                                ))}
                            </TagGroup>


                            <div className="col-12 position-relative">
                                <input type="text" className="form-control form-class icon" placeholder={t('header.search.placeholder')} aria-label={t('header.search.placeholder')} aria-describedby="basic-addon1" />
                                <span><FiSearch /></span>
                            </div>
                        </form>

                        <div className="row mt-3 px-2">
                            {cards?.map((card: any) => (
                                <div className="col-4 mb-1 p-2" key={`card=list${card.number}`}>
                                    <Card onClick={() => setOpen({ ...open, open: true, item: card })} action="add" item={card} hideTags />
                                </div>
                            ))}

                        </div>

                        <div className="my-3 w-100">
                            <div className="d-flex justify-content-center w-100">
                                <Pagination
                                    total={currentPage.total_records}
                                    limit={currentPage.items_per_page}
                                    activePage={currentPage.page}
                                    onChangePage={_handleActivePage}
                                    maxButtons={5} next prev />
                            </div>
                        </div>
                    </ContainerBox>
                </div>

                <div className="d-none d-md-block col-8 py-5">
                    <ContainerBox className="m-0 px-2 pt-4 pb-5">

                        <div className="row mx-0 mb-5 mt-0 px-3 justify-content-start">
                            <div className="col-12 p-0">
                                <h6>Leader ({totalCards})</h6>
                                <hr className="w-25" />
                            </div>

                            {deck.leader?.image ? (
                                <div className="col-6 col-md-4 col-xl-2 position-relative">
                                    <Card onClick={() => setOpen({ ...open, open: true, item: deck.leader })} action="" className="position-relative" style={{ top: "0px", left: "5px", right: "0px", width: "95%" }} item={{ ...deck.leader }} />
                                </div>
                            ) : null}
                        </div>

                        <div className="row m-0 px-3 justify-content-start mb-5">
                            <div className="col-12 p-0">
                                <h6>Character ({deck.characters.reduce((acc: any, current: any) => acc += current.quantity, 0)})</h6>
                                <hr className="w-25" />
                            </div>

                            {deck?.characters.map((item: any) => (
                                <div className="col-6 col-md-4 col-xl-2 px-0 position-relative my-4" key={`char-${item.number}`}>
                                    {Array.from(Array(item.quantity).keys()).map((i: any) => {
                                        if (i === 0) {
                                            return (
                                                <Card  key={`char-qtd-${i}-${item.number}`} onClick={() => setOpen({ ...open, open: true, item: item })} action="remove" className="position-relative" style={{ top: `${card_space[i]}px`, left: "5px", right: "5px", width: "95%" }} item={{ ...item }} />
                                            )
                                        } else {
                                            return (
                                                <Card  key={`char-qtd-${i}-${item.number}`} onClick={() => setOpen({ ...open, open: true, item: item })} action="remove" className="position-absolute" style={{ top: `${card_space[i]}px`, left: "5px", right: "5px", width: "95%" }} item={{ ...item }} />
                                            )
                                        }
                                    })}
                                </div>
                            ))}
                        </div>

                        <div className="row m-0 px-3 justify-content-start mb-5 mt-2">
                            <div className="col-12 p-0">
                                <h6>Events ({deck.events.reduce((acc: any, current: any) => acc += current.quantity, 0)})</h6>
                                <hr className="w-25" />
                            </div>

                            {deck?.events.map((item: any) => (
                                <div className="col-6 col-md-4 col-xl-2 px-0 position-relative my-4" key={`event-${item.number}`}>
                                    {Array.from(Array(item.quantity).keys()).map((i: any) => {
                                        if (i === 0) {
                                            return (
                                                <Card key={`event-qtd-${i}-${item.number}`} onClick={() => setOpen({ ...open, open: true, item: item })} action="remove" className="position-relative" style={{ top: `${card_space[i]}px`, left: "5px", right: "5px", width: "95%" }} item={{ ...item }} />
                                            )
                                        } else {
                                            return (
                                                <Card key={`event-qtd-${i}-${item.number}`} onClick={() => setOpen({ ...open, open: true, item: item })} action="remove" className="position-absolute" style={{ top: `${card_space[i]}px`, left: "5px", right: "5px", width: "95%" }} item={{ ...item }} />
                                            )
                                        }
                                    })}
                                </div>
                            ))}
                        </div>

                        <div className="row m-0 px-3 justify-content-start mb-5 mt-2">
                            <div className="col-12 p-0">
                                <h6>Stages ({deck.stages.reduce((acc: any, current: any) => acc += current.quantity, 0)})</h6>
                                <hr className="w-25" />
                            </div>

                            {deck?.stages.map((item: any) => (
                                <div className="col-6 col-md-4 col-xl-2 px-0 position-relative my-4" key={`stage-${item.number}`}>
                                    {Array.from(Array(item.quantity).keys()).map((i: any) => {
                                        if (i === 0) {
                                            return (
                                                <Card key={`stage-qtd-${i}-${item.number}`} onClick={() => setOpen({ ...open, open: true, item: item })} action="remove" className="position-relative" style={{ top: `${card_space[i]}px`, left: "5px", right: "5px", width: "95%" }} item={{ ...item }} />
                                            )
                                        } else {
                                            return (
                                                <Card key={`stage-qtd-${i}-${item.number}`} onClick={() => setOpen({ ...open, open: true, item: item })} action="remove" className="position-absolute" style={{ top: `${card_space[i]}px`, left: "5px", right: "5px", width: "95%" }} item={{ ...item }} />
                                            )
                                        }
                                    })}
                                </div>
                            ))}
                        </div>


                    </ContainerBox>
                </div>
            </div>
        </div>
    )
}

export default Decks;