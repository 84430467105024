export const colors = [
  {
    id: 1,
    name: "red",
    label: "Red",
  },
  {
    id: 2,
    name: "purple",
    label: "Purple",
  },
  {
    id: 3,
    name: "green",
    label: "Green",
  },
  {
    id: 4,
    name: "blue",
    label: "Blue",
  },
  {
    id: 5,
    name: "black",
    label: "Black",
  },
  {
    id: 6,
    name: "yellow",
    label: "Yellow",
  },
];

export const types = [
  {
    id: 1,
    name: "don",
    label: "Don",
  },
  {
    id: 2,
    name: "character",
    label: "Character",
  },
  {
    id: 3,
    name: "leader",
    label: "Leader",
  },
  {
    id: 4,
    name: "event",
    label: "Event",
  },
  {
    id: 5,
    name: "stage",
    label: "Stage",
  },
];

export const _getSets = () => {
  const sets = [];
  const setData: any = [
    { quantity: 9, prefix: "st" },
    { quantity: 4, prefix: "op" },
  ];

  let id: number = 1;

  for (const set of setData) {
    for (let i = 1; i <= set.quantity; i++) {
      sets.push({
        id,
        name: `${set.prefix}${i < 10 ? `0${i}` : i}`,
        label: `${set.prefix}${i < 10 ? `0${i}` : i}`.toUpperCase(),
      });
      id++;
    }
  }

  sets.push({ id, name: "promo", label: "Promo" });
  return sets;
};
