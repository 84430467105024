import ContainerBox from '../../components/ContainerBox';

const Home = () => {



    return (
        <>
            <ContainerBox className="mt-5 rounded p-3 text-white" style={{ height: '400px' }}>
                Decks
            </ContainerBox>

            <ContainerBox className="mt-5 rounded p-3 text-white" style={{ height: '400px' }}>
                Cards mais buscados
            </ContainerBox>

            <ContainerBox className="mt-5 rounded p-3 text-white" style={{ height: '400px' }}>
                Franco
            </ContainerBox>
        </>
    )
}

export default Home