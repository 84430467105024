import { query } from "array-query";
import { data } from "../mocks/data";

export const useDatabase = () => {
  const db = data;

  const searchDB = (fields: string | [], search: string) => {
    let data: any = [];

    if (typeof fields === "string") {
      data = db.filter((item: any) => {
        return item[fields].toLowerCase().includes(search.toLowerCase());
      });
    }

    if (Array.isArray(fields)) {
      data = db;
      fields.forEach((item: any) => {
        data = data.filter((dbItem: any) => {
          return dbItem[item].toLowerCase().includes(search.toLowerCase());
        });
      });
    }

    return data;
  };

  const queryDb = (filters: any) => {
    const filtersTypes = filters.reduce((acc: any, item: any) => {
      if (!acc[item.type.toLowerCase()]) {
        acc[item.type.toLowerCase()] = [];
      }

      acc[item.type.toLowerCase()].push(item);

      return acc;
    }, {});

    let q: any = null;
    let filteredData: any = db;

    Object.keys(filtersTypes).forEach((key: any) => {
      for (const filter of filtersTypes[key]) {
        if (!q) {
          q = query(`${key}.toLowerCase()`).has(filter.label.toLowerCase());
        } else {
          q = q.or(`${key}.toLowerCase()`).has(filter.label.toLowerCase());
        }
      }
      filteredData = q.on(filteredData);
      q = null;
    });
    return filteredData;
  };

  const getAllData = () => {
    return db;
  };

  return { searchDB, queryDb, getAllData };
};
