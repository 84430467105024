import { handleActions } from "redux-actions";
import { SET_LANGUAGE } from "../actions/translation";

export const INITIAL_STATE = {
  language: "en-en",
};

export default handleActions(
  {
    [SET_LANGUAGE]: (state: any, payload: any) => {
      return { ...state, ...payload.payload };
    },
  },
  INITIAL_STATE
);
