import './styles.scss'

const ContainerBox = ({ children, className, style }: any) => {
    return (
        <div className={`container container-box ${className}`} style={style}>
            {children}
        </div>
    )
}

export default ContainerBox;