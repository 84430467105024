import { banned_cards } from "./banned_cards";
import { _pushToastMessage } from "./messages";
import { restricted_cards } from "./restricted_cards";

export const _getRules = (leader: any, card: any) => {
  const rules: any = {
    quantity: 4,
    isColorValid: false,
    isCardBanned: false,
    isLeaderSelected: false,
  };

  const isBanned = banned_cards.find((banned: any) => banned === card.number);

  if (isBanned) {
    rules.isCardBanned = true;
  }

  const isRestricted = restricted_cards.find(
    (restricted: any) => restricted.number === card.number
  );

  if (isRestricted) {
    rules.quantity = isRestricted.quantity;
  }

  if (!leader.number) {
    return rules;
  }

  rules.isLeaderSelected = true;
  const cardColors = card?.color?.split("/") || [];
  const leaderColors = leader?.color?.split("/") || [];

  cardColors.forEach((color: any) => {
    if (leaderColors.includes(color)) {
      rules.isColorValid = true;
    }
  });

  return rules;
};

export const _applyAddDeckRules = (
  deck: any,
  data: any,
  char: any,
  category: string
) => {
  const rules = _getRules(deck.leader, data);
  let count_errors: number = 0;
  data.icons = [];

  if (rules.isCardBanned) {
    data.icons.push("banned");
  }

  if (!rules.isLeaderSelected) {
    _pushToastMessage({
      type: "error",
      text: "Selecciona un lider antes de continuar",
      header: "Erro",
    });
    count_errors++;
    return { errors: count_errors };
  }

  if (!rules.isColorValid) {
    _pushToastMessage({
      type: "error",
      text: "A cor desta carta nao é valida para este capitao",
      header: "Erro",
    });
    count_errors++;
    return { errors: count_errors };
  }
  let total_cards = 1;

  total_cards += deck.characters.reduce(
    (acc: number, character: any) => acc + character.quantity,
    0
  );
  total_cards += deck.stages.reduce(
    (acc: number, stage: any) => acc + stage.quantity,
    0
  );
  total_cards += deck.events.reduce(
    (acc: number, event: any) => acc + event.quantity,
    0
  );

  if (total_cards === 50) {
    _pushToastMessage({
      type: "error",
      text: "Limite de cartas atingido",
      header: "Erro",
    });
    count_errors++;
    return { errors: count_errors };
  }

  if (count_errors > 0) return { errors: count_errors };
  if (!char)
    deck[category].push({ ...data, quantity: 1, icons: [...data.icons] });

  if (char && char.quantity === 4) {
    _pushToastMessage({
      type: "error",
      text: "Quantidade maxima atingida",
      header: "Erro",
    });
  }

  if (char && char.quantity < 4) {
    char.quantity++;
  }

  if (char && char.quantity > rules?.quantity) {
    data.icons.push("restricted");
    char.icons = [...data.icons];
  }

  return { errors: count_errors, char, data, deck, total_cards };
};

export const _applyRemoveDeckRules = (
  deck: any,
  data: any,
  char: any,
  category: string
) => {
  const rules = _getRules(deck.leader, data);
  data.icons = [];

  if (rules.isCardBanned) {
    data.icons.push("banned");
  }

  if (!char) return {};

  if (char && char.quantity > 0) {
    char.quantity--;
  }

  if (char.quantity > rules.quantity) {
    data.icons.push("restricted");
    char.icons = [...data.icons];
  } else {
    if (char.icons?.includes("restricted"))
      char.icons = char.icons.filter((icon: any) => icon !== "restricted");
  }

  if (char.quantity === 0)
    deck[category] = deck[category].filter(
      (item: any) => item.number !== data.number
    );

  let total_cards = 1;

  total_cards += deck.characters.reduce(
    (acc: number, character: any) => acc + character.quantity,
    0
  );
  total_cards += deck.stages.reduce(
    (acc: number, stage: any) => acc + stage.quantity,
    0
  );
  total_cards += deck.events.reduce(
    (acc: number, event: any) => acc + event.quantity,
    0
  );

  return { char, data, deck, total_cards };
};

export const _countAllCards = (deck: any) => {
  let total_cards = 0;

  if (deck.leader.number) total_cards++;

  total_cards += deck.characters.reduce(
    (acc: number, character: any) => acc + character.quantity,
    0
  );
  total_cards += deck.stages.reduce(
    (acc: number, stage: any) => acc + stage.quantity,
    0
  );
  total_cards += deck.events.reduce(
    (acc: number, event: any) => acc + event.quantity,
    0
  );

  return total_cards;
};
