import { TagPicker } from "rsuite"
import useTranslation from "../../hooks/useTranslation"
import Icon from "../Icon"
import { FiFilter } from "react-icons/fi"
import { AiOutlineClose } from "react-icons/ai"

interface FilterProps {
    open: boolean
    filters?: any
    handleSearch?: any
    handleFilters?: any
    clearFilters?: any
    handleClose?: any
    data: any
}

const FilterCards = ({ open, filters, handleFilters, handleSearch, handleClose, clearFilters, data }: FilterProps) => {
    const { t } = useTranslation()

    const _handleSubmit = (e: any) => {
        e.preventDefault();
    }
    const handleClick = (e: any) => {
        if (e.target.classList.contains('container-search')) handleSearch(e)
    }

    return (
        <div className={`container-search ${open ? "" : "d-none"}`} onClick={handleClick}>
            <div className="container-box-search">
                <button className="position-absolute text-white btn" style={{right: "5px"}} onClick={handleClose}> <AiOutlineClose /> </button>
                <div className="container-box-search__wrap">
                    <form onSubmit={_handleSubmit}>
                        <div className="col-12 text-white">
                            <label className="fw-bold ms-1" style={{ fontSize: '0.8rem' }}>{t("filter.color")}</label>
                            <TagPicker
                                className="mb-3 text-white"
                                valueKey="id"
                                data={data.colors}
                                value={filters?.colors}
                                onChange={(data: any) => handleFilters('colors', data)} style={{ width: "100%" }}
                                cleanable={false}
                                locale={{
                                    noResultsText: t("filter.noResultsText"),
                                    placeholder: t("filter.placeholderColors")
                                }}
                            />
                        </div>
                        <div className="col-12 text-white">
                            <label className="fw-bold ms-1" style={{ fontSize: '0.8rem' }}>{t("filter.type")}</label>
                            <TagPicker
                                className="mb-3 text-white"
                                valueKey="id"
                                data={data.types}
                                value={filters?.types}
                                onChange={(data: any) => handleFilters('types', data)} style={{ width: "100%" }}
                                cleanable={false}
                                locale={{
                                    noResultsText: t("filter.noResultsText"),
                                    placeholder: t("filter.placeholderTypes")
                                }}
                            />
                        </div>
                        <div className="col-12 text-white">
                            <label className="fw-bold ms-1" style={{ fontSize: '0.8rem' }}>{t("filter.set")}</label>
                            <TagPicker
                                className="mb-3 text-white"
                                valueKey="id"
                                data={data.sets}
                                value={filters?.sets}
                                onChange={(data: any) => handleFilters('sets', data)} style={{ width: "100%" }}
                                cleanable={false}
                                locale={{
                                    noResultsText: t("filter.noResultsText"),
                                    placeholder: t("filter.placeholderSets")
                                }}
                            />
                        </div>

                        <div className="col-12 text-end">
                            <button type="button" className="btn btn-outline-default p x-2 py-1 text-white me-3" onClick={clearFilters}>{t("filter.clear")}</button>
                            <button type="button" className="btn btn-outline-default p x-2 py-1 text-white" onClick={handleSearch}>{t("filter.search")}</button>
                        </div>
                    </form>
                </div>

                <div className="container-box-search__footer p-2">
                    <div className="text-white">
                        <kbd className="me-2 py-2">
                            <Icon component={FiFilter} />
                        </kbd>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FilterCards