/* eslint-disable react-hooks/exhaustive-deps */
import { useSelector } from "react-redux"
import Luffy from '../../assets/images/gif/op.gif'

import './Loader.scss'

const Loader = () => {
  const { loader } = useSelector((state: any) => state)
  const { isLoading } = loader

  return (
    <div className={`container-loader ${isLoading ? 'd-flex' : 'd-none'}`}>
      <div className="d-flex justify-content-center align-items-center">
        <img src={Luffy} alt="Luffy" style={{width: "85px"}} />
      </div>
    </div>
  )
}

export default Loader