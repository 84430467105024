const words = {
  header: {
    menu: {
      home: "Home",
      decks: "Decks",
      events: "Eventos",
      cards: "Cartas",
      languages: "Idiomas",
    },
    search: {
      placeholder: "Buscar cartas",
    },
  },
  languages: {
    english: "Inglês",
    spanish: "Espanhol",
    portuguese: "Português",
    "en-en": "Inglês",
    "es-es": "Espanhol",
    "pt-br": "Português",
  },
  search: {
    "press-enter": "Pressione enter para fechar",
  },
  filter: {
    color: "Cor",
    type: "Tipo",
    set: "Set",
    clear: "Limpar",
    search: "Buscar",
    noResultsText: "Nenhum resultado encontrado",
    placeholderColors: "Selecione as cores",
    placeholderTypes: "Selecione os tipos",
    placeholderSets: "Selecione os sets",
  }
};

export default words;
