const words = {
  header: {
    menu: {
      home: "Home",
      decks: "Decks",
      events: "Events",
      cards: "Cards",
      languages: "Languages",
    },
    search: {
      placeholder: "Search cards",
    },
  },
  languages: {
    english: "English",
    spanish: "Spanish",
    portuguese: "Portuguese",
    "en-en": "English",
    "es-es": "Spanish",
    "pt-br": "Portuguese",
  },
  search: {
    "press-enter": "Press enter to close",
  },
  filter: {
    color: "Color",
    type: "Type",
    set: "Set",
    clear: "Clear",
    search: "Search",
    noResultsText: "No results found",
    placeholderColors: "Select the colors",
    placeholderTypes: "Select the types",
    placeholderSets: "Select the sets",
  },
  card_details:{
    name: "Name",
  }
};

export default words;
