/* eslint-disable react-hooks/exhaustive-deps */
import useTranslation from "../../hooks/useTranslation"
import Icon from "../Icon"
import { FiFilter } from "react-icons/fi"
import { AiOutlineClose } from "react-icons/ai"
import { useEffect, useState } from "react"
import Counter from "../Counter"
import { _applyAddDeckRules, _applyRemoveDeckRules } from "../../helpers/character_rules"
import { useDispatch, useSelector } from "react-redux"
import { update_deck } from "../../redux/actions/deck"

interface CardDetailsProps {
    open: boolean
    filters?: any
    handleClose?: any
    item: any
}

const CardDetails = ({ open, handleClose, item }: CardDetailsProps) => {

    const { t } = useTranslation()
    const { deck } = useSelector((state: any) => state)
    const dispatch: any = useDispatch()
    const [data, setData] = useState<any>({ ...item, icons: [] })

    useEffect(() => {
        if (item && item.category) {
            const category: string = item?.category?.toLowerCase()
            if (category === "leader") {
                if (deck.leader?.number !== item?.number) {
                    setData({ ...data, ...item, quantity: 0 })
                    return
                }
                setData({ ...data, ...deck.leader })
            }

            if (category !== "leader") {
                const deckItem = deck[`${category}s`].find((character: any) => character.number === item?.number)
                if (deckItem) setData({ ...data, ...deckItem })
                if (!deckItem) setData({ ...data, ...item, quantity: 0 })
            }

        }
    }, [open, deck])

    const handleClick = (e: any) => {
        if (e.target.classList.contains('container-search')) console.log('click')
    }

    const _handleClick = (action: any) => {
        switch (data?.category?.toLowerCase()) {
            case "leader":
                _handleLeader(action)
                break;
            case "character":
                _handleCharacter(action)
                break;
            case "stage":
                _handleStage(action)
                break;
            case "event":
                _handleEvent(action)
                break;
            default:
                break;
        }
    }

    const _handleLeader = (action: any) => {

        /**
         * TODO: If exists a leader, show confirmation modal
         * TODO: remove all characters that colors dont match from deck
         */
        if (action === "add") deck.leader = { ...data, quantity: 1 }

        /**
         * TODO: show confirmation modal
         * TODO: remove all characters from deck
         */
        if (action === "remove") deck.leader = {}
        dispatch(update_deck(deck))
    }

    const _handleCharacter = (action: any) => {
        const char = deck.characters.find((character: any) => character.number === data?.number)

        if (action === "add") {

            const { errors, deck: deckValidated, data: dataValidated }: any = _applyAddDeckRules(deck, data, char, "characters")

            if (errors > 0) return

            setData({ ...data, icons: dataValidated.icons })

            dispatch(update_deck(deckValidated))
        }

        if (action === "remove") {

            const { deck: deckValidated, data: dataValidated }: any = _applyRemoveDeckRules(deck, data, char, "characters")

            setData({ ...data, icons: dataValidated.icons })

            dispatch(update_deck(deckValidated))
        }
    }

    const _handleStage = (action: any) => {
        const stage = deck.stages.find((stage: any) => stage.number === data?.number)
        if (action === "add") {
            const { errors, deck: deckValidated, data: dataValidated }: any = _applyAddDeckRules(deck, data, stage, "stages")

            if (errors > 0) return

            setData({ ...data, icons: dataValidated.icons })

            dispatch(update_deck(deckValidated))
        }

        if (action === "remove") {

            const { deck: deckValidated, data: dataValidated }: any = _applyRemoveDeckRules(deck, data, stage, "stages")

            setData({ ...data, icons: dataValidated.icons })

            dispatch(update_deck(deckValidated))
        }
    }

    const _handleEvent = (action: any) => {
        const event = deck.events.find((event: any) => event.number === data?.number)
        if (action === "add") {
            const { errors, deck: deckValidated, data: dataValidated }: any = _applyAddDeckRules(deck, data, event, "events")

            if (errors > 0) return

            setData({ ...data, icons: dataValidated.icons })

            dispatch(update_deck(deckValidated))
        }

        if (action === "remove") {

            const { deck: deckValidated, data: dataValidated }: any = _applyRemoveDeckRules(deck, data, event, "events")

            setData({ ...data, icons: dataValidated.icons })

            dispatch(update_deck(deckValidated))
        }
    }

    return (
        <div className={`container-search ${open ? "" : "d-none"}`} onClick={handleClick}>
            <div className="container-box-search" style={{ minWidth: "80%" }}>
                <button className="position-absolute text-white btn" style={{ right: "5px" }} onClick={handleClose}> <AiOutlineClose /> </button>
                <div className="container-box-search__wrap mt-4" style={{ maxHeight: "70vh", overflow: "auto" }}>
                    <div className="row">
                        <div className="col-12 col-md-4 text-center">
                            <img src={`${process.env.REACT_APP_URL_IMG}/${data?.image}`} alt="" className="img-fluid" style={{ maxWidth: "90%" }} />
                        </div>

                        <div className="col-12 col-md-8 position-relative">
                            <div className="row py-4 ">
                                <div className="col-6 mb-3">
                                    <span className="fw-bold">{t("card_details.name")}</span>
                                    <div className="ps-2" style={{ fontSize: "0.9rem" }}>{data?.name}</div>
                                </div>
                                <div className="col-6 mb-3">
                                    <span className="fw-bold">Number</span>
                                    <div className="ps-2" style={{ fontSize: "0.9rem" }}>{data?.number}</div>
                                </div>
                                <div className="col-6 mb-3">
                                    <span className="fw-bold">Attribute</span>
                                    <div className="ps-2" style={{ fontSize: "0.9rem" }}>{data?.attribute}</div>
                                </div>
                                <div className="col-6 mb-3">
                                    <span className="fw-bold">CardSet</span>
                                    <div className="ps-2" style={{ fontSize: "0.9rem" }}>{data?.cardSet}</div>
                                </div>
                                <div className="col-6 mb-3">
                                    <span className="fw-bold">Color</span>
                                    <div className="ps-2" style={{ fontSize: "0.9rem" }}>{data?.color}</div>
                                </div>
                                <div className="col-6 mb-3">
                                    <span className="fw-bold">Rarity</span>
                                    <div className="ps-2" style={{ fontSize: "0.9rem" }}>{data?.rarity}</div>
                                </div>
                                <div className="col-6 mb-3">
                                    <span className="fw-bold">Category</span>
                                    <div className="ps-2 capitalize" style={{ fontSize: "0.9rem" }}>{data?.category?.toLowerCase()}</div>
                                </div>
                                <div className="col-6 mb-3">
                                    <span className="fw-bold">Cost</span>
                                    <div className="ps-2" style={{ fontSize: "0.9rem" }}>{data?.cost}</div>
                                </div>
                                <div className="col-6 mb-3">
                                    <span className="fw-bold">Type</span>
                                    <div className="ps-2 capitalize" style={{ fontSize: "0.9rem" }}>{data?.type}</div>
                                </div>
                                <div className="col-6 mb-3">
                                    <span className="fw-bold">Counter</span>
                                    <div className="ps-2 capitalize" style={{ fontSize: "0.9rem" }}>{data?.counter}</div>
                                </div>
                                <div className="col-6 mb-3">
                                    <span className="fw-bold">Power</span>
                                    <div className="ps-2 capitalize" style={{ fontSize: "0.9rem" }}>{data?.power}</div>
                                </div>
                                <div className="col-6 mb-3">
                                    <span className="fw-bold">Life</span>
                                    <div className="ps-2" style={{ fontSize: "0.9rem" }}>{data?.Life || "-"}</div>
                                </div>
                                <div className="col-12 mb-3">
                                    <span className="fw-bold">Effect</span>
                                    <div className="ps-2 capitalize" style={{ fontSize: "0.9rem" }}>{data?.effect}</div>
                                </div>

                                <div className="position-absolute bottom-0 d-flex justify-content-center">
                                    <Counter quantity={data?.quantity} onPlus={() => _handleClick("add")} onMinus={() => _handleClick("remove")} />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-box-search__footer p-2">
                    <div className="text-white">
                        <kbd className="me-2 py-2">
                            <Icon component={FiFilter} />
                        </kbd>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CardDetails